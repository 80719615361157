import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-unistore';
import style from './main.module.scss';
import productImage from '../../assets/placeholder/product.svg';
import logoImagePlaceholder from '../../assets/placeholder/logo.svg';

// eslint-disable-next-line react/prefer-stateless-function
class Linkboard extends Component {
  render() {
    const {
      linkboard,
      branding,
      fallBackToPlaceHolderForHeader,
      fallBackToPlaceHolderForLogo,
      showOptions,
    } = this.props;

    const { linkboardBranding } = linkboard;

    const filteredButtons = linkboard.buttons.filter((object) => object.isActive === true);
    const filteredProducts = linkboard.products.filter((object) => object.isActive === true);
    const filteredSocials = linkboard.socials.filter((object) => object.isActive === true);

    const linkboardStyles = {
      fontFamily:
        linkboardBranding.font,
      backgroundColor:
        linkboardBranding.backgroundColor,
      fontColor:
        linkboardBranding.fontColor
          ? linkboardBranding.fontColor
          : branding.fontColor,
      bannerImage: linkboard.headerFile ? linkboard.headerFile.url : null,
      logoImage: linkboard.logoFile ? linkboard.logoFile.url : null,
      buttonBackgroundColor:
        linkboard.buttons.backgroundColor
          ? linkboard.buttons.backgroundColor
          : branding.primaryButtonColor,
      buttonTextColor: linkboard.buttons.color
        ? linkboard.buttons.color
        : branding.primaryTextColor,
      headline: linkboard.headline,
      description: linkboard.description,
      type: linkboard.type,
      buttons: filteredButtons,
      products: filteredProducts,
      socials: filteredSocials,
      grid: linkboard.grid,
      currency: linkboard.currency,
      policyUrl: linkboard.policyUrl,
    };

    const linkboardClass = `${style['linkboard-container']}`;

    // if (preview) {
    //   linkboardClass += ` ${style.preview}`;
    // }

    return (

      <div className={linkboardClass}>

        <div
          className={style['linkboard-container']}
          style={{ backgroundColor: linkboardStyles.backgroundColor }}
        >
          {fallBackToPlaceHolderForHeader !== false || linkboardStyles.bannerImage ? (
            <div
              className={style['banner-container']}
              style={{ backgroundColor: linkboardStyles.backgroundColor }}
            >
              {linkboardStyles.bannerImage
                && <img src={linkboardStyles.bannerImage} alt="Linkboard Banner" />}
            </div>
          ) : (
            <div
              className={style['banner-container-empty']}
              style={{ backgroundColor: linkboardStyles.backgroundColor }}
            />
          )}

          <div
            className={style['content-container']}
            style={{ backgroundColor: linkboardStyles.backgroundColor }}
          >
            <div className={style['content-spacer']}>
              {fallBackToPlaceHolderForLogo !== false || linkboardStyles.logoImage
                ? (
                  <div className={style['logo-container']}>
                    <img src={linkboardStyles.logoImage ? linkboardStyles.logoImage : logoImagePlaceholder} alt="" />
                  </div>
                ) : null}

              {linkboardStyles.headline && (
              <h1
                className={style.headline}
                style={{
                  fontFamily: linkboardStyles.fontFamily,
                  color: linkboardStyles.fontColor,
                }}
              >
                {linkboardStyles.headline}
              </h1>
              )}

              {linkboardStyles.description
                  && (
                  <p
                    className={style.description}
                    style={{
                      fontFamily: linkboardStyles.fontFamily,
                      color: linkboardStyles.fontColor,
                    }}
                  >
                    {linkboardStyles.description}
                  </p>
                  )}

              {(linkboardStyles.type === 'ecommerce')
                  && (
                    (linkboardStyles.products.length >= 0)
                    && (
                      <div
                        className={
                          (linkboardStyles.grid === true)
                            ? `${style.product_container} ${style.grid}`
                            : style.product_container
                        }
                      >
                        {
                          linkboardStyles.products.map((product) => (
                            <a href={product.url} className={style.product}>
                              <div className={style['image-container']}>
                                <img
                                  src={(product.imageFile.url)
                                    ? product.imageFile.url
                                    : productImage}
                                  alt={product.text}
                                />
                              </div>
                              <h6
                                className={style.headline}
                                style={{
                                  fontFamily: linkboardStyles.fontFamily,
                                  color: linkboardStyles.fontColor,
                                }}
                              >
                                {product.text ? product.text : 'Product'}
                              </h6>
                              <p
                                className={style.price}
                                style={{
                                  fontFamily: linkboardStyles.fontFamily,
                                  color: linkboardStyles.fontColor,
                                }}
                              >
                                <span>{product.price}</span>
                                <span>
                                  {' '}
                                  {linkboard.currency ? linkboard.currency : 'EUR'}
                                </span>
                              </p>
                            </a>
                          ))
                        }
                      </div>
                    ))}

              {linkboardStyles.buttons
                  && (linkboardStyles.buttons.map((button) => (
                    <a
                      key={button.id.toString()}
                      className={style.button}
                      style={{
                        fontFamily: linkboardStyles.fontFamily,
                        color:
                          button.color
                            ? button.color
                            : branding.primaryTextColor,
                        backgroundColor:
                          button.backgroundColor
                            ? button.backgroundColor
                            : branding.primaryButtonColor,
                      }}
                      href={button.url ? button.url : '#'}
                      target={button.url ? '_blank' : '_self'}
                    >
                      {button.iconType === 'custom' && button.iconFile.url !== null
                        ? (
                          <div className={style['image-container']}>
                            <img src={button.iconFile.url} alt="" />
                          </div>
                        )
                        : (
                          <span
                            className={(button.iconType === 'no-icon' || button.iconType === '') ? style.empty : button.iconType}
                            style={{
                              color:
                                button.backgroundColor
                                  ? button.backgroundColor
                                  : branding.primaryButtonColor,
                              backgroundColor:
                                button.color
                                  ? button.color
                                  : branding.primaryTextColor,
                            }}
                          />
                        )}
                      {button.text}
                    </a>
                  )))}

            </div>
            {
                  (linkboardStyles.socials)
                  && (
                  <div className={style['icon-container']}>
                    {
                      linkboardStyles.socials.map((social) => (
                        <a
                          key={social.id.toString()}
                          href={social.url ? social.url : '#'}
                          target={social.url ? '_blank' : '_self'}
                          style={{
                            color:
                              (social.color)
                                ? social.color
                                : branding.primaryTextColor,
                            backgroundColor:
                              (social.backgroundColor)
                                ? social.backgroundColor
                                : branding.primaryButtonColor,
                          }}
                          className={style['icon-outer']}
                        >
                          <span
                            className={social.iconType}
                            style={{ color: social.color }}
                          >
                            {social.text}
                          </span>
                        </a>
                      ))
                    }
                  </div>
                  )
                }

          </div>

          {linkboard.policyUrl
              && (
              <a
                href={linkboardStyles.policyUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={style.policyUrl}
                style={{
                  backgroundColor: linkboardStyles.backgroundColor,
                  color: linkboardStyles.fontColor,
                  fontFamily: linkboardStyles.fontFamily,
                }}
              >
                Privacy policy
              </a>
              )}

          {showOptions
            ? (
              <div className={style['options-container']}>
                <Link
                  to={`/linkboards/edit/${linkboard.id}`}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Linkboard',
                      action: 'Edit',
                      label: 'Open Editor',
                    });
                  }}
                  className={style['options-button']}
                >
                  <img
                    src="/icons/edit-white.svg"
                    alt="Edit"
                  />
                </Link>
                <Link
                  to={`/linkboards/detail/${linkboard.id}`}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Linkboard',
                      action: 'View',
                      label: 'Analytics',
                    });
                  }}
                  className={style['options-button']}
                >
                  <img
                    src="/icons/analytics-white.svg"
                    alt="Detail"
                  />
                </Link>
              </div>
            ) : null}
        </div>

      </div>

    );
  }
}
Linkboard.defaultProps = {
  fallBackToPlaceHolderForHeader: false,
  fallBackToPlaceHolderForLogo: false,
  showOptions: false,
};
Linkboard.propTypes = {
  fallBackToPlaceHolderForHeader: PropTypes.bool,
  fallBackToPlaceHolderForLogo: PropTypes.bool,
  linkboard: PropTypes.shape({
    headerFile: PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    logoFile: PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    grid: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    socials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    currency: PropTypes.string.isRequired,
    policyUrl: PropTypes.string.isRequired,
    linkboardBranding: PropTypes.shape({
      backgroundColor: PropTypes.string.isRequired,
      font: PropTypes.string.isRequired,
      fontColor: PropTypes.string.isRequired,
      primaryTextColor: PropTypes.string.isRequired,
      primaryButtonColor: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  branding: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    font: PropTypes.string.isRequired,
    fontColor: PropTypes.string.isRequired,
    primaryTextColor: PropTypes.string.isRequired,
    primaryButtonColor: PropTypes.string.isRequired,
  }).isRequired,
  showOptions: PropTypes.bool,

};

export default connect('branding', null)(Linkboard);
