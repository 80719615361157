import React from 'react';
import ReactDOM from 'react-dom';
import createStore from 'unistore';
import { Provider } from 'react-unistore';
import App from './components/app';
import './style/index.module.scss';
import initialState from './model';

const store = createStore(initialState);

// TODO: only show logs in development
// if (process.env.NODE_ENV !== 'production') {
//   store.subscribe((data) => {
//     console.log(data.linkboardEditor);
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider value={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
