/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, Link } from 'react-router-dom';
import * as user from '../../../util/user-management';
import Footer from '../../Footer';

import style from '../../Header/style.module.scss';

const AccountingRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  const isAccounting = user.isAccounting();

  return (
    <Route
      {...rest}
      render={(props) => (isAccounting ? (
        <div>
          {/* <Header activeLink={props.location.pathname} showNavigation={user.role()} /> */}
          <header className={style.header}>
            <Link className={style.logo} to="/">
              <img src="/icons/logo-vodafone-square.jpg" alt="Vodafone Icon" />
            </Link>
            <div className={style.nav_wrapper}>
              <nav>
                <Link className={style.logout} to="/logout">Logout</Link>
              </nav>
            </div>
          </header>
          <Component {...props} />
        </div>

      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      ))}
    />
  );
};
export default AccountingRoute;
