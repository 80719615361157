import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { connect } from 'react-unistore';
import 'react-table/react-table.css';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import Content from '../../components/Content';
import Search from "../../components/Form/components/Search";

class Groups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredGroups: this.props.groups || [],
    };

    this.setActiveClass = this.setActiveClass.bind(this);
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
  }

  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Groups',
    });
  }

  setActiveClass(state, rowInfo) {
    const { search } = this.props.location;
    const query = new URLSearchParams(search);
    const highlightId = query.get('highlightId');
    if (rowInfo.original.id === parseInt(highlightId)) {
      return {
        className: 'animated',
      };
    }
    return {};
  }

  updateSearchQuery(query) {
    const filtered = this.props.groups.filter((group) => {
      return group.name?.toLowerCase().includes(query.toLowerCase())
    });

    this.setState({
      filteredGroups: filtered,
    })
  }

  render() {
    const { history } = this.props;

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        id: 'group',
        filterable: false,
        sortable: true,
        headerClassName: 'sort',
      },
      {
        Header: 'User count',
        accessor: 'count',
        id: 'usercount',
        filterable: false,
        sortable: true,
        Cell: (props) => <p>{props.original.users.length}</p>,
        headerClassName: 'sort',
      }, {
        Header: 'Edit',
        id: 'Edit',
        headerClassName: 'center',
        className: 'icons',
        accessor: (row) => `${row.id}`,
        Cell: (props) => (
          <div className="center">
            <Link
              to={`/groups/edit/${props.original.id}`}
              onClick={() => {
                ReactGA.event({
                  category: 'Group',
                  action: 'Edit',
                  label: 'Open Editor',
                });
              }}
            >
              <img alt="edit group" src="/icons/edit.svg" />
            </Link>
          </div>
        ),
        filterable: false,
        sortable: false,
        width: 100,

      },
    ];

    return (
      <Content>
        <h1>Usergroups</h1>

        <Content button>
          <Search placeholder="Search" autocomplete={false} callback={this.updateSearchQuery}
          />
        </Content>

        <ReactTable
          data={this.state.filteredGroups}
          columns={columns}
          defaultSorted={[
            {
              id: 'createdAt',
              desc: false,
            },
          ]}
          defaultPageSize={7}
          PaginationComponent={Pagination}
          minRows={0}
          resizable={false}
          getTrProps={this.setActiveClass}
        />

        <Content button>
          <Button
            label="Create new group"
            onClick={() => {
              ReactGA.event({
                category: 'Group',
                action: 'Create',
                label: 'Open Editor',
              });
              history.push('/groups/create');
            }}
          />
        </Content>
      </Content>
    );
  }
}

Groups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default connect('groups')(Groups);
