import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-unistore';
import style from './style.module.scss';
import Form from '../../components/Form';
import Button from '../../components/Button';
import actions from '../../actions';
import { fetchAccount } from '../../api/account';
import { login } from '../../api/common';
import * as user from '../../util/user-management';
import Background from "../../components/Background";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _username: '',
      _password: '',
    };

    this.submitLogin = this.submitLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Login',
    });
  }

  static getDerivedStateFromProps(props, state) {
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async submitLogin(e) {
    e.preventDefault();

    let error = false;
    // TODO: do not access dom directly in react app!!!
    const requiredFields = Array.from(document.querySelectorAll('[data-required]'));
    const data = {
      _username: this.state._username,
      _password: this.state._password,
    };

    // eslint-disable-next-line array-callback-return
    requiredFields.map((formItem) => {
      if (formItem.value === '') {
        formItem.classList.add('error');
        error = true;
      } else {
        formItem.classList.remove('error');
      }
    });

    if (error) {
      this.props.toggleSnackbar('Please check your credentials.');
      return;
    }

    this.props.toggleLoading(true);
    const res = await login(JSON.stringify(data));

    if (res.status !== 200) {
      this.props.toggleSnackbar('Please check your credentials and try again.');
      this.props.toggleLoading(false);
      this.setState({ _password: '' });
      return;
    }

    const userData = await res.json();
    // store user information incl. JWT token in localstorage
    localStorage.setItem('token', JSON.stringify(userData));

    await fetchAccount();
    this.props.toggleLoading(false);

    const { location, history } = this.props;
    if (user.isAccounting()) {
      history.push('/accounting');
    } else if (location && location.state) {
      const fromLocation = location.state.from;
      history.push(fromLocation.pathname);
    } else {
      history.push('/');
    }
  }

  render() {
    return (
        <Background id="background">
          <div id={style.login}>

            <div className={style.login_form}>
              <div className={style.logo}>
                <img src="/icons/logo-vodafone.jpg" alt="Linkhawk Website"/>
              </div>
              <p>Login for Vodafone employees</p>

              <Form>
                <Form.AutocompleteInput
                    type="text"
                    width={100}
                    name="_username"
                    placeholder="Email address"
                    data-required
                    value={this.state._username}
                    onChange={this.handleChange}
                />
                <Form.AutocompleteInput
                    type="password"
                    width={100}
                    name="_password"
                    placeholder="Password"
                    data-required
                    value={this.state._password}
                    onChange={this.handleChange}
                />
                <Link to="/reset-password">Forgot password?</Link>
                <Button label="Login" onClick={this.submitLogin} className={style.login_button}/>

              </Form>

            </div>
          </div>
        </Background>
    );
  }
}

export default connect('user', actions)(Login);
