import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { connect } from 'react-unistore';
import inputStyle from '../Input/style.module.scss';
import style from './style.module.scss';
import actions from '../../../../actions';

class ColorPicker extends Component {
  constructor() {
    super();
    this.state = {
      displayColorPicker: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({ displayColorPicker: !prevState.displayColorPicker }));
  }

  handleClose() {
    this.setState({ displayColorPicker: false });
  }

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '20px',
          height: '20px',
          borderRadius: '3px',
          background: this.props.color,
        },
        swatch: {
          position: 'absolute',
          background: '#fff',
          borderRadius: '4px',
          display: 'inline-block',
          cursor: 'pointer',
          bottom: 0,
          margin: '14px',
          border: '1px solid #eee',
        },
        popover: {
          position: 'absolute',
          zIndex: 1000,
          top: this.props.popoverPosTop ? this.props.popoverPosTop : 'calc(-100% - 54px)',
          left: '5px',
        },
        cover: {
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    });

    const {
      label, branding, width, containerStyle, inputChange, name, placeholder, color, isDisabled, popupWidth, colorPickerChange, presetColors,
    } = this.props;

    const brandingColors = branding
      ? [
        branding.backgroundColor,
        branding.fontColor,
        branding.primaryButtonColor,
        branding.primaryTextColor,
        branding.secondaryButtonColor,
        branding.secondaryTextColor,
      ]
      : null;

    const uniqueColors = [...new Set(brandingColors)];

    let className = `${style.colorpicker} width_${width}`;

    if (isDisabled) {
      className += ` ${style.disabled}`;
    }

    return (
      <div className={className}>

        <div
          className={`${inputStyle.input_wrapper} ${style.colorpicker_input_wrapper}`}
          style={containerStyle}
        >
          {label && <label>{label}</label>}
          <div>
            <input
              type="text"
              onInput={inputChange}
              name={name}
              placeholder={placeholder}
              value={color}
              {...this.props}
              disabled={isDisabled}
            />
            <div id="control" className={style['control-indicator']} onClick={this.handleClick} />
          </div>

          <div style={styles.swatch} onClick={this.handleClick}>
            <div style={styles.color} />
          </div>
        </div>

        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              disableAlpha
              width={`${popupWidth || '200px'}`}
              color={color}
              onChange={colorPickerChange}
              presetColors={presetColors || uniqueColors}
            />
          </div>
        ) : null}
        {/* placeholder for error_message */}
        <div style={{ height: '20px' }} />
      </div>
    );
  }
}

export default connect('branding', actions)(ColorPicker);
