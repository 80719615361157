import React, { Component } from 'react';
import { connect } from 'react-unistore';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import style from './style.module.scss';
import Button from '../../../../components/Button';
import linkboardActions from '../../../../actions/linkboardEditor';
import Content from '../../../../components/Content';
import Phone from '../../../../components/Phone';
import Linkboard from '../../../../components/Linkboard';
import Copy from '../../../../components/Copy';

class SuccessOverview extends Component {
  render() {
    const {
      linkboard,
      fallBackToPlaceHolderForHeader,
      fallBackToPlaceHolderForLogo,
      history,
      discard,
      message,
      usergroup,
    } = this.props;

    return (
      <div id={style['linkboard-preview-container']}>
        <Content successPage>
          <div>
            <h1>Well done!</h1>
            <h2>{message}</h2>

            <table className={`ReactTable ${style['table-general']}`}>
              <tr>
                <th>Title:</th>
                <td>{linkboard.title}</td>
              </tr>
              <tr>
                <th>Group:</th>
                <td>{usergroup}</td>
              </tr>
            </table>

            <p>
              <b>Shortlink:</b>
              {' '}
              <Copy value={linkboard.shortUrl} />
            </p>

            <Content button style={{ display: 'inline-block' }}>
              <Button
                label="Edit Linkboard"
                secondary
                onClick={async () => {
                  ReactGA.event({
                    category: 'Linkboard',
                    action: 'Edit',
                    label: 'Open Editor (from Success Page)',
                  });
                  await discard();
                  // pushing the active page does not work
                  history.push('/linkboards');
                  history.push(`/linkboards/edit/${linkboard.id}`);
                }}
              />
              <Button
                label="Linkboard overview"
                onClick={async () => {
                  ReactGA.event({
                    category: 'Linkboard',
                    action: 'Back to Overview',
                    label: 'Back to Linkboard Overview',
                  });
                  await discard();
                  history.push('/linkboards');
                }}
              />
            </Content>
          </div>

          <div className={style['linkboard-container']}>
            <Phone shadow scale={1}>
              <Linkboard
                fallBackToPlaceHolderForHeader={fallBackToPlaceHolderForHeader}
                fallBackToPlaceHolderForLogo={fallBackToPlaceHolderForLogo}
                linkboard={linkboard}
              />
            </Phone>
          </div>

        </Content>

      </div>
    );
  }
}

SuccessOverview.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  linkboard: PropTypes.shape({
    shortUrl: PropTypes.string.isRequired,
    usergroup: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  usergroup: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  fallBackToPlaceHolderForHeader: PropTypes.bool.isRequired,
  fallBackToPlaceHolderForLogo: PropTypes.bool.isRequired,
  discard: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { linkboardEditor, usergroups } = state;
  return {
    linkboard: linkboardEditor.workingCopy,
    usergroup: usergroups.filter(g => linkboardEditor.workingCopy.usergroup === g.id)[0].name,
    fallBackToPlaceHolderForHeader: linkboardEditor.config.useHeaderImage,
    fallBackToPlaceHolderForLogo: linkboardEditor.config.useLogoImage,
  };
}
export default connect(mapStateToProps, (store) => ({
  discard: async (...args) => linkboardActions.discard(store, ...args),
}))(SuccessOverview);
