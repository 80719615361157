import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import style from './style.module.scss';
import Button from '../../../../components/Button';
import linkboardActions from '../../../../actions/linkboardEditor';
import Content from '../../../../components/Content';
import Phone from '../../../../components/Phone';
import Accordion from '../../../../components/Accordion';
import Linkboard from '../../../../components/Linkboard';
import PopUp from '../../../../components/PopUp';
import PopUpStyle from '../../../../components/PopUp/popup.module.scss';
import ParagraphRequiredField from '../../../../components/ParagraphRequiredField';
import MetaDataSection from '../../../../components/Linkboard/Editor/MetaDataSection';
import HeadSection from '../../../../components/Linkboard/Editor/HeadSection';
import BrandingSection from '../../../../components/Linkboard/Editor/BrandingSection';
import ItemsSection from '../../../../components/Linkboard/Editor/ItemsSection';
import ButtonItem from '../../../../components/Linkboard/Editor/ItemsSection/ButtonItem';
import ProductItem from '../../../../components/Linkboard/Editor/ItemsSection/ProductItem';
import SocialItems from '../../../../components/Linkboard/Editor/ItemsSection/SocialItem';

class EditLinkboardForm extends Component {
  constructor() {
    super();
    this.state = {
      showDiscardWarning: false,
    };
    this.cancel = this.cancel.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleLinkboardPosition);
  }

  cancel() {
    const { history, discard } = this.props;
    this.setState({ showDiscardWarning: false });
    discard();
    history.push('/linkboards');
  }

  // eslint-disable-next-line class-methods-use-this
  handleLinkboardPosition() {
    const scrollPosition = window.pageYOffset;
    const linkboardViewer = document.getElementById('triggerElement').childNodes[0];

    if (scrollPosition >= 200) {
      linkboardViewer.classList.add('fixed');
    } else {
      linkboardViewer.classList.remove('fixed');
    }
  }

  render() {
    const {
      linkboard,
      fallBackToPlaceHolderForHeader,
      fallBackToPlaceHolderForLogo,
      branding,
      nextStep,
      headline,
    } = this.props;

    const { showDiscardWarning } = this.state;

    if (Object.keys(branding).length > 0) {
      window.addEventListener('scroll', this.handleLinkboardPosition);
    }

    return (
      <div>
        <h1 className="center">{headline}</h1>
        {Object.keys(branding).length > 0 && (
          <section className={style.content_linkboard}>
            <div>
              <Accordion>
                <MetaDataSection />
                <BrandingSection />
                <HeadSection />
                {linkboard.type === 'ecommerce' ? (
                  <ItemsSection
                    name="Products"
                    entity="product"
                    maxItems={20}
                    addButtonText="Add another product"
                    removeButtonText="Delete product"
                    noItemsMessage="Place one or more products on your Linkboard"
                    itemComponent={ProductItem}
                  />
                ) : null}
                <ItemsSection
                  name={linkboard.type !== 'ecommerce' ? 'Buttons & Links' : 'Button & Link'}
                  entity="button"
                  maxItems={linkboard.type === 'ecommerce' ? 1 : 20}
                  addButtonText="Add button"
                  removeButtonText="Delete button"
                  noItemsMessage={linkboard.type !== 'ecommerce' ? 'Place one or more buttons on your Linkboard' : 'Place a Button on your Linkboard'}
                  itemComponent={ButtonItem}
                />

                <ItemsSection
                  name="Social Links"
                  entity="social"
                  maxItems={20}
                  addButtonText="Add social link"
                  removeButtonText="Delete social link"
                  noItemsMessage="Place one or more Social Links on your Linkboard"
                  itemComponent={SocialItems}
                />
              </Accordion>

              <Content bar noPadding>
                <ParagraphRequiredField />
                <Content button>
                  <Button
                    label="Cancel"
                    secondary
                    onClick={() => {
                      this.setState({ showDiscardWarning: true });
                    }}
                  />
                  <Button label="Save" onClick={nextStep} />
                </Content>
              </Content>

            </div>
            <div id="triggerElement">
              <Phone shadow scale={1} resize>
                <Linkboard
                  fallBackToPlaceHolderForHeader={fallBackToPlaceHolderForHeader}
                  fallBackToPlaceHolderForLogo={fallBackToPlaceHolderForLogo}
                  linkboard={linkboard}
                />

              </Phone>
            </div>
          </section>
        )}

        {showDiscardWarning
        && (
          <PopUp
            onClose={() => {
              this.setState({ showDiscardWarning: false });
            }}
          >
            <div className={PopUpStyle.content_wrapper}>
              <h2>Do you really want to cancel?</h2>
              <p>
                Are you sure you want to stop creating your Linkboard?
                <br />
                Your changes will not be saved.
              </p>

              <div className={PopUpStyle.button_wrapper}>
                <Button label="Discard Changes" secondary onClick={() => { this.cancel(); }} />
                <Button
                  label="Keep Editing"
                  onClick={() => {
                    this.setState({ showDiscardWarning: false });
                  }}
                />
              </div>
            </div>
          </PopUp>
        )}
      </div>
    );
  }
}

EditLinkboardForm.propTypes = {
  headline: PropTypes.string.isRequired,
  linkboard: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  branding: PropTypes.shape({
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  domain: PropTypes.shape({}).isRequired,
  usergroup: PropTypes.shape({}).isRequired,
  fallBackToPlaceHolderForHeader: PropTypes.bool.isRequired,
  fallBackToPlaceHolderForLogo: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  discard: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    linkboardEditor, branding, domains, usergroups,
  } = state;
  return {
    linkboard: linkboardEditor.workingCopy,
    branding,
    domains,
    usergroups,
    fallBackToPlaceHolderForHeader: linkboardEditor.config.useHeaderImage,
    fallBackToPlaceHolderForLogo: linkboardEditor.config.useLogoImage,
  };
}

export default connect(mapStateToProps, (store) => ({
  updateLinkboardProperty:
   async (...args) => linkboardActions.updateLinkboardProperty(store, ...args),
  nextStep: async (...args) => linkboardActions.nextStep(store, ...args),
  discard: async (...args) => linkboardActions.discard(store, ...args),
}))(EditLinkboardForm);
