import React, { Component } from 'react';
import ReactGA from 'react-ga';
import style from './style.module.scss';
import Content from '../../components/Content';
import { getAccount, getCurrentUserName } from '../../util/user-management';

// eslint-disable-next-line react/prefer-stateless-function
class Inactive extends Component {
  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Account Inavtive',
    });
  }

  render() {
    const account = getAccount();
    console.log(account);

    return (
      <section id={style.accountInactive}>
        <Content small center>
          <div className={style.image_container}>
            <img src="/disactivated.png" alt="Your account is inactive" />
          </div>
          <h1>
            Hello&nbsp;
            {getCurrentUserName()}
            :
          </h1>

          <p>
            We are sorry, but this account has been deactivated by
            {' '}
            {account.firstname}
            {' '}
            {account.lastname}
            {' '}
            and is no longer
            available. Please contact the administrator if you have any questions.
          </p>
          <p>
            Feel free to open an account of your own by signing up
            {' '}
            <a href="https://beta.linkhawk.com/payment">here</a>
            .
          </p>
          <p>We value your participation. Please let us know if we can assist you further.</p>
          <p>Thank you for using Linkhawk.</p>

          <p>
            Sincerely,
            <br />
            The Linkhawk Team
          </p>

        </Content>
      </section>
    );
  }
}

export default Inactive;
