import React, { Component } from 'react';

import { connect } from 'react-unistore';
import style from '../../main.module.scss';
import logoImage from '../../../../assets/placeholder/logo.svg';
import actions from '../../../../actions';

// eslint-disable-next-line react/prefer-stateless-function
class LinkboardTemplateStandard extends Component {
  render() {
    const { linkboard, branding } = this.props;

    const linkboardStyles = {
      fontFamily: branding.font,
      backgroundColor: branding.backgroundColor,
      fontColor: branding.fontColor,
      bannerImage: branding.previewHeader ? branding.previewHeader : null,
      logoImage: branding.preview ? branding.preview : logoImage,
      buttonBackgroundColor: branding.primaryButtonColor,
      buttonTextColor: branding.primaryTextColor,
      headline: 'Headline',
      description: 'Description',
      type: 'standard',
      buttons: [
        {
          key: 'btn-a',
          backgroundColor: branding.primaryButtonColor,
          buttonId: 1,
          color: branding.primaryTextColor,
          text: 'Button',
          url: '',
        },
        {
          key: 'btn-b',
          backgroundColor: branding.primaryButtonColor,
          buttonId: 2,
          color: branding.primaryTextColor,
          text: 'Button',
          url: '',
        },
        {
          key: 'btn-c',
          backgroundColor: branding.primaryButtonColor,
          buttonId: 3,
          color: branding.primaryTextColor,
          text: 'Button',
          url: '',
        },
      ],
      socials: [
        {
          key: 'btn-a',
          backgroundColor: branding.primaryButtonColor,
          color: branding.primaryTextColor,
          iconType: 'socicon-facebook',
          shortUrl: '',
          targetUrl: '',
          views: '',
          id: 1,
        },
        {
          key: 'btn-b',
          backgroundColor: branding.primaryButtonColor,
          color: branding.primaryTextColor,
          iconType: 'socicon-twitter',
          shortUrl: '',
          targetUrl: '',
          views: '',
          id: 2,
        },
        {
          key: 'btn-c',
          backgroundColor: branding.primaryButtonColor,
          color: branding.primaryTextColor,
          iconType: 'socicon-instagram',
          shortUrl: '',
          targetUrl: '',
          views: '',
          id: 3,
        },
      ],
    };

    return (
      <div className={style['linkboard-container']} style={{ backgroundColor: linkboardStyles.backgroundColor }}>
        <div className={style['banner-container']}>
          {linkboardStyles.bannerImage
            && <img src={linkboardStyles.bannerImage} alt="Linkboard Banner" />}
        </div>
        <div className={style['content-container']}>
          <div>
            <div className={style['logo-container']}>
              <img src={linkboardStyles.logoImage} alt={linkboardStyles.logoImage} />
            </div>

            {linkboardStyles.headline && (
            <h1
              className={style.headline}
              style={{
                fontFamily: linkboardStyles.fontFamily,
                color: linkboardStyles.fontColor,
              }}
            >
              {linkboardStyles.headline}
            </h1>
            ) }

            {linkboardStyles.description && (
            <p
              className={style.description}
              style={{
                fontFamily: linkboardStyles.fontFamily,
                color: linkboardStyles.fontColor,
              }}
            >
              {linkboardStyles.description}
            </p>
            )}

            {linkboardStyles.buttons
            && (linkboardStyles.buttons.map((button) => (
              <a
                key={button.key}
                className={style.button}
                style={{
                  fontFamily: linkboardStyles.fontFamily,
                  color: (button.color) ? button.color : branding.primaryTextColor,
                  backgroundColor: (button.backgroundColor) ? button.backgroundColor : branding.primaryButtonColor,
                }}
                href={button.url ? button.url : 'https://linkhawk.com'}
                target="_blank"
              >
                {' '}
                {button.text}
              </a>
            )))}

          </div>
          {
            (linkboardStyles.socials)
            && (
            <div className={style['icon-container']}>
              {
                linkboardStyles.socials.map((icon) => (
                  <a
                    key={icon.key}
                    href={icon.targetUrl ? icon.targetUrl : '#'}
                    target={icon.targetUrl ? '_blank' : '_self'}
                    style={{
                      color: (icon.color) ? icon.color : branding.primaryTextColor,
                      backgroundColor: (icon.backgroundColor) ? icon.backgroundColor : branding.primaryButtonColor,
                    }}
                    className={style['icon-outer']}
                  >
                    <span className={icon.iconType} style={{ color: icon.color }}>{icon.text}</span>
                  </a>
                ))
              }
            </div>
            )
          }

        </div>
      </div>
    );
  }
}

export default connect('branding', actions)(LinkboardTemplateStandard);
