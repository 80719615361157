import React, { Component } from 'react';
import style from './style.module.scss';
import Step1 from './Step1';
import Step2 from './Step2';
import Content from '../../../components/Content';
import MultiSteps from '../../../components/MultiSteps';

export default class PaymentSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      disabledSteps: [2],
      paymentProvider: null,
      paymentMethod: null,
      payPal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.handleDisabledSteps = this.handleDisabledSteps.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  previousStep() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  nextStep() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  handleDisabledSteps(disabledSteps) {
    this.setState({ disabledSteps });
  }

  updateData(data) {
    this.setState({ ...this.state, ...data });
  }

  render() {
    const { currentStep, disabledSteps } = this.state;
    const getCurrentStep = (step) => this.setState({ currentStep: step });

    return (
      <Content>

        <MultiSteps
          description={['Payment', 'Overview']}
          totalSteps={2}
          currentStep={currentStep}
          disabledSteps={disabledSteps}
          getCurrentStep={getCurrentStep}
        />

        <div className={style.steps_wrapper}>
          {currentStep === 1
            && (
            <Step1
              payPal={this.state.payPal}
              paymentProvider={this.state.paymentProvider}
              onSelectChange={this.handleSelectChange}
              nextStep={this.nextStep}
              updateData={this.updateData}
              handleDisabledSteps={this.handleDisabledSteps}
              history={this.props.history}
            />
            )}

          {currentStep === 2
            && (
            <Step2
              payPal={this.state.payPal}
              paymentProvider={this.state.paymentProvider}
              paymentMethod={this.state.paymentMethod}
              onChange={this.handleChange}
              prevStep={this.previousStep}
              updateData={this.updateData}
              history={this.props.history}
            />
            )}
        </div>

      </Content>
    );
  }
}
