import React from 'react';
import style from './style.module.scss';

const Separator = (props) => (
  <div className={props.primary ? style.separator + ' ' + style.primary : style.separator}>
    {props.text && (
    <h4 className={style.text}>
      {props.index}
      .
      {' '}
      {props.text}
    </h4>
    )}
    <span className={style.line} />
  </div>
);

export default Separator;
