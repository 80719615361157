import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import Content from '../../../components/Content';
import MultiSteps from '../../../components/MultiSteps';
import CampaignsEditForm from './Step1';
import CampaignsEditSuccess from './Step2';
import actions from '../../../actions';

class CampaignsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalSteps: 2,
      currentStep: 1,
      disabledSteps: [2],
    };

    this.setStep = this.setStep.bind(this);
    this.handleDisabledSteps = this.handleDisabledSteps.bind(this);
  }

  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Campaign Edit',
    });
  }

  setStep(currentStep) {
    this.setState({ currentStep });
  }

  handleDisabledSteps(disabledSteps) {
    this.setState({ disabledSteps });
  }

  render() {
    const { history, match } = this.props;
    const { id } = match.params;
    const campaignId = parseInt(id, 10);
    const { campaign } = this.state;
    const { totalSteps, currentStep, disabledSteps } = this.state;
    const getCurrentStep = (step) => this.setState({ currentStep: step });
    const setCampaign = (state) => this.setState({ campaign: state.campaign });

    return (
      <Content>
        <MultiSteps
          totalSteps={totalSteps}
          currentStep={currentStep}
          disabledSteps={disabledSteps}
          getCurrentStep={getCurrentStep}
        />

        {currentStep === 1
        && (
        <CampaignsEditForm
          setStep={this.setStep}
          handleDisabledSteps={this.handleDisabledSteps}
          id={campaignId}
          setCampaign={setCampaign}
          history={history}
        />
        )}

        {currentStep === 2
        && (
        <CampaignsEditSuccess
          id={campaignId}
          campaign={campaign}
          history={history}
        />
        )}

      </Content>
    );
  }
}

CampaignsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default connect('domains,tags,usergroups', actions)(CampaignsEdit);
