import React from 'react';
import style from './style.module.scss';

const Button = (props) => {
  const button = `${props.secondary ? style.button_secondary : style.button_primary} width_${props.width} ${props.className}`;
  const buttonOnClick = props.submitButtonOnClick || props.resetButtonOnClick || props.onClick;
  const type = props.type ? props.type : props.secondary ? 'Button' : 'Submit';
  const label = props.label ? props.label : 'Button';

  return (
    <button
      style={props.style}
      className={button}
      onClick={(event) => { buttonOnClick(event); }}
      type={type}
      disabled={props.isDisabled}
      title={props.title}
    >
      {`${label}`}

    </button>
  );
};

export default Button;
