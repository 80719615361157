import React from 'react';
import style from './style.module.scss';

const Grid = (props) => (
  <div className={style.grid}>
    {props.children}
  </div>
);

export default Grid;
