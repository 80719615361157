import React, { Component } from 'react';
import { connect } from 'react-unistore';
import ReactGA from 'react-ga';
import style from '../style.module.scss';
import Form from '../../../../components/Form';
import SecondaryButton from '../../../../components/SecondaryButton';
import { getCode } from '../../../../api/data-management';
import actions from '../../../../actions';
import Button from '../../../../components/Button';
import Content from '../../../../components/Content';
import { validateRequiredFields } from '../../../../util/validates';
import { convertToSelectValue } from '../../../../util/helperFunctions';
import Spinner from '../../../../components/Spinner';
import ParagraphRequiredField from '../../../../components/ParagraphRequiredField';

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 2,
      title: '',
      usergroup: '',
      url: '',
      shortUrls: [
        {
          linkId: 1,
          domain: '',
          code: '',
          tags: [],
          selectedTags: [],
        },
        {
          linkId: 2,
          domain: '',
          code: '',
          tags: [],
          selectedTags: [],
        },
      ],
      buttonShow: false,
      fetchedCodes: false,
      tagsLoading: false,
    };

    this.increase = this.increase.bind(this);
    this.decrease = this.decrease.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleIndexSelectChange = this.handleIndexSelectChange.bind(this);
    this.handleIndexTagsChange = this.handleIndexTagsChange.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.handleCreateTag = this.handleCreateTag.bind(this);
    this.checkForButton = this.checkForButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const usergroup = this.props.usergroups[0].id;
    const domain = this.props.domains[0].id;

    const promises = this.state.shortUrls.map(async (shortUrl) => {
      const unusedCode = await getCode();
      return {
        ...shortUrl,
        code: unusedCode.code,
        domain,
      };
    });
    const shortUrls = await Promise.all(promises);

    this.setState({
      fetchedCodes: true,
      usergroup,
      shortUrls,
    });

    this.checkForButton();
  }

  checkForButton() {
    if (this.state.index > 2) {
      this.setState({
        buttonShow: true,
      });
    } else {
      this.setState({
        buttonShow: false,
      });
    }
  }

  async increase() {
    const unusedCode = await getCode();
    this.setState({
      shortUrls: [
        ...this.state.shortUrls,
        {
          code: unusedCode.code,
          linkId: this.state.index + 1,
          domain: this.props.domains[0].id,
          tags: [],
          selectedTags: [],
        },
      ],
      index: this.state.index + 1,
    });

    this.checkForButton();
  }

  decrease() {
    if (this.state.shortUrls.length < 3) return;

    const { shortUrls } = this.state;
    shortUrls.pop();

    this.setState({
      shortUrls,
      index: this.state.index - 1,
    });

    this.checkForButton();
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSelectChange(items, name) {
    const value = Array.isArray(items) ? items.map((item) => item.value) : items.value;
    this.setState({ [name]: value });
  }

  handleIndexChange(event, name, index) {
    const shortUrls = this.state.shortUrls.map((shortUrl) => (shortUrl.linkId === index + 1
      ? {
        ...shortUrl,
        [name]: event.target.value,
      }
      : shortUrl));
    this.setState({ shortUrls });
  }

  handleIndexSelectChange(item, name, index) {
    const shortUrls = this.state.shortUrls.map((shortUrl) => (shortUrl.linkId === index + 1
      ? {
        ...shortUrl,
        [name]: item.value,
      }
      : shortUrl));
    this.setState({ shortUrls });
  }

  handleIndexTagsChange(items, index) {
    const shortUrls = this.state.shortUrls.map((shortUrl) => (shortUrl.linkId === index + 1
      ? {
        ...shortUrl,
        selectedTags: items,
        tags: items !== null ? items.map((item) => ({
          id: item.value,
          name: item.label,
        })) : [],
      }
      : shortUrl));
    this.setState({ shortUrls });
  }

  async handleCreateTag(value, index) {
    this.setState({ tagsLoading: true });
    await this.props.createTag(JSON.stringify({ name: value }));
    // retrieve the tag that was newly added
    const latestTag = this.props.tags[this.props.tags.length - 1];
    const shortUrls = this.state.shortUrls.map((shortUrl) => (shortUrl.linkId === index + 1
      ? {
        ...shortUrl,
        selectedTags: [...shortUrl.selectedTags, convertToSelectValue(latestTag)],
        tags: [...shortUrl.tags, latestTag],
      }
      : shortUrl));
    this.setState({
      shortUrls,
      tagsLoading: false,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { toggleLoading, toggleSnackbar, createCampaign } = this.props;

    const requiredFields = Array.from(document.querySelectorAll('[data-required], [data-type=\'url\']'));
    const error = validateRequiredFields(requiredFields);

    if (!error) {
      const {
        title, url, usergroup, shortUrls,
      } = this.state;
      const payload = JSON.stringify({
        title,
        url,
        usergroup,
        shortUrls: shortUrls.map((link) => ({ ...link, tags: link.tags.map((tag) => tag.id) })),
      });

      toggleLoading(true);
      try {
        ReactGA.event({
          category: 'Campaign',
          action: 'Create',
          label: 'Create Campaign',
        });
        await createCampaign(payload);
        toggleLoading(false);
      } catch (err) {
        toggleLoading(false);
        toggleSnackbar(err.message);
        return;
      }

      if (this.props.error) {
        return;
      }

      this.props.handleDisabledSteps([1]);
      this.props.setStep(2);
    } else {
      this.props.toggleSnackbar('Please complete all required fields!');
    }
  }

  render() {
    const { fetchedCodes } = this.state;

    if (!fetchedCodes) {
      return (<Spinner show />);
    }
    return (
      <div>
        <h1 className="center">Create a new campaign</h1>

        <Form>
          <Form.Input
            type="text"
            name="title"
            label="Title*"
            width={25}
            placeholder="Title"
            data-required
            errorMessage="Title name must not be empty"
            value={this.state.title}
            onInput={this.handleChange}
          />

          <Form.SelectField
            name="usergroup"
            label="Usergroup"
            width={25}
            options={this.props.usergroups}
            value={this.state.usergroup}
            placeholder="Example"
            onChange={(items) => this.handleSelectChange(items, 'usergroup')}
          />

          <Form.Input
            name="url"
            onInput={this.handleChange}
            type="text"
            label="Target URL*"
            width={50}
            placeholder="https://www.example.com"
            data-required
            errorMessage="Target URL name must not be empty"
            data-type="url"
            value={this.state.url}
          />

          {this.state.shortUrls.map((link, index) => (

            <div id={`link_${index}`} key={index + 1} className={style.formInputs}>

              <h3>
                Link
                {index + 1}
              </h3>

              <div className={style.field_wrapper}>

                <Form.SelectField
                  name={`dynamic_domain_${index}`}
                  label="Branded Domain"
                  width={25}
                  options={this.props.domains}
                  value={link.domain}
                  placeholder="Select"
                  index={index}
                  onChange={(item) => this.handleIndexSelectChange(item, 'domain', index)}
                />

                <Form.Input
                  type="text"
                  label="Slash-Tag*"
                  data-type="slash-tag"
                  name={`dynamic_code_${index}`}
                  width={25}
                  placeholder="q5a4s6"
                  value={this.state.shortUrls[index].code}
                  data-required
                  errorMessage="Code must not be empty"
                  onInput={(event) => this.handleIndexChange(event, 'code', index)}
                />

                <Form.CreateableMultiSelectField
                  name={`dynamic_tags_${index}`}
                  label="Tags"
                  placeholder="Tag 1, Tag 2, Tag 3"
                  options={this.props.tags}
                  value={link.selectedTags}
                  width={50}
                  isLoading={this.state.tagsLoading}
                  isDisabled={this.state.tagsLoading}
                  onCreateOption={(value) => this.handleCreateTag(value, index)}
                  onChange={(items) => this.handleIndexTagsChange(items, index)}
                />
              </div>
            </div>

          ))}

          <div className={style.button_wrapper}>
            <SecondaryButton
              onClick={this.increase}
              text="Add another link"
              color="secondary"
              icon="plus"
            />

            {this.state.buttonShow === true
              ? (
                <SecondaryButton
                  onClick={this.decrease}
                  text="Delete last link"
                  color="primary"
                  icon="minus"
                />
              )
              : null}

          </div>

          <Content bar noPadding>
            <ParagraphRequiredField />
            <Content button>
              <Button
                label="Cancel"
                secondary
                onClick={() => this.props.history.push('/campaigns')}
              />
              <Button label="Create campaign" onClick={this.handleSubmit} />
            </Content>
          </Content>

        </Form>
      </div>
    );
  }
}

export default connect('domains,tags,usergroups', actions)(Step1);
