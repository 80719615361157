import { get, post } from './common';

export const getDomains = async () => get('/api/domains');

export const getExternalDomains = async () => get('/api/domains/external');

export const getExternalDomain = async (id) => get(`/api/domains/external/${id}`);

export const activateDomain = async (id) => post(`/api/domains/external/${id}/activate`, 'POST', null);

export const addDomain = async (domainName) => post('/api/domains/external', 'POST', JSON.stringify({ domain: domainName }));

export const updateAccountDefaultDomain = async (id) => post(`/api/domains/set-default/${id}`, 'POST', null);
