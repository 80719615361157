import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import Content from '../../../components/Content';
import Spinner from '../../../components/Spinner';
import { getAccounts } from '../../../api/accounting';
import Button from '../../../components/Button';

class AccountingAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: null,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    const accounts = await getAccounts();

    this.setState({ account: accounts.filter((acc) => acc.id === parseInt(id, 10))[0] });
  }

  render() {
    const { history } = this.props;
    const { account } = this.state;
    if (account === null) {
      return (
        <Spinner show />
      );
    }

    const columns = [
      {
        Header: 'Date',
        name: 'invoiceDate',
        id: 'invoiceDate',
        filterable: false,
        sortable: false,
        Cell: (props) => (
          <div>
            <Link
              to={`/accounting/account/${account.id}/edit/${props.original.number}`}
            >
              {`${moment(props.original.invoiceDate).format('YYYY-MM-DD')} Invoice ${props.original.number}`}

            </Link>
          </div>
        ),
      },
      {
        Header: 'PDF',
        name: 'pdf',
        id: 'pdf',
        filterable: false,
        sortable: false,
        Cell: (props) => (
          <div>
            <a
              href={props.original.fileUrl}
            >
              Download

            </a>
          </div>
        ),
      },
      {
        Header: 'Number',
        name: 'number',
        id: 'number',
        filterable: false,
        sortable: false,
        Cell: (props) => (
          <div>
            {`#${props.original.number}`}
          </div>
        ),
      },
      {
        Header: 'Revision',
        name: 'revision',
        id: 'revision',
        filterable: false,
        sortable: false,
        Cell: (props) => (
          <div>
            {props.original.revision}
          </div>
        ),
      },
    ];

    return (
      <Content>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <h1>{`Customer Invoice Overview (${account.name})`}</h1>
            <p>{`Account-Name: ${account.name}; ID: ${account.id}`}</p>
          </div>
          <div>
            <Button
              label="Create Invoice"
              onClick={() => {
                history.push(`/accounting/account/${account.id}/new`);
              }}
            />
          </div>
        </div>

        <ReactTable
          data={account.invoices}
          manual
          columns={columns}
          minRows={0}
          resizable={false}
          showPagination={false}
        />
        <br />
        <hr />
        <br />
        <Link
          to="/accounting/"
        >
          Back to Account Overview
        </Link>
      </Content>
    );
  }
}

AccountingAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default AccountingAccount;
