import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import style from '../style.module.scss';
import { resetPassword } from '../../../api/users';
import Content from '../../../components/Content';
import { LinkhawkServiceEntityNotFound } from '../../../api/common';
import Form from '../../../components/Form';
import Button from '../../../components/Button';
import actions from '../../../actions';
import { clearErrors } from '../../../util/validates';
import Spinner from '../../../components/Spinner';

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      success: null,
      userNotFound: null,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Password Reset: Request Reset',
    });
  }

  handleChange(event) {
    clearErrors();
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async submit(event) {
    event.preventDefault();
    const {
      email,
    } = this.state;
    const {
      toggleSnackbar,
    } = this.props;

    try {
      this.setState({ loading: true });
      await resetPassword(email);
      this.setState({
        success: true,
        userNotFound: false,
        loading: false,
      });
    } catch (err) {
      if (err instanceof LinkhawkServiceEntityNotFound) {
        this.setState({ userNotFound: true });
        toggleSnackbar('User not found.');
      }
      this.setState({ success: false, loading: false });
    }
  }

  render() {
    const {
      email, success, userNotFound, loading,
    } = this.state;
    return (

      <Content center style={{ minHeight: '100vh' }}>

        {!success ? (
          <div className={style.password_container}>
            <img src="/password.png" alt="Password" />
            <h1>Forgot password?</h1>
            <p>
              Just let us know the email you use to sign in to
              <br />
              Linkhawk and we’ll help you get your password back.
            </p>
            <div className={style.password_wrapper}>
              <form>
                <Form.Input
                  label="Email address"
                  type="text"
                  name="email"
                  width={100}
                  value={email}
                  error={userNotFound === true}
                  onChange={this.handleChange}
                />
                <Button
                  style={{ marginTop: 0 }}
                  label="Reset password"
                  type="submit"
                  submitButtonOnClick={this.submit}
                />
              </form>
            </div>
          </div>
        ) : (
          <div className={style.password_container}>
            <div className={style.password_wrapper}>
              <h1>Recovery Email Sent!</h1>
              <p>Please check your email for next steps to reset your password.</p>
              <p>
                If you can’t see the email, it might be in your spam folder, or your Linkhawk
                account might be under a
                different email address.
              </p>
            </div>
          </div>
        )}
        <Spinner show={loading} />
      </Content>
    );
  }
}

PasswordReset.propTypes = {
  toggleSnackbar: PropTypes.func.isRequired,
};

export default connect('', actions)(PasswordReset);
