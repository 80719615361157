import React, { Component } from 'react';
import { connect } from 'react-unistore';
import { Elements, StripeProvider } from 'react-stripe-elements';
import style from './style.module.scss';
import Button from '../../../../components/Button';
import CreditCardForm from '../../../../components/CreditCartForm';
import actions from '../../../../actions';
import Content from '../../../../components/Content';
import { fetchAccount } from '../../../../api/account';
import Spinner from '../../../../components/Spinner';

class Step1 extends Component {
  constructor() {
    super();
    this.state = {
      stripe: null,
      accountInfoUpdated: false,
    };

    this.submit = this.submit.bind(this);
    this.showCreditCard = this.showCreditCard.bind(this);
    this.showPayPal = this.showPayPal.bind(this);
  }

  async componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) });
    } else {
      document.querySelector('#stripe-js')
        .addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) });
        });
    }

    // make sure subscriber count is up to date
    await fetchAccount();
    this.setState({ accountInfoUpdated: true });
  }

  submit(data) {
    this.props.updateData({
      ...data,
    });
    this.props.nextStep();
    this.props.handleDisabledSteps([]);
  }

  // switch to PayPal
  showPayPal() {
    this.props.updateData({ payPal: true });
  }

  // switch to credit card
  showCreditCard() {
    this.props.updateData({ payPal: false });
  }

  cancel() {
    const { history } = this.props;
    history.push('/payment');
  }

  render() {
    const { accountInfoUpdated } = this.state;
    if (!accountInfoUpdated) {
      return (<Spinner show />);
    }
    return (
      <StripeProvider stripe={this.state.stripe}>
        <section className={style.steps_wrapper}>
          <h1>Choose payment</h1>

          <div id={style.wrapper}>
            <div id={style.primary}>
              <div className={style.toggler}>
                <div
                  id="creditCard"
                  className={!this.props.payPal && 'active'}
                  onClick={this.showCreditCard}
                >
                  <p>
                    <img src="/icons/payment-methods/icons/credit-card.svg" alt="Credit card"/>
                    Credit
                    card
                  </p>
                </div>
                <div
                  id="payPal"
                  className={this.props.payPal && 'active'}
                  onClick={this.showPayPal}
                >
                  <p>
                    <img src="/icons/payment-methods/icons/paypal-pp.svg" alt="PayPal"/>
                    PayPal
                  </p>

                </div>
              </div>
              <div id="payPalBox" className={style.payPalBox} />
              <div id="creditCardBox" className={style.payPalBox} />
            </div>

          </div>
          <div>

            { this.props.payPal === false
              ? (
                <Elements>
                  <CreditCardForm
                    previousStep={this.props.previousStep}
                    submit={this.submit}
                    updateData={this.props.updateData}
                    buttonBackOnClick={() => this.cancel(this)}
                    mainButtonText="Next"
                    history={this.props.history}
                  />
                </Elements>
              )
              : (
                <div>
                  <div>
                    <p>
                      You have chosen
                      <strong> PayPal</strong>
                      {' '}
                      as your payment method.
                    </p>
                    <p>In the next step you can check your order again and you will be forwarded to the payment process. </p>
                  </div>

                  <Content button>
                    <Button label="Cancel" secondary onClick={() => this.cancel(this)} />
                    <Button label="Next" type="submit" onClick={this.submit} />
                  </Content>
                </div>
              )}

          </div>

        </section>
      </StripeProvider>
    );
  }
}

export default connect('', actions)(Step1);
