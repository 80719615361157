import React from 'react';
import { connect } from 'react-unistore';
import style from './style.module.scss';
import actions from '../../actions';

const Phone = (props) => {
  const scale = props.scale ? { transform: `scale(${props.scale})` } : { transform: 'scale(0.85)' };
  const module = `${props.shadow ? style.shadow : ''} ${props.resize ? style.resize : ''}`;

  return (
    <div className={`${style.phone} ${module}`} style={scale}>
      <div className={style.inner}>
        {props.children}
      </div>
      {props.headline && <h3 className={style.headline}>{props.headline}</h3>}
    </div>
  );
};

export default connect('', actions)(Phone);
