import React from 'react';
import { defaults } from 'react-chartjs-2';
import merge from 'lodash.merge';

// export const Tooltip = (props) => (
//   <div className="tooltip-content">
//     <div className="tooltip-label">{props.date}</div>
//     <div className="tooltip-value">{props.value}</div>
//     <div className="tooltip-caret" />
//   </div>
// );

export const customTooltips = (tooltip, id, unit) => {
  // tooltip will be false if tooltip is not visible or should be hidden
  if (!tooltip) { return; }

  // Otherwise, tooltip will be an object with all tooltip properties like:
  // tooltip.backgroundColor = '#000';
  tooltip.mode = 'index';
  tooltip.intersect = true;
  tooltip.yPadding = 10;
  tooltip.xPadding = 10;
  // tooltip.bodyFontColor = '#FFF';
  tooltip.borderWidth = 0.05;
  tooltip.cornerRadius = 0;
  tooltip.displayColors = false;

  // Tooltip Element
  const tooltipEl = document.getElementById(id);
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set custom tooltip
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body[0].lines;
    const tooltipData = bodyLines[0];
    // Custom tooltip requires an id for proper positioning

    // Create inner html
    const tooltipTitle = `${tooltipData.xLabel
      ? tooltipData.xLabel
      : tooltipData.match(':') != null
        ? tooltipData.split(':')[0]
        : ''}`;
    const tooltipValue = tooltipData.value !== false && tooltipData.xLabel
      ? `${tooltipData.value}${tooltipData.percent
        ? '%'
        : ''}`
      : typeof tooltipData === 'string'
        ? tooltipData.split(':')[1]
        : '';

    let color = 'gray';
    switch (tooltipTitle) {
      case 'Linkboards':
        color = 'rgba(234 ,206 ,239, 1.0)';
        break;
      case 'Links':
        color = 'rgba(255, 183, 144, 1)';
        break;
      case 'Campaigns':
        color = 'rgba(194,212,177, 1)';
        break;
      default:
        color = 'gray';
    }

    tooltipEl.style.backgroundColor = color;
    tooltipEl.innerHTML = '<div class="tooltip-content">';
    tooltipEl.innerHTML += `<div class="tooltip-label">${tooltipTitle}</div>`;
    tooltipEl.innerHTML += `<div class="tooltip-value">${addDecimalDivider(tooltipValue, ',')}</div>`;
    tooltipEl.innerHTML += `<div style="border-color: ${color} transparent transparent transparent" class="tooltip-caret"></div>`;
    tooltipEl.innerHTML += '</div';

    // Set inner html to tooltip
    const chartElement = tooltipEl.parentNode.getBoundingClientRect();
    // Calculate position
    const positionY = chartElement.top + tooltip.yPadding + (tooltipEl.getBoundingClientRect().height / 2);
    const positionX = chartElement.left - tooltip.xPadding + (tooltipEl.getBoundingClientRect().width / 2);
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${positionX + tooltip.caretX + 1.5}px`;
    tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  }
};

// Make chartjs use the custom tooltip
// merge(defaults, {
//   global: {
//     tooltips: {
//       enabled: false,
//       custom: customTooltips,
//       callbacks: {
//         label: (tooltipItem, data) => {
//           const index = tooltipItem.datasetIndex === undefined ? tooltipItem.index : tooltipItem.datasetIndex;
//           const itemData = data.datasets[index];
//           // Return custom data to tooltip, these will be available inside the tooltip.body
//           return {
//             id: data.id, pictureUrl: itemData.pictureUrl, label: itemData.label, value: tooltipItem.yLabel, xLabel: tooltipItem.xLabel, percent: data.percent,
//           };
//         },
//       },
//     },
//   },
// });

export const addDecimalDivider = (number, divider) => {
  number += '';
  const x = number.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1${divider}$2`);
  }
  return x1 + x2;
};
