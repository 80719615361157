import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import linkboardActions, {
  propertyHasValidationErrors,
  propertiesHaveValidationErrors,
} from '../../../../actions/linkboardEditor';
import AccordionItem from '../../../Accordion/Item';
import Form from '../../../Form';

class MetaDataSection extends Component {
  constructor(props) {
    super(props);
    this.state = { showAdvanderdOptions: false };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { updateLinkboardProperty } = this.props;
    updateLinkboardProperty(event.target.name, event.target.value);
  }

  render() {
    const {
      selectedDomain,
      selectedUserGroup,
      title,
      code,
      usergroups,
      domains,
      pixelId,
      policyUrl,
      expandedSection,
      toggleSection,
      validationData,
      isEditing,
    } = this.props;

    const { showAdvanderdOptions } = this.state;

    const SECTION_NAME = 'meta-data';
    return (
      <AccordionItem
        type="checkbox"
        checked={expandedSection === SECTION_NAME}
        onClick={() => toggleSection(SECTION_NAME)}
        headline="Linkboard Title & URL"
        id="information"
        error={
          propertiesHaveValidationErrors(
            [
              'title',
              'domain',
              'usergroup',
              'code',
              'pixelId',
              'policyUrl',
            ], validationData,
          )
}
      >
        <Form>
          <Form.Input
            data-required
            label="Title*"
            placeholder="Title"
            width={100}
            name="title"
            value={title}
            onInput={this.onChange}
            error={propertyHasValidationErrors('title', validationData)}
            errorMessage={propertyHasValidationErrors('title', validationData) ? validationData.title.message : null}
          />
          <Form.SelectField
            label="Branded Domain*"
            width={50}
            options={domains}
            name="domain"
            isDisabled={isEditing}
            data-type="url"
            value={selectedDomain}
            placeholder="abc.de"
            onChange={(item) => this.onChange({ target: { name: 'domain', value: item.value } })}
            error={propertyHasValidationErrors('domain', validationData)}
            errorMessage={propertyHasValidationErrors('domain', validationData) ? validationData.domain.message : null}
          />
          <Form.Input
            data-required
            width={50}
            name="code"
            data-type="slash-tag"
            label="Slash-Tag*"
            value={code}
            isDisabled={isEditing}
            onChange={this.onChange}
            error={propertyHasValidationErrors('code', validationData)}
            errorMessage={propertyHasValidationErrors('code', validationData) ? validationData.code.message : null}
          />
          <Form.SelectField
            data-required
            label="User group*"
            width={100}
            options={usergroups}
            name="usergroup"
            value={selectedUserGroup}
            id="usergroup"
            placeholder="Usergroup"
            onChange={(item) => this.onChange({ target: { name: 'usergroup', value: item.value } })}
            error={propertyHasValidationErrors('usergroup', validationData)}
            errorMessage={propertyHasValidationErrors('usergroup', validationData) ? validationData.usergroup.message : null}
          />
        </Form>
        <AccordionItem
          type="checkbox"
          secondary
          checked={showAdvanderdOptions}
          onClick={() => this.setState({ showAdvanderdOptions: !showAdvanderdOptions })}
          headline="Advanced options"
          id="advancedOptions"
          error={
            propertiesHaveValidationErrors(
              [
                'pixelId',
                'policyUrl',
              ], validationData,
            )
  }
        >
          <Form.Input
            label="Facebook pixel id"
            labelLink="https://linkhawk.helpscoutdocs.com/article/142-why-do-i-need-a-facebook-pixel-id"
            placeholder="Pixel ID"
            width={100}
            name="pixelId"
            value={pixelId}
            onInput={this.onChange}
            error={propertyHasValidationErrors('pixelId', validationData)}
            errorMessage={propertyHasValidationErrors('pixelId', validationData) ? validationData.pixelId.message : null}
          />

          <Form.Input
            label="Link to data privacy policy"
            placeholder="https://yourwebsite.com/policy"
            width={100}
            name="policyUrl"
            data-type="url"
            value={policyUrl}
            onInput={this.onChange}
            containerStyle={{ paddingBottom: '20px' }}
            error={propertyHasValidationErrors('policyUrl', validationData)}
            errorMessage={propertyHasValidationErrors('policyUrl', validationData) ? validationData.policyUrl.message : null}
          />
        </AccordionItem>
      </AccordionItem>

    );
  }
}

MetaDataSection.propTypes = {
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selectedDomain: PropTypes.number.isRequired,
  selectedUserGroup: PropTypes.number.isRequired,
  pixelId: PropTypes.string.isRequired,
  policyUrl: PropTypes.string.isRequired,
  updateLinkboardProperty: PropTypes.func.isRequired,
  toggleSection: PropTypes.func.isRequired,
  domains: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.number.isRequired,
  })).isRequired,
  usergroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.number.isRequired,
  })).isRequired,
  validationData: PropTypes.shape({}).isRequired,
  expandedSection: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    linkboardEditor, domains, usergroups,
  } = state;
  return {
    code: linkboardEditor.workingCopy.code,
    title: linkboardEditor.workingCopy.title,
    selectedDomain: linkboardEditor.workingCopy.domain,
    selectedUserGroup: linkboardEditor.workingCopy.usergroup,
    pixelId: linkboardEditor.workingCopy.pixelId,
    policyUrl: linkboardEditor.workingCopy.policyUrl,
    domains,
    usergroups,
    expandedSection: linkboardEditor.config.expandedSection,
    validationData: linkboardEditor.validation,
    isEditing: linkboardEditor.workingCopy.id !== -1,
  };
}

export default connect(mapStateToProps, (store) => ({
  updateLinkboardProperty:
     async (...args) => linkboardActions.updateLinkboardProperty(store, ...args),
  toggleSection: async (...args) => linkboardActions.toggleSection(store, ...args),
}))(MetaDataSection);
