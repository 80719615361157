import moment from 'moment-timezone';
import { get, post } from './common';
import { downloadCsvFile } from '../util/helperFunctions';

/**
 * Get a Linkboard from DB
 *
 * @param {Object} data
 */
export const getLinkboards = async (data) => get(`/api/linkboards/${data}`, { timezone: moment.tz.guess() });

/**
 * Get capmaigns from DB
 *
 * @param {Object} data
 */
export const getCampaigns = async (data, dates = null) => {
  const dateFilter = dates != null
    ? {
      startDate: dates.dateStart,
      endDate: dates.dateEnd,
    }
    : undefined;

  return get(`/api/campaigns/${data}`, dateFilter);
};

/**
 * Get an unused shortcode
 * @param {number} amount The amount of codes to be retrieved, defaults to null
 */
export const getCode = async (amount = null) => {
  const resource = amount !== null ? `/api/code?amount=${amount}` : '/api/code';
  return get(resource);
};

export const downloadCsv = async (resource, id, dates) => {
  let res = {};

  const dateFilter = {
    startDate: dates.dateStart,
    endDate: dates.dateEnd,
    timezone: moment.tz.guess(),
  };

  res = await get(`/api/${resource}/${id}/csv-download`, dateFilter);

  const blob = await res.blob();
  return downloadCsvFile(blob);
};

export const validateEmail = async (email) => post('/api/public/unique-email', 'POST', JSON.stringify({ email }));
export const validateCode = async (code) => post('/api/unique-code', 'POST', JSON.stringify({ code }));
/**
 * Get random background from pictures.linkhawk.com
 */

export const getLatestLinks = async () => get('/api/links/latest-10');
