import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import style from './style.module.scss';
import Copy from '../../components/Copy';
import Content from '../../components/Content';
import { addDecimalDivider } from '../../components/Charts/Tooltip';
import actions from '../../actions';
import { getCurrentUserName } from '../../util/user-management';
import LineChart from '../../components/Charts/LineChart';
import { getAnalytics, getAnalyticsUserDashboardData } from '../../api/analytics';
import ButtonLink from '../../components/Button/ButtonLink';
import Spinner from '../../components/Spinner';
import Button from '../../components/Button';
import {Redirect} from "react-router-dom";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analytics: null,
      userData: null,
    };
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Dashboard',
    });
    const dateStart = moment()
      .startOf('day')
      .add(12, 'hours')
      .subtract(14, 'days')
      .format('YYYY-MM-DD');
    const dateEnd = moment()
      .startOf('day')
      .subtract(12, 'hours')
      .format('YYYY-MM-DD');

    const userData = await getAnalyticsUserDashboardData();
    this.setState({ userData });
    const analytics = await getAnalytics({ dateStart, dateEnd },
      'hits_by_day,hits_totals');

    this.setState({ analytics });
  }

  render() {
    const { analytics, userData } = this.state;
    const { history } = this.props;

    function getDescriptionText() {
      const totals = userData.accountTotals;
      return (
        <p className="information-bar">
          {'You have '}
          <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(totals.linksCount, '.')} links`}</span>
          {', '}
          <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(totals.campaignsCount, '.')} campaigns`}</span>
          {' and '}
          <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(totals.linkboardsCount, '.')} linkboards`}</span>
          {' with '}
          <br />
          <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(totals.clicks, '.')} clicks`}</span>
          {' in groups you are a member of.'}
        </p>
      );
    }

    if (userData === null) {
      return (<Spinner show />);
    }

    const columns = [
      {
        Header: 'Name',
        name: 'link',
        id: 'link_id',
        accessor: (row) => `${row.title} ${row.created} ${row.user} ${row.usergroup}`,
        filterable: false,
        sortable: false,
        Cell: (props) => {
          let date = new Date(props.original.object.created_at);
          date = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
          return (
            <div>
              <h6>{props.original.object.title}</h6>
              <p className="small">
                Created:&nbsp;
                <span>{date}</span>
                <br />
                Group:&nbsp;
                <span>{props.original.object.usergroup}</span>
              </p>
            </div>
          );
        },
      },
      {
        id: 'short_urls',
        Header: 'Shortlinks',
        accessor: 'shortUrls', // row => `${row.shortUrls}`,
        filterable: false,
        sortable: false,
        Cell: (props) => {
          switch (props.original.type) {
            case 'Campaign':
              return (
                <div>
                  {
                    props.original.object.shortUrls.map((shortUrl) => (
                      <Copy key={`copy-${shortUrl.url}`} value={shortUrl.url} maxWidth={150} />
                    ))
                  }
                </div>
              );
            default:
              // eslint-disable-next-line no-case-declarations
              const url = props.original.object.shortUrl;
              return (
                <div>
                  <Copy key={`copy-${url}`} value={url} maxWidth={150} />
                </div>
              );
          }
        },
      },
      {
        Header: 'Type',
        name: 'type',
        id: 'type',
        accessor: (row) => row[0],
        filterable: false,
        Cell: (props) => (
          <div className={style.listChartEntryBold}>
            <p>{props.original.type}</p>
          </div>
        ),
        sortable: false,
      },
      {
        Header: 'Clicks',
        name: 'clicks',
        id: 'clicks',
        accessor: (row) => row[1],
        filterable: false,
        Cell: (props) => (
          <div className={style.listChartEntryBold}>
            <p>{`${addDecimalDivider(props.original.object.hits, ',')} Clicks`}</p>
          </div>
        ),
        sortable: false,
        // headerClassName: 'sort',
        headerStyle: {
          textAlign: 'left',
          paddingRight: 10,
        },
        style: {
          textAlign: 'left',
        },
      },
      {
        Header: 'Analytics',
        id: 'Analytics',
        accessor: (row) => `${row.id}`,
        Cell: (props) => (
          <div className="center">
            <a href={`/${props.original.type.toLowerCase()}s/detail/${props.original.object.id}`}>
              <img alt="show analytics" src="/icons/chart-bar-system.svg" />
            </a>
          </div>
        ),
        filterable: false,
        sortable: false,
        width: 100,
        headerClassName: 'center',
        className: 'icons',
      },
    ];

    return <Redirect to={"/links"} />

    return (
      <section id={style.welcome}>
        <Content small center>
          <div className={style.image_container}>
            <img src="/welcome.png" alt="Welcome to Linkhawk Pro" />
          </div>
          <h1>
            Hi
            {' '}
            {getCurrentUserName()}
            .
          </h1>
          <h2>Welcome to your Linkhawk account.</h2>
          <p>
            {getDescriptionText()}
          </p>

        </Content>
        <Content>
          <main className={style.main}>
            <div className={style.box_wrapper}>
              <div className={style.box}>
                <img src="/shortlinks.png" alt="Shortlinks" />
                <h3>Shortlinks</h3>
                <p>
                  Optimize your link performance and create in a few steps branded and individual
                  shortlinks and much more.
                </p>
                <ButtonLink href="/links" label="Links" />
              </div>
              <div className={style.box}>
                <img src="/campaigns.png" alt="Campaigns" />
                <h3>Campaigns</h3>
                <p>
                  Group your links to Campaigns and keep track of all marketing channels, including
                  email, SMS, print, and social media.
                </p>
                <ButtonLink href="/campaigns" label="Campaigns" />
              </div>
            </div>

            <div>
              <h2>Your last 5 actions</h2>
              <ReactTable
                data={userData.lastActions}
                columns={columns}
                defaultPageSize={5}
                showPagination={false}
                minRows={0}
                resizable={false}
              />
            </div>
            <div>
              <h2>Statistics overview</h2>
              { analytics ? (
                <LineChart
                  data={[
                    {
                      label: 'Links',
                      data: analytics.linkClicks.hitsByDay.data,
                      color: 'rgba(255, 183, 144, 1)',
                    },
                    {
                      label: 'Campaigns',
                      data: analytics.campaignClicks.hitsByDay.data,
                      color: 'rgb(194,212,177, 1)',
                    },
                  ]}
                  totalHits={analytics.hitsTotals.data.total}
                  headline="Clicks by day"
                  keyColor="rgba(199, 149, 208, 1.0)"
                  containerStyle={{ marginTop: 20 }}
                />
              ) : (<span>loading...</span>)}
            </div>
            <Content small center>
              <Button
                onClick={() => {
                  history.push('/analytics');
                }}
                label="Go to Analytics"
              />
            </Content>
          </main>
        </Content>
      </section>
    );
  }
}

Welcome.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect('', actions)(Welcome);
