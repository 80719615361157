import React, { Component } from 'react';
import { connect } from 'react-unistore';
import ReactGA from 'react-ga';
import actions from '../../../../actions';
import Content from '../../../../components/Content';
import style from './style.module.scss';
import Tag from '../../../../components/Tag';
import Copy from '../../../../components/Copy';
import Button from '../../../../components/Button';

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { campaign } = this.props;

    return (
      <Content successPage>

        <div className={style['content-container']}>
          <h1>Well done!</h1>
          <h2>Campaign successfully created.</h2>

          <table className={`ReactTable ${style['table-general']}`}>
            <tr>
              <th>Title:</th>
              <td>{campaign.title}</td>
            </tr>
            <tr>
              <th>Target URL:</th>
              <td>
                <a
                  className={style.shorten}
                  href={campaign.url}
                  target="_blank"
                >
                  {campaign.url}
                </a>
              </td>
            </tr>
            <tr>
              <th>Group:</th>
              <td>{campaign.usergroup}</td>
            </tr>
          </table>

          <table className={style['table-shortlinks']}>
            <thead>
              <th>Shortlinks</th>
              <th>Tags</th>

            </thead>
            <tbody>

              {campaign.links.map((shortUrl) => (
                <tr>
                  <td className={style.shortlinks}><Copy value={shortUrl.url} /></td>
                  <td className={style.tags}>
                    <div className={style['scroll-container']}>{shortUrl.tags.map((tag) => <Tag label={tag.name} />)}</div>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>

          <Content button style={{ display: 'inline-block' }}>
            <Button
              label="Edit campaign"
              secondary
              onClick={() => {
                ReactGA.event({
                  category: 'Campaign',
                  action: 'Edit',
                  label: 'Open Editor (from Success Page)',
                });
                this.props.history.push(`/campaigns/edit/${campaign.id}`);
              }}
            />
            <Button
              label="Campaigns overview"
              onClick={() => {
                ReactGA.event({
                  category: 'Campaign',
                  action: 'Back to Overview',
                  label: 'Back to Campaign Overview',
                });
                this.props.history.push(`/campaigns?highlightId=${campaign.id}`);
              }}
            />

          </Content>
        </div>

        <div className={style['image-container']}>
          <img src="/campaign-success.png" alt="" />
        </div>

      </Content>
    );
  }
}

export default connect('campaign', actions)(Step2);
