import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import style from './style.module.scss';
import '../../../../style/index.module.scss';
import Button from '../../../../components/Button';
import linkboardActions from '../../../../actions/linkboardEditor';
import Content from '../../../../components/Content';
import Phone from '../../../../components/Phone';
import SelectBox from '../../../../components/Selectbox';
import LinkboardTemplateStandard from '../../../../components/Linkboard/Templates/Standard';
import LinkboardTemplateEcommerce from '../../../../components/Linkboard/Templates/E-commerce';

class TemplateSelection extends Component {
  render() {
    const {
      selectedType, updateLinkboardProperty, history, nextStep,
    } = this.props;
    return (
      <div>
        <h1 className="center">Choose your template</h1>

        <div className={style['template-grid']}>
          <SelectBox
            checked={selectedType === 'standard'}
            onClick={() => updateLinkboardProperty('type', 'standard')}
          >
            <Phone headline="Standard">
              <LinkboardTemplateStandard />
            </Phone>
          </SelectBox>

          <SelectBox
            checked={selectedType === 'ecommerce'}
            onClick={() => updateLinkboardProperty('type', 'ecommerce')}
          >
            <Phone headline="E-commerce">
              <LinkboardTemplateEcommerce />
            </Phone>
          </SelectBox>
        </div>

        <Content button style={{ justifyContent: 'center' }}>
          <Button label="Cancel" secondary onClick={() => history.push('/linkboards')} />
          <Button label="Next" onClick={() => nextStep()} />
        </Content>
      </div>
    );
  }
}

TemplateSelection.propTypes = {
  selectedType: PropTypes.string.isRequired,
  updateLinkboardProperty: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

function mapStateToProps(state) {
  const { linkboardEditor } = state;
  return {
    selectedType: linkboardEditor.workingCopy.type || 'standard',
  };
}

export default connect(mapStateToProps, (store) => ({
  updateLinkboardProperty:
   async (...args) => linkboardActions.updateLinkboardProperty(store, ...args),
  nextStep: async (...args) => linkboardActions.nextStep(store, ...args),
}))(TemplateSelection);
