import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import ReactGA from 'react-ga';
import style from '../style.module.scss';
import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import { getCampaigns } from '../../../../api/data-management';
import actions from '../../../../actions';
import Content from '../../../../components/Content';
import { validateRequiredFields } from '../../../../util/validates';
import Spinner from '../../../../components/Spinner';
import { updateCampaign } from '../../../../api/campaigns';
import { convertToSelectValue } from '../../../../util/helperFunctions';
import ParagraphRequiredField from '../../../../components/ParagraphRequiredField';

class CampaignsEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: null,
      tagsLoading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleIndexTagsChange = this.handleIndexTagsChange.bind(this);
    this.handleCreateTag = this.handleCreateTag.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { id, usergroups } = this.props;
    const campaign = await getCampaigns(id);
    const usergroup = usergroups.filter((item) => item.name === campaign.usergroup)[0].id;

    this.setState({
      campaign: {
        ...campaign,
        usergroup,
      },
    });
  }

  handleChange(event) {
    const { campaign } = this.state;
    this.setState({
      campaign: {
        ...campaign,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleSelectChange(items, name) {
    const { campaign } = this.state;
    const value = Array.isArray(items) ? items.map((item) => item.value) : items.value;
    this.setState({
      campaign: {
        ...campaign,
        [name]: value,
      },
    });
  }

  handleIndexTagsChange(items, index) {
    const { links } = this.state.campaign;
    const updatedLinks = links.map((link) => (link.id === index
      ? {
        ...link,
        tags: items !== null ? items.map((item) => ({
          id: item.value,
          name: item.label,
        })) : [],
      }
      : link));
    this.setState({
      campaign: {
        ...this.state.campaign,
        links: updatedLinks,
      },
    });
  }

  async handleCreateTag(value, index) {
    this.setState({ tagsLoading: true });
    await this.props.createTag(JSON.stringify({ name: value }));
    // retrieve the tag that was newly added
    const latestTag = this.props.tags[this.props.tags.length - 1];
    const { links } = this.state.campaign;
    const updatedLinks = links.map((link) => (link.id === index
      ? {
        ...link,
        tags: [...link.tags, latestTag],
      }
      : link));
    this.setState({
      campaign: {
        ...this.state.campaign,
        links: updatedLinks,
      },
      tagsLoading: false,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {
      id,
      setCampaign,
      handleDisabledSteps,
      setStep,
      toggleSnackbar,
      toggleLoading,
      error: propsError,
    } = this.props;

    // TODO: do not access dom directly in react app!!!
    const requiredFields = Array.from(document.querySelectorAll('[data-required], [data-type=\'url\']'));
    const error = validateRequiredFields(requiredFields);

    if (!error) {
      const {
        campaign,
      } = this.state;
      const {
        title, url, usergroup, links,
      } = campaign;
      const payload = JSON.stringify({
        title,
        url,
        usergroup,
        shortUrls: links.map((link) => ({
          ...link,
          tags: link.tags.map((tag) => tag.id),
        })),
      });

      toggleLoading(true);
      try {
        ReactGA.event({
          category: 'Campaign',
          action: 'Edit',
          label: 'Save Changes',
        });
        await updateCampaign(id, payload);
        toggleLoading(false);
      } catch (err) {
        toggleLoading(false);
        toggleSnackbar(err.message);
        return;
      }

      if (propsError) {
        return;
      }

      setCampaign(this.state);
      handleDisabledSteps([1]);
      setStep(2);
    } else {
      toggleSnackbar('Please complete all required fields!');
    }
  }

  render() {
    const { history, usergroups, domains } = this.props;
    const {
      campaign,
    } = this.state;

    if (campaign === null) {
      return (<Spinner show />);
    }

    const { links, title, url } = campaign;

    return (
      <div>
        <h1 className="center">Edit campaign</h1>

        <Form>

          <Form.Input
            type="text"
            name="title"
            label="Titel*"
            width={25}
            placeholder="Title"
            data-required
            errorMessage="Title name must not be empty"
            value={title}
            onInput={this.handleChange}
          />

          <Form.SelectField
            name="usergroup"
            label="Usergroup"
            width={25}
            options={usergroups}
            value={campaign.usergroup}
            onChange={(item) => this.handleSelectChange(item, 'usergroup')}
          />

          <Form.Input
            name="url"
            label="Target URL*"
            data-required
            width={50}
            data-type="url"
            placeholder="Example"
            value={url}
            onInput={this.handleChange}
          />

          {links.map((link, index) => {
            const selectedDomain = domains.filter((item) => item.id === link.domain)[0].id;
            const selectedTags = link.tags.map(convertToSelectValue);

            return (
              <div id={`link_${index}`} key={link.id} className={style.formInputs}>

                <h3>
                  Link {index + 1}
                </h3>

                <Form.SelectField
                  name="domain"
                  label="Branded Domain"
                  width={25}
                  options={domains}
                  value={selectedDomain}
                  placeholder="Select"
                  isDisabled
                  index={index}
                  onChange={(item) => this.handleIndexSelectChange(item, 'domain', index)}
                />

                <Form.Input
                  type="text"
                  label="Slash-Tag*"
                  width={25}
                  placeholder="q5a4s6"
                  isDisabled
                  value={link.code}
                  onChange={(event) => this.handleIndexChange(event, 'code', index)}
                />

                <Form.CreateableMultiSelectField
                  name="tags"
                  label="Tags"
                  placeholder="Tag 1, Tag 2, Tag 3"
                  options={this.props.tags}
                  value={selectedTags}
                  width={50}
                  isLoading={this.state.tagsLoading}
                  isDisabled={this.state.tagsLoading}
                  onCreateOption={(value) => this.handleCreateTag(value, link.id)}
                  onChange={(items) => this.handleIndexTagsChange(items, link.id)}
                />

              </div>
            );
          })}

          <Content bar noPadding>
            <ParagraphRequiredField />
            <Content button>
              <Button label="Cancel" secondary onClick={() => history.push('/campaigns')} />
              <Button label="Save Changes" onClick={this.handleSubmit} />
            </Content>
          </Content>

        </Form>
      </div>
    );
  }
}

CampaignsEditForm.defaultProps = {
  error: null,
};

CampaignsEditForm.propTypes = {
  id: PropTypes.number.isRequired,
  usergroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCampaign: PropTypes.func.isRequired,
  handleDisabledSteps: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  toggleSnackbar: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
  toggleLoading: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default connect('domains,usergroups,tags', actions)(CampaignsEditForm);
