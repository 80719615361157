import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';
import chartStyle from '../../../components/Charts/style.module.scss';
import style from './style.module.scss';
import GeoLocationChart from '../../../components/Charts/GeoLocationChart';
import { getLinkboards, getLinkboardAnalytics } from '../../../api/linkboards';
import Spinner from '../../../components/Spinner';
import DoughnutChart from '../../../components/Charts/DoughnutChart';
import LineChart from '../../../components/Charts/LineChart';
import BarChart from '../../../components/Charts/BarChart';
import ListChart from '../../../components/Charts/ListChart';
import FilterBar from '../../../components/FilterBar';
import DateFilter from '../../../components/FilterBar/DateFilter';
import Content from '../../../components/Content';
import Copy from '../../../components/Copy';
import BackLink from '../../../components/BackLink';
import CSVDownloadButton from '../../../components/CSVDownloadButton';

export default class LinkboardsDetail extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    const { id } = match.params;
    this.state = {
      id,
      dateStart: moment().startOf('day').add(12, 'hours').subtract(30, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: moment().startOf('day').subtract(12, 'hours').format('YYYY-MM-DD'),
      selectedRange: '30d',
      title: '',
      linkboard: null,
      isFetchingData: true,
    };
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Linkboard Analytics',
    });
    const { id, dateStart, dateEnd } = this.state;

    const linkboard = await getLinkboards(id);
    const analytics = await getLinkboardAnalytics(id, { dateStart, dateEnd });

    this.setState({
      linkboard,
      analytics,
      title: linkboard.title,
      isFetchingData: false,
    });
  }

  async updateDate({ from, to }) {
    const { id } = this.state;

    const dateStart = moment(from).format('YYYY-MM-DD');
    const dateEnd = moment(to).format('YYYY-MM-DD');
    this.setState({ isFetchingData: true });
    const analytics = await getLinkboardAnalytics(
      id, { dateStart, dateEnd },
    );
    this.setState({
      analytics, dateStart, dateEnd, isFetchingData: false,
    });
  }

  renderLinkTable(title, firstColumnName, items) {
    const { containerStyle } = this.props;
    const columns = [
      {
        Header: firstColumnName,
        name: firstColumnName,
        id: firstColumnName,
        className: 'buttonTargetUrlCell',
        headerClassName: 'buttonTargetUrlCell',
        accessor: (row) => row[1],
        filterable: false,
        Cell: (props) => {
          const disabled = props.original[1].isActive === false;
          return (
            <div className={style.listChartEntryBold}>
              <p
                style={disabled ? { color: 'grey' } : {}}
              >
                {`${props.original[1].title}${disabled ? ' (Disabled)' : ''}`}
              </p>
            </div>
          );
        },
        headerStyle: {
          flex: 1,
          flexGrow: '0',
          flexShrink: '0',
          flexBasis: 'auto',
          minWidth: 300,
        },
        style: {
          flex: 1,
          flexGrow: '0',
          flexShrink: '0',
          flexBasis: 'auto',
          minWidth: 300,
        },
        sortable: false,
      },
      {
        Header: 'Target URL',
        name: 'targeturl',
        id: 'targeturl',
        accessor: (row) => row[1],
        filterable: false,
        Cell: (props) => {
          const disabled = props.original[1].isActive === false;
          return (
            <div className={style.listChartEntryBold}>
              <a
                style={disabled ? { opacity: 0.7 } : {}}
                href={props.original[1].targetUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {props.original[1].targetUrl}
              </a>
            </div>
          );
        },
        sortable: false,
        className: 'buttonTextCell',
        headerStyle: {
          textAlign: 'left',
          paddingRight: 10,
        },
        style: {
          textAlign: 'left',
        },
      },
      {
        Header: 'Interactions',
        name: 'interactions',
        id: 'interactions',
        accessor: (row) => row[1],
        filterable: false,
        Cell: (props) => {
          const disabled = props.original[1].isActive === false;
          return (
            <div className={style.listChartEntryBold}>
              <p style={disabled ? { color: 'grey' } : {}}>
                {`${props.original[1].hitsTotals.data.total} Interactions`}
              </p>
            </div>
          );
        },
        sortable: false,
        // headerClassName: 'sort',
        className: 'autoWidth',
        headerStyle: {
          textAlign: 'right',
          paddingRight: 10,
        },
        style: {
          flex: 1,
          flexGrow: '0 !important',
          flexShrink: '0 !important',
          flexBasis: 'auto !important',
          width: 'auto !important',
          textAlign: 'right',
        },
      },
    ];
    if (items === undefined || items.length === 0) {
      return (<div />);
    }

    return (
      <div className={style.linkTable} style={containerStyle}>
        <h2 className={style.chartHeadline}>{title}</h2>
        <div className={style.chart}>
          <ReactTable
            data={Object.entries(items)}
            columns={columns}
            defaultPageSize={7}
            showPagination={false}
            minRows={0}
            resizable={false}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      id, dateStart, dateEnd, linkboard, title, selectedRange, isFetchingData, analytics,
    } = this.state;

    function getDescriptionText() {
      return `Created: ${moment(linkboard.created_at).format('DD.MM.Y')} · User: ${linkboard.user} · Group: ${linkboard.usergroup}`;
    }

    return (
      <Content>
        <BackLink target="/linkboards" title="Back to Linkboards" />

        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, paddingTop: '28px' }}>
            <h1 style={{
              display: 'inline-block',
              fontSize: '38px',
            }}
            >
              {title}
            </h1>
            {linkboard ? (
              <p className="information-bar">
                {getDescriptionText()}
              </p>
            )
              : null}
          </div>
          <div>
            <FilterBar>
              <DateFilter
                selectedRange={selectedRange}
                dateStart={dateStart}
                dateEnd={dateEnd}
                callback={(dates) => this.updateDate(dates)}
              />
            </FilterBar>
          </div>
        </div>

        {!isFetchingData
        && (
          (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 9,
            }}
            >
              <p style={{ marginTop: 0, marginBottom: 0 }}>
                <Copy value={linkboard.shortUrl} />
              </p>
              <CSVDownloadButton
                type="linkboards"
                id={id}
                dateStart={dateStart}
                dateEnd={dateEnd}
              />
            </div>

          )
        )}

        {
          analytics
            ? (
              <div>
                <LineChart
                  data={[
                    {
                      label: 'Clicks',
                      data: analytics.clicks.hitsByDay.data,
                      color: 'rgba(199, 149, 208, 1.0)',
                    },
                    {
                      label: 'Interactions',
                      data: analytics.interactions.hitsByDay.data,
                      color: 'rgba(234 ,206 ,239, 1.0)',
                    },
                  ]}
                  totalHits={analytics.clicks.hitsTotals.data.total}
                  totalInteractions={analytics.interactions.hitsTotals.data.total}
                  headline="Clicks by day"
                  keyColor="rgba(199, 149, 208, 1.0)"
                  containerStyle={{ marginTop: 20 }}
                />
                <BarChart
                  data={[
                    {
                      label: 'Clicks',
                      data: analytics.clicks.hitsByHourOfDay.data,
                      color: 'rgba(199, 149, 208, 1.0)',
                    },
                    {
                      label: 'Interactions',
                      data: analytics.interactions.hitsByHourOfDay.data,
                      color: 'rgba(234 ,206 ,239, 1.0)',
                    },
                  ]}
                  headline="Clicks by time"
                  keyColor="rgba(199, 149, 208, 1.0)"
                  containerStyle={{ marginTop: 30 }}
                />
                <div className={chartStyle.chartGroup} style={{ marginBottom: 30 }}>
                  <DoughnutChart
                    data={analytics.clicks.hitsTotals.data}
                    headline="Devices"
                    colorDesktop="#C795D0"
                    colorMobile="#EACEEF"
                    colorTablet="#F9E2FD"
                    containerStyle={{ marginTop: 30 }}
                  />
                  <ListChart
                    data={analytics.clicks.hitsByReferrer.data}
                    headline="Top 5 visitor sources by clicks"
                    containerStyle={{ marginTop: 30 }}
                  />
                </div>
                <GeoLocationChart
                  fetchAnalytics={getLinkboardAnalytics}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  id={id}
                  headline="Top 5 locations by clicks"
                  keyColors={['#F9E2FD', '#EACEEF', '#C795D0']}
                  containerStyle={{ marginTop: 30 }}
                />
                {this.renderLinkTable('Products', 'Product Title', analytics.products)}
                {this.renderLinkTable('Button & Link', 'Button Text', analytics.buttons)}
                {this.renderLinkTable('Social Links', 'Social Network', analytics.socialLinks)}
              </div>
            )
            : null
        }
        <Spinner show={isFetchingData} />
      </Content>
    );
  }
}

LinkboardsDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  containerStyle: PropTypes.any.isRequired,
};
