import moment from 'moment-timezone';
import {
  get,
} from '../api/common';

// eslint-disable-next-line import/prefer-default-export
export const getLinkboards = async (store, state, page, limit, query = null) => {
  store.setState({ loading: true });

  const searchQuery = query !== null ? `&search=${query}` : '';

  const res = await get(`/api/linkboards?page=${page}&limit=${limit}${searchQuery}&timezone=${moment.tz.guess()}`, null, false);
  const total = res.headers.get('X-Total-Count');
  const items = await res.json();

  return {
    linkboards: {
      ...state.linkboards,
      items,
      currentPage: page,
      pages: Math.ceil(total / limit),
      fetched: true,
    },
    loading: false,
  };
};
