import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { downloadCsv } from '../../api/data-management';
import style from './style.module.scss';

class CSVDownloadButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idDownloadingCSV: false,
    };
  }

  render() {
    const {
      dateEnd, dateStart, id, type,
    } = this.props;
    const { idDownloadingCSV } = this.state;

    return (
      <button
        type="button"
        disabled={idDownloadingCSV}
        className={style.csv}
        onClick={async (e) => {
          e.preventDefault();
          this.setState({ idDownloadingCSV: true });
          try {
            const name = type.charAt(0).toUpperCase() + type.slice(1, -1);

            ReactGA.event({
              category: name,
              action: 'Download',
              label: `CSV-Export ${name}`,
            });
            await downloadCsv(type, id, { dateStart, dateEnd });
          } catch (err) {
            // eslint-disable-next-line no-alert
            alert('Something went wrong.');
          }
          this.setState({ idDownloadingCSV: false });
        }}
      >
        <span>Download CSV</span>
        <img src="/icons/download.svg" alt="" />
      </button>
    );
  }
}

CSVDownloadButton.propTypes = {
  id: PropTypes.number.isRequired,
  dateStart: PropTypes.string.isRequired,
  dateEnd: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['links', 'campaings', 'linkboards']).isRequired,
};

export default CSVDownloadButton;
