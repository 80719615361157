import React, { Component } from 'react';
import { connect } from 'react-unistore';
import { StripeProvider } from 'react-stripe-elements';
import ReactGA from 'react-ga';
import style from './style.module.scss';
import buttonStyle from '../../../../components/Form/multiStepForm.scss';
import Button from '../../../../components/Button';
import PayPalPayment from '../../../../components/PayPalPayment/PayPalPayment';
import actions from '../../../../actions';
import {
  getAccount, getNextBillingDate, isSubscribed, getLatestPayment, isCanceled,
} from '../../../../util/user-management';
import { SUBSCRIPTION_PRICE, PAYPAL, STRIPE } from '../../../../util/constants';
import { paymentMethodIcon, formatToCurrency } from '../../../../util/helperFunctions';
import Content from '../../../../components/Content';
import LinkRouter from '../../../../components/Link';

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
    };

    this.handlePayPalSubmit = this.handlePayPalSubmit.bind(this);
    this.handleStripeSubmit = this.handleStripeSubmit.bind(this);
  }

  async componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) });
    } else {
      document.querySelector('#stripe-js')
        .addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) });
        });
    }

    const account = getAccount();

    console.log(account);
  }

  trackSubscription(provider) {
    const payment = getLatestPayment();

    if (!isSubscribed()) {
      ReactGA.event({
        category: 'Subscription',
        action: 'Resubscribe',
        label: 'Success',
      });
    } else {
      ReactGA.event({
        category: 'Payment',
        action: 'Change',
        label: `${payment ? payment.provider : 'unknown'} to ${provider}`,
      });
    }
  }

  async handlePayPalSubmit(data, action) {
    this.trackSubscription(PAYPAL);
    const payload = {
      periodStart: isSubscribed() ? getNextBillingDate() : null,
      subscriptionID: data.subscriptionID,
    };
    await this.props.subscribeViaPayPal(JSON.stringify(payload));
    this.props.history.push('/payment');
  }

  async handleStripeSubmit(payment) {
    const { paymentMethod } = this.props;
    this.trackSubscription(STRIPE);
    const payload = {
      periodStart: getNextBillingDate(),
      subscriptionID: payment.provider === STRIPE ? payment.stripeSubscriptionId : payment.payPalSubscriptionId,
      paymentMethod: paymentMethod && paymentMethod.id,
    };
    await this.props.subscribeViaStripe(JSON.stringify(payload), this.state.stripe);
    this.props.history.push('/payment');
  }

  render() {
    const account = getAccount();
    const payment = getLatestPayment();
    const totalPrice = formatToCurrency(SUBSCRIPTION_PRICE * account.quantity);

    return (
      <StripeProvider stripe={this.state.stripe}>
        <div>
          <div>
            <h1>Your overview</h1>

            <div className={style.box_wrapper}>
              <div className={style.box}>
                <h2>Your information</h2>

                <div className={style.table_wrapper}>
                  <table>
                    <tr>
                      <th>First name:</th>
                      <td>{account.firstname}</td>
                    </tr>
                    <tr>
                      <th>Last name:</th>
                      <td>{account.lastname}</td>
                    </tr>
                    <tr>
                      <th>Address:</th>
                      <td>
                        <span className="no-wrap">
                          {account.streetName}
                          {' '}
                          {account.streetNumber}
                        </span>
                        <br />
                        {account.postalCode}
                        {' '}
                        {account.city}
                        <br />
                        {account.country}
                      </td>
                    </tr>
                  </table>

                  {account.name && (
                    <table>

                      {account.name
                      && (

                        <tr>
                          <th>Company:</th>
                          <td>{account.name}</td>
                        </tr>

                      )}
                      {account.vat
                      && (
                        <tr>
                          <th>VAT:</th>
                          <td>{account.vat}</td>
                        </tr>
                      )}

                    </table>
                  )}

                </div>

              </div>

              <div className={style.box}>
                <h2>Method of payment</h2>

                {
                  this.props.payPal
                    ? (
                      <div>
                        <p>
                          You have chosen
                          <b>&nbsp;PayPal&nbsp;</b>
                          as your payment method.
                        </p>
                        <img
                          className="card"
                          src={`../../../${paymentMethodIcon('paypal')}`}
                          alt="PayPal"
                        />
                      </div>
                    ) : (
                      <div>
                        <p>
                          You have chosen
                          <b>&nbsp;Credit Card&nbsp;</b>
                          as your payment method.
                        </p>
                        <p style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        >
                          <img
                            className="card"
                            style={{ marginRight: '20px' }}
                            src={`../../../${paymentMethodIcon(this.props.paymentMethod.card.brand)}`}
                            alt="Card icon"
                          />
                          Credit Card Number: **** **** ****
                          {' '}
                          {this.props.paymentMethod.card.last4}
                        </p>
                      </div>
                    )
                }
              </div>
            </div>

            <div className={style.pricebar}>
              <div>
                <h4>
                  Total price:
                  <b>
                    {`${totalPrice} €`}
                  </b>
                  <span
                    className={style.font_light}
                  >
                    {' '}
                    incl. VAT
                  </span>
                </h4>
              </div>
            </div>
          </div>

          <Content bar>
            <LinkRouter back onClick={this.props.prevStep} />
            {
              this.props.payPal === true
                ? (
                  <div style={{
                    height: '50px',
                    width: '250px',
                  }}
                  >
                    <PayPalPayment
                      quantity={account.quantity}
                      update={payment.provider === PAYPAL && !isCanceled()}
                      subscriptionId={payment.provider === PAYPAL && payment.payPalSubscriptionId}
                      startTime={isSubscribed() ? getNextBillingDate() : null}
                      onSubmit={this.handlePayPalSubmit}
                    />
                  </div>
                ) : (
                  <Button
                    label="Order at cost"
                    width={180}
                    type="submit"
                    className={buttonStyle.submitButton}
                    submitButtonOnClick={() => this.handleStripeSubmit(payment)}
                  />
                )
            }
          </Content>

        </div>
      </StripeProvider>
    );
  }
}

export default connect('user', actions)(Step2);
