import React, { Component } from 'react';
import ReactGA from 'react-ga';
import style from './style.module.scss';
import Content from '../../components/Content';
import { getAccount } from '../../util/user-management';

// eslint-disable-next-line react/prefer-stateless-function
class closedAccount extends Component {
  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Account Closed',
    });
  }

  render() {
    const account = getAccount();
    console.log(account);
    return (
      <section id={style.accountInactive}>
        <Content small center>
          <div className={style.image_container}>
            <img src="/closed_account.png" alt="Your account is closed" />
          </div>
          <p>
            We really appreciate all the time you’ve linked with us! We’ve sent you an email confirming your account closure and offering an export of all your data.
          </p>
          <p>If there’s anything we could be doing better, please don’t hesitate to let us know.</p>
          <p>Until we meet again, friend.</p>
          <p>
            The Linkhawk Team
            <br />
            Lissi, Janine, Bibi, Max, Julia, Anna, Alex, Tobi, Felice, Brianna, Linda, Melanie and Markus.
          </p>
        </Content>
      </section>
    );
  }
}

export default closedAccount;
