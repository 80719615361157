import React, { Component, h } from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';

import style from './style.module.scss';
import Button from '../Button';
import Content from '../Content';

const createOptions = () => ({
  style: {
    base: {
      color: '#000',
      fontFamily: "'Roboto', sans-serif",
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      fontWeight: '200',
      lineHeight: '46px',
      backgroundColor: '#fff',
      '::placeholder': {
        fontFamily: "'Roboto', sans-serif",
        color: '#B5B5B5',
      },
      ':-webkit-autofill': {
        backgroundColor: '#000',
      },
    },
    invalid: {
      color: '#FF6E00',
      iconColor: '#FF6E00',
    },
  },
});

class CreditCardForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cancel() {
    const { history } = this.props;
    history.push('/payment');
  }

  async handleSubmit(event) {
    let inputError = false;
    const inputs = Array.from(document.getElementById('stripeForm').getElementsByTagName('input'));

    inputs.map((input) => {
      if (input.required && input.value === '') {
        input.classList.add('error');
        inputError = true;
      } else {
        input.classList.remove('error');
      }
    });

    event.preventDefault();

    const cardElement = this.props.elements.getElement('card');
    const { paymentMethod, error } = await this.props.stripe.createPaymentMethod('card', cardElement);

    if (error || typeof paymentMethod === 'undefined' || inputError) {
      this.props.updateData({
        disableStep4: false,
      });
    } else {
      this.props.submit({ paymentMethod, message: '' });
    }
  }

  render() {
    return (
      <div id="creditCardBox" className="active">
        <form id="stripeForm">
          <div className={style.flex_wrap}>
            <div className={style.creditCardWrapper}>
              <label htmlFor="cardNumber">Credit card number</label>
              <CardElement hidePostalCode for="cardNumber" {...createOptions()} />
            </div>
          </div>

          <Content button>
            <Button label="Cancel" secondary onClick={() => this.cancel(this)} />
            <Button label={this.props.mainButtonText} type="submit" onClick={this.handleSubmit} />
          </Content>
        </form>
      </div>
    );
  }
}

export default injectStripe(CreditCardForm);
