import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import style from './style.module.scss';

const MultiSelectField = (props) => {
  const renamedOptions = props.options.map((el) => ({
    value: el.id, label: el.name,
  }));

  const values = props.value.map(val => renamedOptions.filter(opt => opt.value === val)[0]);

  const label = props.label || 'Label';
  const className = `${style.input_wrapper} width_${props.width} ${props.error ? style.error : ''}`;
  const placeholder = props.placeholder || 'Select';

  return (
    <div className={`selectboxWrapper ${className} ${props['data-required'] ? 'required' : ''}`} data-type="multi-select" data-required={props['data-required']}>
      <label htmlFor={props.name}>{label}</label>
      <Select
        isMulti
        name={props.name}
        value={values}
        options={renamedOptions}
        placeholder={placeholder}
        classNamePrefix="selectbox"
        onChange={props.onChange}
        isDisabled={props.isDisabled}
      />
      {props.error && <div className={style.error_message}>{props.errorMessage}</div>}
    </div>
  );
};

MultiSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default MultiSelectField;
