import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';

const SelectBox = ({
  id, name, checked, onClick, children,
}) => (
  <label className={style.selectbox_wrapper}>
    <input
      type="radio"
      id={id}
      name={name}
      checked={checked}
      onClick={onClick}
      readOnly={checked}
      // TODO: fix root-cause; dummy on change to shut up react warning
      onChange={() => {}}
    />
    <div className={style.checkbox} />
    {children}
  </label>
);

SelectBox.defaultProps = {
  checked: false,
  id: null,
  name: '',
  onClick: null,
  children: null,
};

SelectBox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default SelectBox;
