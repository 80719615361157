/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as user from '../../../util/user-management';
import AdminRoute from '../AdminRoute';

const SubscribedAdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (user.isLoggedIn()) {
        if (user.isAdmin() && user.isSubscribed()) {
          return (
            <div>
              <AdminRoute {...props} component={Component} />
            </div>
          );
        }
        return <Redirect to={{ pathname: '/payment/choose-payment', state: { from: props.location } }} />;
      }

      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }}
  />
);

export default SubscribedAdminRoute;
