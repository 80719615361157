/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-unistore';
import actions from '../../../actions';
import * as user from '../../../util/user-management';
import UserRoute from '../UserRoute';
import Spinner from '../../Spinner';

class SubscribedUserRoute extends React.Component {
  componentDidMount() {
    this.prefetchData();
  }

  async prefetchData() {
    const {
      refreshUserData,
      prefetchedData,
      prefetchingData,
    } = this.props;

    if (user.isLoggedIn() && !prefetchingData && !prefetchedData) {
      await refreshUserData();
    }
  }

  render() {
    const { component: Component, prefetchedData, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (user.isLoggedIn()) {
            if (user.isSubscribed()) {
              // Components in this route expect this data to be available
              if (!prefetchedData) {
                return (<Spinner show />);
              }
              return (
                <div>
                  <UserRoute {...props} component={Component} />
                </div>
              );
            }
            if (!user.isSubscribed() && user.isAdmin()) {
              return <Redirect to={{ pathname: '/payment/choose-payment', state: { from: props.location } }} />;
            }

            return <Redirect to={{ pathname: '/account-inactive', state: { from: props.location } }} />;
          }

          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }}
      />
    );
  }
}

SubscribedUserRoute.propTypes = {
  refreshUserData: PropTypes.func.isRequired,
  prefetchedData: PropTypes.bool.isRequired,
  prefetchingData: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
};

export default connect(
  'prefetchedData,prefetchingData',
  actions,
)(SubscribedUserRoute);
