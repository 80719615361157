import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import {
  propertyHasValidationErrors,
  propertiesHaveValidationErrors,
} from '../../../../../actions/linkboardEditor';
import AccordionItem from '../../../../Accordion/Item';
import Form from '../../../../Form';

class ProductItem extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { updateLinkboardItemProperty, item } = this.props;
    updateLinkboardItemProperty('products', item.id, event.target.name, event.target.value);
  }

  render() {
    const {
      item: product,
      expanded,
      toggleDisplayState,
      positionInList,
      validationData,
    } = this.props;

    const {
      id,
      text,
      price,
      targetUrl,
      imageFile,
    } = product;

    return (
      <AccordionItem
        type="checkbox"
        box
        checked={expanded}
        onClick={() => toggleDisplayState()}
        headline={`${positionInList}. Product`}
        id={`product-${id}`}
        error={propertiesHaveValidationErrors(
          ['text', 'price', 'targetUrl', 'imageFile'],
          validationData,
        )}
      >

        <div>

          <Form>
            <Form.FileUploader
              isRequired
              label="Product image*"
              id={`product${id}`}
              onChange={(file) => this.onChange({ target: { name: 'imageFile', value: file } })}
              handleImageRemove={() => this.onChange({ target: { name: 'imageFile', value: null } })}
              width={33}
              maxNumberOfFiles={1}
              file={imageFile.url}
              imageName={imageFile.name}
              error={propertyHasValidationErrors('imageFile', validationData)}
              errorMessage={propertyHasValidationErrors('imageFile', validationData) ? validationData.imageFile.message : null}
            />

            <div className={style['form-flex']}>
              <Form.Input
                type="text"
                label="Product description"
                width={100}
                placeholder="Example"
                name="text"
                value={text}
                onInput={this.onChange}
                error={propertyHasValidationErrors('text', validationData)}
                errorMessage={propertyHasValidationErrors('text', validationData) ? validationData.text.message : null}
              />

              <Form.Input
                type="text"
                label="Price"
                width={25}
                className="no-margin"
                placeholder="49,99"
                name="price"
                value={price}
                onInput={this.onChange}
                error={propertyHasValidationErrors('price', validationData)}
                errorMessage={propertyHasValidationErrors('price', validationData) ? validationData.price.message : null}
              />

              <Form.Input
                data-required
                label="Product target URL*"
                type="text"
                width={75}
                className="no-margin"
                placeholder="https://example.com"
                name="targetUrl"
                data-type="url"
                value={targetUrl}
                onInput={this.onChange}
                error={propertyHasValidationErrors('targetUrl', validationData)}
                errorMessage={propertyHasValidationErrors('targetUrl', validationData) ? validationData.targetUrl.message : null}
              />
            </div>

          </Form>
        </div>
      </AccordionItem>
    );
  }
}

ProductItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    targetUrl: PropTypes.string,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  positionInList: PropTypes.number.isRequired,
  toggleDisplayState: PropTypes.func.isRequired,
  updateLinkboardItemProperty: PropTypes.func.isRequired,
  validationData: PropTypes.shape({}).isRequired,
};

export default ProductItem;
