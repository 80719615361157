import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../../../../Accordion/Item';
import Form from '../../../../Form';
import {
  propertyHasValidationErrors,
  propertiesHaveValidationErrors,
} from '../../../../../actions/linkboardEditor';
import { socialIconOptions } from '../../../../../util/socialIconLibrary';

class SocialItems extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { updateLinkboardItemProperty, item } = this.props;
    updateLinkboardItemProperty('socials', item.id, event.target.name, event.target.value);
  }

  render() {
    const {
      item: social,
      expanded,
      toggleDisplayState,
      positionInList,
      validationData,
    } = this.props;

    const {
      id,
      color: selectedColor,
      backgroundColor: selectedBackgroundColor,
      targetUrl,
      iconType,
    } = social;

    return (

      <AccordionItem
        type="checkbox"
        box
        checked={expanded}
        onClick={() => toggleDisplayState()}
        headline={`${positionInList}. Link`}
        id={`social-${id + 1}`}
        error={propertiesHaveValidationErrors(
          ['color', 'backgroundColor', 'targetUrl', 'iconType'],
          validationData,
        )}
      >

        <div key={social.id}>
          <Form>
            <Form.SelectField
              label="Social network"
              name="iconType"
              width={33}
              options={socialIconOptions}
              value={iconType}
              onChange={(option) => this.onChange({ target: { name: 'iconType', value: option.value } })}
              placeholder="Choose"
              error={propertyHasValidationErrors('iconType', validationData)}
              errorMessage={propertyHasValidationErrors('iconType', validationData) ? validationData.iconType.message : null}
            />

            <Form.ColorPicker
              label="Icon color"
              placeholder="Choose icon color"
              color={selectedColor}
              colorPickerChange={(color) => this.onChange({ target: { name: 'color', value: color.hex } })}
              inputChange={this.onChange}
              width={33}
              name="color"
              error={propertyHasValidationErrors('color', validationData)}
              errorMessage={propertyHasValidationErrors('color', validationData) ? validationData.color.message : null}
            />

            <Form.ColorPicker
              label="Background color"
              placeholder="Choose background color"
              color={selectedBackgroundColor}
              colorPickerChange={(color) => this.onChange({ target: { name: 'backgroundColor', value: color.hex } })}
              inputChange={this.onChange}
              width={33}
              name="backgroundColor"
              error={propertyHasValidationErrors('backgroundColor', validationData)}
              errorMessage={propertyHasValidationErrors('backgroundColor', validationData) ? validationData.backgroundColor.message : null}
            />

            <Form.Input
              data-required
              label="Link to profile*"
              type="text"
              width={100}
              placeholder="https://example.com"
              name="targetUrl"
              data-type="url"
              value={targetUrl}
              onInput={this.onChange}
              error={propertyHasValidationErrors('targetUrl', validationData)}
              errorMessage={propertyHasValidationErrors('targetUrl', validationData) ? validationData.targetUrl.message : null}
            />
          </Form>
        </div>
      </AccordionItem>

    );
  }
}

SocialItems.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    targetUrl: PropTypes.string,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  positionInList: PropTypes.number.isRequired,
  toggleDisplayState: PropTypes.func.isRequired,
  updateLinkboardItemProperty: PropTypes.func.isRequired,
  validationData: PropTypes.shape({
    targetUrl: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    iconType: PropTypes.string,
  }).isRequired,
};

export default SocialItems;
