import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactTable from 'react-table';
import { connect } from 'react-unistore';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import { addDecimalDivider } from '../../components/Charts/Tooltip';
import actions from '../../actions';
import Content from '../../components/Content';
import { clearErrors } from '../../util/validates';
import Copy from '../../components/Copy';
import Search from '../../components/Form/components/Search';
import style from './style.module.scss';

class Links extends Component {
  constructor(props) {
    super(props);
    this.state = { queryString: null };
    this.setActiveClass = this.setActiveClass.bind(this);
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Links',
    });
    const { getLinks, links } = this.props;
    clearErrors();
    await getLinks(0, links.pageSize);
  }

  setActiveClass(state, rowInfo) {
    const { loading, location } = this.props;
    const { search } = location;
    const query = new URLSearchParams(search);
    const highlightId = query.get('highlightId');
    if (rowInfo.original.id === parseInt(highlightId, 10) && !loading) {
      return {
        className: 'animated',
      };
    }
    return {};
  }

  async updateSearchQuery(query) {
    const { getLinks, links } = this.props;
    const { queryString } = this.state;

    if (queryString !== query) {
      this.setState({ queryString: query });
      await getLinks(0, links.pageSize, query);
    }
  }

  render() {
    function expand(id) {
      const clickModule = document.querySelector(`.click.link${id}`);
      const expandModule = document.querySelector(`.expand.link${id}`);
      expandModule.classList.toggle('active');
      clickModule.classList.toggle('active');
    }

    const {
      history, getLinks, links,
    } = this.props;

    const { queryString, sorted } = this.state;

    const {
      items,
      currentPage,
      pages,
      pageSize,
      fetched,
    } = links;

    const columns = [
      {
        Header: () => {
          const isSorted = sorted?.find(sort => sort.id === 'title');
          return (
              <div className={style.sortable_header}>
                Title
                {isSorted && (
                    <img
                        alt="sort"
                        src={isSorted.desc ? '/icons/arrow-up-black.svg' : '/icons/arrow-down.svg'}
                        className={style.sort_icon}
                    />
                )}
              </div>
          );
        },
        name: 'link',
        id: 'title',
        accessor: 'title',
        filterable: false,
        Cell: (props) => {
          let date = new Date(props.original.created_at);
          date = date.toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          return (
            <div>
              <h6>{props.original.title}</h6>
              <p className="small">
                Created:&nbsp;
                <span>{date}</span>
                {' '}How
                <br/>
                User:&nbsp;
                <span>{props.original.user}</span>
                <br/>
                Group:&nbsp;
                <span>{props.original.usergroup}</span>
              </p>
            </div>
          );
        },
        sortable: true,
      }, {
        Header: 'Shortlinks',
        accessor: 'shortUrl',
        filterable: false,
        sortable: false,
        // minWidth: 200,
        Cell: (props) => (
          <div>
            <div className="cellPulldown left">
              <Copy value={props.original.shortUrl}/>
            </div>
          </div>
        ),
      }, {
        Header: () => {
          const isSorted = sorted?.find(sort => sort.id === 'views');
          return (
              <div className={style.sortable_header}>
                Clicks
                {isSorted && (
                    <img
                        alt="sort"
                        src={isSorted.desc ? '/icons/arrow-up-black.svg' : '/icons/arrow-down.svg'}
                        className={style.sort_icon}
                    />
                )}
              </div>
          );
        },
        accessor: 'views',
        filterable: false,
        sortable: true,
        width: 100,
        className: 'icons',
        Cell: (props) => (
          <div className="cellPulldown center">
            <p
              className="big"
            >
              {addDecimalDivider(props.value, ',')}
            </p>
          </div>
        ),
      }, {
        Header: 'Target URL',
        accessor: 'targetUrl',
        id: 'target_url',
        filterable: false,
        sortable: false,
        headerClassName: 'margin_left',
        className: 'margin_left',
        Cell: (props) => {
          let trimmedString = props.original.targetUrl;
          if (trimmedString.length > 30) {
            trimmedString = `${trimmedString.substring(0, 30)}...`;
          }
          return (
            <div className="cellPulldown">
              <a
                title={props.original.targetUrl}
                target="_blank"
                className="long-value"
                rel="noopener noreferrer"
                href={props.original.targetUrl}
              >
                {trimmedString}
              </a>
            </div>
          );
        },
      }, {
        Header: 'Edit',
        id: 'Edit',
        headerClassName: 'center',
        className: 'icons',
        accessor: (row) => `${row.id}`,
        Cell: (props) => (
          <div className="cellPulldown center">
            <Link
              to={`/links/edit/${props.original.id}`}
              onClick={() => {
                ReactGA.event({
                  category: 'Link',
                  action: 'Edit',
                  label: 'Open Editor',
                });
              }}
            >
              <img alt="edit link" src="/icons/edit.svg"/>
            </Link>
          </div>
        ),
        filterable: false,
        sortable: false,
        width: 100,

      }, {
        Header: 'Analytics',
        id: 'Analytics',
        accessor: (row) => `${row.id}`,
        Cell: (props) => (
          <div className="cellPulldown center">
            <a
              href={`/links/detail/${props.original.id}`}
              onClick={() => {
                ReactGA.event({
                  category: 'Link',
                  action: 'View',
                  label: 'Analytics',
                });
              }}
            >
              <img alt="show analytics" src="/icons/chart-bar-system.svg"/>
            </a>
          </div>
        ),
        filterable: false,
        sortable: false,
        width: 100,
        headerClassName: 'center',
        className: 'icons',
      },
    ];

    return (
      <Content>
        {(fetched && !items.length && queryString === null)
        && (
          <Content small center>
            <img
              src="/shortlinks_create.png"
              alt="create a shortlink"
              style={{ width: '300px' }}
            />
            <h1>Create a shortlink</h1>
            <p>
              Keep your audience engaged by linking your latest news, promoting a line of products,
              or announcing an event.
            </p>
            <Button
              label="Create link"
              onClick={() => {
                ReactGA.event({
                  category: 'Link',
                  action: 'Create',
                  label: 'Open Editor',
                });
                history.push('/links/create');
              }}
              width={200}
            />
          </Content>
        )}

        {(fetched && (items.length > 0 || queryString !== null))
        && (
          <div>

            <Content header>
              <h1>Links</h1>
              <Content button>
                <Button
                  label="Create new link"
                  onClick={() => {
                    ReactGA.event({
                      category: 'Link',
                      action: 'Create',
                      label: 'Open Editor',
                    });
                    history.push('/links/create');
                  }}
                />
                <Search
                  placeholder="Search"
                  autocomplete={false}
                  callback={this.updateSearchQuery}
                />
              </Content>
            </Content>

            <ReactTable
              data={items}
              page={currentPage}
              pages={pages}
              defaultPageSize={pageSize}
              manual={true}
              onFetchData={async (state) => {
                const { page, pageSize, sorted } = state;
                await getLinks(page, pageSize, queryString, sorted);
              }}
              columns={columns}
              minRows={0}
              resizable={false}
              getTrProps={this.setActiveClass}
              PaginationComponent={Pagination}
              sortable={true}
              onSortedChange={(newSorted) => {
                this.setState({ sorted: newSorted });
              }}
            />
          </div>
        )}

      </Content>
    );
  }
}

Links.propTypes = {
  links: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    currentPage: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    fetched: PropTypes.bool.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  getLinks: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
};

export default connect('links,loading', actions)(Links);
