import React, { Component } from 'react';
import ReactGA from 'react-ga';
import 'react-table/react-table.css';
import moment from 'moment';
import chartStyle from '../../components/Charts/style.module.scss';
import { addDecimalDivider } from '../../components/Charts/Tooltip';
import { getAnalytics } from '../../api/analytics';
import Spinner from '../../components/Spinner';
import DoughnutChart from '../../components/Charts/DoughnutChart';
import LineChart from '../../components/Charts/LineChart';
import BarChart from '../../components/Charts/BarChart';
import ListChart from '../../components/Charts/ListChart';
import FilterBar from '../../components/FilterBar';
import DateFilter from '../../components/FilterBar/DateFilter';
import Content from '../../components/Content';

export default class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateStart: moment().startOf('day').add(12, 'hours').subtract(1, 'week')
        .format('YYYY-MM-DD'),
      dateEnd: moment().startOf('day').subtract(12, 'hours').format('YYYY-MM-DD'),
      selectedRange: '30d',
      referrerAnalytics: null,
      analytics: null,
      isFetchingData: true,
    };
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Analytics',
    });
    const { dateStart, dateEnd } = this.state;

    const analytics = await getAnalytics({ dateStart, dateEnd },
      'hits_by_day,hits_totals,hits_by_hour_of_day');

    this.setState({
      analytics,
      isFetchingData: false,
    });

    const referrerAnalytics = await getAnalytics({ dateStart, dateEnd },
      'hits_by_referrer');

    this.setState({
      referrerAnalytics,
    });
  }

  async updateDate({ from, to }) {
    const dateStart = moment(from).format('YYYY-MM-DD');
    const dateEnd = moment(to).format('YYYY-MM-DD');
    this.setState({ isFetchingData: true, dateStart, dateEnd });
    const analytics = await getAnalytics({ dateStart, dateEnd },
      'hits_by_day,hits_totals,hits_by_hour_of_day');
    this.setState({
      analytics, dateStart, dateEnd, isFetchingData: false, referrerAnalytics: null,
    });

    const referrerAnalytics = await getAnalytics({ dateStart, dateEnd },
      'hits_by_referrer');

    this.setState({
      referrerAnalytics,
    });
  }

  render() {
    const {
      dateStart, dateEnd, selectedRange, isFetchingData, analytics, referrerAnalytics,
    } = this.state;

    const { history } = this.props;

    function getDescriptionText() {
      return (
        <p className="information-bar">
          {'You have '}
          <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(analytics.linksCount, '.')} links`}</span>
          { ' and '}
          <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(analytics.campaignsCount, '.')} campaigns`}</span>
          {' with '}
          <span style={{ fontWeight: 'bold' }}>{`${addDecimalDivider(analytics.hitsTotals.data.total, '.')} clicks`}</span>
          {' in the selected time range.'}
        </p>
      );
    }

    return (
      <Content>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, paddingTop: '28px' }}>
            <h1 style={{
              display: 'inline-block',
              fontSize: '38px',
            }}
            >
              Analytics
            </h1>
            {analytics
              ? getDescriptionText()

              : null}
          </div>
          <div>
            <FilterBar>
              <DateFilter
                selectedRange={selectedRange}
                dateStart={dateStart}
                dateEnd={dateEnd}
                callback={(dates) => this.updateDate(dates)}
              />
            </FilterBar>
          </div>
        </div>
        {/*
          <Content bar>
            <div className="downloadCsv"
            onClick={() => downloadCsv('linkboards', this.props.id, { dateStart, dateEnd })}>
              <img src={`/icons/download.svg`} />
            </div>
          </Content>
        */}
        {
          analytics
            ? (
              <div>
                <LineChart
                  data={[
                    {
                      label: 'Links',
                      data: analytics.linkClicks.hitsByDay.data,
                      color: 'rgba(255, 183, 144, 1)',
                    },
                    {
                      label: 'Campaigns',
                      data: analytics.campaignClicks.hitsByDay.data,
                      color: 'rgb(194,212,177, 1)',
                    },
                  ]}
                  totalHits={analytics.hitsTotals.data.total}
                  headline="Clicks by day"
                  keyColor="rgba(199, 149, 208, 1.0)"
                  containerStyle={{ marginTop: 20 }}
                />
                <BarChart
                  data={[
                    {
                      label: 'Links',
                      data: analytics.linkClicks.hitsByHourOfDay.data,
                      color: 'rgba(255, 183, 144, 1)',
                    },
                    {
                      label: 'Campaigns',
                      data: analytics.campaignClicks.hitsByHourOfDay.data,
                      color: 'rgb(194,212,177, 1)',
                    },
                  ]}
                  headline="Clicks by time"
                  keyColor="rgba(199, 149, 208, 1.0)"
                  containerStyle={{ marginTop: 30 }}
                />
                <div className={chartStyle.chartGroup} style={{ marginBottom: 30 }}>
                  <DoughnutChart
                    data={analytics.hitsTotals.data}
                    headline="Devices"
                    colorDesktop="#318692"
                    colorMobile="#3DB3C4"
                    colorTablet="#B9DFE5"
                    containerStyle={{ marginTop: 30 }}
                  />
                  {referrerAnalytics ? (
                    <ListChart
                      data={referrerAnalytics.hitsByReferrer.data}
                      headline="Top 5 visitor sources by clicks"
                      containerStyle={{ marginTop: 30 }}
                    />
                  ) : null}

                </div>
                <Content style={{ color: 'grey', fontSize: '12px' }}>{`* displayed analytics include links and campaigns for your group(s): ${analytics.usergroupNames.join(', ')}`}</Content>
              </div>
            )
            : null
        }
        <Spinner show={isFetchingData} />
      </Content>
    );
  }
}

Analytics.propTypes = {
};
