import React from 'react';

import style from './style.module.scss';

const Snackbar = (props) => {

  const type = props.type === 'error' ? style.error : style.success;
  let cssClass = `${style.snackbar_wrapper} ${type}`;

  if (props.show) {
    cssClass = `${style.snackbar_wrapper} ${type} ${style.show}`;
  }

  // TODO: fix this mess
  return (
    <div className={cssClass}>
      <div className={style.snackbar}>
        {props.children === undefined
          ? 'Sorry, we have a small problem. Please try it later.'
          : (props.children[0] === undefined ? props.children : props.children[0]) }

      </div>
    </div>
  );
};

export default Snackbar;
