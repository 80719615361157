import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import { isSubscribed, isLoggedIn } from '../../util/user-management';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { userRole: [] };
  }

  render() {
    const { activeLink, showNavigation } = this.props;
    const showRoute = isLoggedIn() && isSubscribed();

    return (
      showNavigation
      && (
        <header className={style.header}>
          <Link className={style.logo} to="/">
            <img src="/icons/logo-vodafone-square.jpg"  alt="Vodafone Icon"/>
          </Link>

          <div className={style.nav_wrapper}>
            <nav className={style.nav_main}>
              {
                showRoute && (
                  <div>
                    <Link className={activeLink.includes('links') ? `${style.active}` : ''} to="/links">Links</Link>
                    <Link className={activeLink.includes('campaigns') ? `${style.active}` : ''} to="/campaigns">Campaigns</Link>
                    <Link className={activeLink.includes('analytics') ? `${style.active}` : ''} to="/analytics">Analytics</Link>
                  </div>
                )
              }
            </nav>

            <nav className={style.nav_icon}>

              <div className={style.adminNavigation}>

                <span
                  to="/"
                  className={style.nav_container}
                  // className={activeLink.includes('user') || activeLink.includes('groups') ? `${style.active}` : ''}
                >
                  <span className={style.active_wrapper}>
                    <Link to="/" className={activeLink.includes('groups') ? `${style.active}` : ''}>
                      <img className={style.active_icon} src="/icons/settings-active.svg" />
                      <img src="/icons/settings.svg" />
                    </Link>
                  </span>

                  <ul className={style.subnavigation}>
                    {showNavigation === 'admin' && showRoute
                    && (
                      <li>
                        <Link
                          className={activeLink.includes('groups') ? `${style.active}` : ''}
                          to="/groups"
                        >
                          <img src="/icons/usergroups.svg" />
                          <p>Groups</p>
                        </Link>
                      </li>
                    )}
                    {showNavigation === 'admin' && showRoute
                    && (
                      <li>
                        <Link
                          className={activeLink.includes('user') ? `${style.active}` : ''}
                          to="/user"
                        >
                          <img src="/icons/user.svg" />
                          <p>Users</p>
                        </Link>
                      </li>
                    )}
                    {showNavigation === 'admin'
                    && (
                      <li>
                        <Link
                          className={activeLink.includes('domains') ? `${style.active}` : ''}
                          to="/domains"
                        >
                          <img src="/icons/domain.svg" />
                          <p>Domains</p>
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        className={activeLink.includes('password') ? `${style.active}` : ''}
                        to="/password"
                      >
                        <img src="/icons/password.svg" />
                        <p>Change password</p>
                      </Link>
                    </li>
                  </ul>
                </span>
              </div>

              <Link className={style.logout} to="/logout">Logout</Link>
            </nav>
          </div>
        </header>
      )

    );
  }
}
