import React from 'react';
import style from './style.module.scss';

const Spinner = (props) => (
  props.loading
    ? (
      <div className="-loading -active">
        <div className="-loading-inner">
          <div className={`${style.wrapper} ${style.show}`}>
            <div className={style.wrapper_circle}>
              <div className={style.circle_dark} />
              <div className={style.circle_light} />
            </div>
          </div>
          <p>loading</p>
        </div>
      </div>
    ) : null
);

export default Spinner;
