/* eslint-disable no-case-declarations */
import {
  get, post, del,
} from '../api/common';
import { getAccount } from '../util/user-management';
import { fetchAccount } from '../api/account';

export const subscribeViaPayPal = async (store, state, payload) => {
  store.setState({ loading: true });

  await post('/api/subscribe', 'POST', payload);

  await fetchAccount();
  store.setState({ loading: false });
};

export const subscribeViaStripe = async (store, state, payload, stripe) => {
  store.setState({ loading: true });

  const subscription = await post('/api/subscribe', 'POST', payload);

  // subscription needs user action - perform 3D Secure
  if (subscription.status === 'incomplete') {
    const paymentIntentSecret = subscription.latest_invoice.payment_intent.client_secret;
    // notwithstanding the result redirect to the success page
    // handle the declination via webhook and
    // remind the customer to verify their payment method
    await stripe.confirmCardPayment(paymentIntentSecret);
  }

  await fetchAccount();
  store.setState({ loading: false });
};

export const unsubscribe = async (store, state, payload) => {
  store.setState({ loading: true });

  await post('/api/unsubscribe', 'POST', payload);

  await fetchAccount();
  store.setState({ loading: false });
};

export const deleteAccount = async (store) => {
  store.setState({ loading: true });

  const account = getAccount();
  await del(`/api/account/${account.id}`);

  store.setState({ loading: false });
  localStorage.clear();
  window.location.href = '/closed-account';
};

export const getInvoices = async (store) => {
  const invoices = await get('/api/invoices');

  store.setState({ invoices, invoicesLoading: false });
};
