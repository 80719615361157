import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import style from '../style.module.scss';
import actions from '../../../actions';
import Content from '../../../components/Content';
import PasswordValidator, { PASSWORD_VALIDATOR_MODE_CHANGE } from '../../../components/PasswordValidator';

// eslint-disable-next-line react/prefer-stateless-function
class PasswordChange extends Component {
  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Change Password',
    });
  }

  render() {
    const { history, location } = this.props;
    return (
      <Content>
        <div className={style.password_container}>
          <img src="/password.png" alt="Password" />
          <h1>Change password</h1>
          <div className={style.password_wrapper}>
            <PasswordValidator
              mode={PASSWORD_VALIDATOR_MODE_CHANGE}
              history={history}
              location={location}
            />
          </div>
        </div>
      </Content>
    );
  }
}

PasswordChange.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect('user', actions)(PasswordChange);
