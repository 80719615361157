import React from 'react';

import style from './style.module.scss';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchString: '' };
  }

  render() {
    const {
      autoComplete, placeholder, name, isDisabled, value, callback,
    } = this.props;

    const { searchString } = this.state;

    return (
      <div className={style.searchContainer}>
        <input
          type="text"
          autoComplete={autoComplete}
          name={name}
          placeholder={placeholder}
          value={searchString}
          callback={callback}
          onChange={(e) => {
            this.setState({ searchString: e.currentTarget.value });
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              callback(searchString);
            }
          }}
          disabled={isDisabled}
        />
        <button
          className={style.searchButton}
          type="button"
          onClick={() => {
            callback(searchString);
          }}
        >
          <div />

        </button>
      </div>
    );
  }
}

export default Search;
