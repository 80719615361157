import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-unistore";
import ReactGA from "react-ga";
import ReactTable from "react-table";
import { StripeProvider } from "react-stripe-elements";
import style from "./style.module.scss";
import PopUpStyle from "../../components/PopUp/popup.module.scss";
import PopUp from "../../components/PopUp";
import Form from "../../components/Form";
import Button from "../../components/Button";
import actions from "../../actions";
import ReactTableSpinner from "../../components/ReactTableSpinner";
import { getAccount, updateAddress } from "../../api/account";
import {
  PAYPAL,
  STRIPE,
  INVOICE,
  SUBSCRIPTION_PRICE,
} from "../../util/constants";
import {
  isAdmin,
  getLatestPayment,
  isSubscribed,
  getNextBillingDateFormatted,
  getSubscriptionId,
  isCanceled,
  getStripeCard,
} from "../../util/user-management";
import {
  paymentMethodIcon,
  formatToCurrency,
} from "../../util/helperFunctions";
import Content from "../../components/Content";
import Spinner from "../../components/Spinner";
import AddressForm from "../../components/AddressForm";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      account: null,
      displayAddressChangeDialog: false,
    };

    this.saveAddress = this.saveAddress.bind(this);
    this.confirmUnsubscribe = this.confirmUnsubscribe.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.toggleAddressChangedDialog = this.toggleAddressChangedDialog.bind(
      this
    );
  }

  async componentDidMount() {
    ReactGA.ga("send", {
      hitType: "pageview",
      page: window.location.pathname,
      title: "Payment Overview",
    });

    const account = await getAccount();
    this.setState({ account });

    console.log(account);

    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY),
        });
      });
    }

    !this.props.invoices.length && (await this.props.getInvoices());
  }

  async confirmUnsubscribe(e) {
    e.preventDefault();

    const input = document.getElementById("confirmCheckout");
    const inputVar = input.value;
    input.classList.remove("error");

    // checks whether the input is identical
    if (inputVar === "unsubscribe") {
      input.classList.remove("error");

      const payload = JSON.stringify({ subscriptionId: getSubscriptionId() });
      ReactGA.event({
        category: "Subscription",
        action: "Cancel",
        label: "Cancel subscription confirmed",
      });
      await this.props.unsubscribe(payload);
      this.props.togglePopup();
    } else {
      input.classList.add("error");
    }
  }

  async confirmDelete() {
    const input = document.getElementById("confirmCheckout");
    const inputVar = input.value;
    input.classList.remove("error");

    // checks whether the input is identical
    if (inputVar === "delete") {
      input.classList.remove("error");

      await this.props.deleteAccount();
    } else {
      input.classList.add("error");
    }
  }

  toggleAddressChangedDialog() {
    const { displayAddressChangeDialog } = this.state;
    this.setState({ displayAddressChangeDialog: !displayAddressChangeDialog });
  }

  resubscribeBtnClicked() {
    const { history } = this.props;
    history.push("/payment/choose-payment");
  }

  async saveAddress(data) {
    const {
      city,
      country,
      firstname,
      lastname,
      company,
      postalCode,
      streetName,
      streetNumber,
      vat,
    } = data;

    const payload = JSON.stringify({
      city,
      country,
      firstName: firstname,
      lastName: lastname,
      company,
      postalCode,
      street: streetName,
      streetNumber,
      vat,
    });

    await updateAddress(payload);
    const account = await getAccount();
    this.setState({
      account,
      displayAddressChangeDialog: false,
    });
  }

  render() {
    const { account, displayAddressChangeDialog } = this.state;
    const payment = getLatestPayment();
    const subscribed = isSubscribed();
    const canceled = isCanceled();
    const admin = isAdmin();
    const nextBillingDate = getNextBillingDateFormatted();

    const columns = [
      {
        Header: "Date",
        name: "date",
        id: "bill_id",
        style: {
          alignItems: "center",
          display: "flex",
        },
        accessor: (row) => row.date,
        filterable: false,
        Cell: (props) => {
          let date = new Date(props.original.date);
          date = date.toLocaleString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          return <p>{date}</p>;
        },
        sortable: false,
      },
      {
        Header: "Reference number",
        accessor: "reference",
        id: "reference_number",
        filterable: false,
        sortable: false,
        style: {
          alignItems: "center",
          display: "flex",
        },
        Cell: (props) => (
          <p title={props.original.reference} className="long-value">
            {props.original.reference}
          </p>
        ),
      },
      {
        Header: "Payment method",
        accessor: "paymentMethod",
        filterable: false,
        sortable: false,
        Cell: (props) => (
          <img
            className="card_left"
            alt={props.original.paymentMethod}
            src={`../../${paymentMethodIcon(props.original.paymentMethod)}`}
          />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        className: "center",
        headerClassName: "center",
        filterable: false,
        sortable: true,
        width: 150,
        Cell: (props) =>
          props.original.status === "paid" ||
          props.original.status === "COMPLETED" ? (
            <img
              style={{
                width: "15px",
                margin: "5px 0",
              }}
              src="/icons/tick.svg"
              alt=""
            />
          ) : (
            <img
              style={{
                width: "15px",
                margin: "5px 0",
              }}
              src="/close-orange.svg"
              alt=""
            />
          ),
      },
      {
        Header: "Price",
        accessor: "price",
        className: "center left",
        filterable: false,
        sortable: true,
        width: 150,
        Cell: (props) => <p>{`${props.original.price} €`}</p>,
      },
      {
        Header: "Invoice",
        accessor: "pdf",
        filterable: false,
        sortable: true,
        width: 150,
        Cell: (props) => (
          <a
            href={`${props.original.pdf}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            PDF
          </a>
        ),
      },
    ];

    // eslint-disable-next-line consistent-return
    function descriptionText() {
      const totalPrice = formatToCurrency(
        SUBSCRIPTION_PRICE * account.quantity
      );
      // eslint-disable-next-line default-case
      switch (payment.provider) {
        case STRIPE:
        case PAYPAL:
          return (
            <p>
              {"The abonnement will be renewed automatically on "}
              <b>{nextBillingDate}</b>
              .
              <br />
              {"With the next payment you will be charged "}
              <b>{`${totalPrice} €`}</b>
              {"for "}
              <b>{account.quantity}</b>
              {" active user"}
              {account.quantity > 1 && "s"}.
            </p>
          );
        case INVOICE:
          return (
            <p>
              {"You have chosen to pay by "}
              <b>invoice</b>
              .
              <br />
              The invoice is issued to:
            </p>
          );
      }
    }

    function getAddress() {
      return (
        <span style={{ lineHeight: `${1.5}em` }}>
          {account.name && (
            <span>
              {`${account.name}`} <br />
            </span>
          )}
          {`${account.firstname} ${account.lastname}`}
          <br />
          <span className="no-wrap">
          {`${account.streetName} ${account.streetNumber}`}
          </span>
          <br />
          {`${account.postalCode} ${account.city}`}
        </span>
      );
    }

    // eslint-disable-next-line consistent-return
    function getPaymentProviderLogo() {
      // eslint-disable-next-line default-case
      switch (payment.provider) {
        case STRIPE:
          return (
            <img
              className="card"
              src={`../../../${paymentMethodIcon(getStripeCard().brand)}`}
              alt="Card icon"
            />
          );
        case PAYPAL:
          return (
            <img
              className="card"
              src={`../../../${paymentMethodIcon("paypal")}`}
              alt="PayPal"
            />
          );
        case INVOICE:
          return (
            <img
              className="card"
              src={`../../../${paymentMethodIcon("invoice")}`}
              alt="Invoice"
            />
          );
      }
    }

    if (account === null) {
      return <Spinner show />;
    }

    return (
      <StripeProvider stripe={this.state.stripe}>
        <Content>
          <h1>Subscription</h1>

          <div className={style.box_container}>
            <div className={style.box_wrapper}>
              <div className={style.box}>
                <div>
                  <div>
                    {subscribed && !canceled && (
                      <div>
                        <h2>Payment</h2>
                        {descriptionText()}
                        <div
                          style={{ display: "flex" }}
                          className={style.payment_box}
                        >
                          {admin && (
                            <div
                              style={{
                                flex: 1,
                                marginRight: "20px",
                              }}
                            >
                              <p className="robotoSlab">Address:</p>
                              <div style={{ display: "flex" }}>
                                <div className={style.address}>
                                  {getAddress()}
                                </div>
                                <button
                                  type="button"
                                  style={{
                                    flex: 0,
                                    marginTop: "3px",
                                    padding: 0,
                                    border: "none",
                                    backgroundColor: "transparent",
                                    height: "30px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleAddressChangedDialog();
                                  }}
                                >
                                  {" "}
                                  <img
                                    className={style.edit}
                                    src="/icons/edit.svg"
                                    alt="Change Address"
                                  />
                                </button>
                              </div>
                            </div>
                          )}

                          <div style={{ flex: 1 }}>
                            <p className="robotoSlab">Payment method:</p>
                            <div className={style.icon_wrapper}>
                              {getPaymentProviderLogo()}
                              {subscribed &&
                                !canceled &&
                                payment.provider !== INVOICE && (
                                  <Link
                                    to="/payment/choose-payment"
                                    onClick={() => {
                                      ReactGA.event({
                                        category: "Payment",
                                        action: "Change",
                                        label: "Start Payment Update Process",
                                      });
                                    }}
                                  >
                                    <img
                                      className={style.edit}
                                      src="/icons/edit.svg"
                                      alt="Paypal"
                                    />
                                  </Link>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {!canceled && payment.provider !== INVOICE && (
                      <a
                        onClick={() => {
                          ReactGA.event({
                            category: "Subscription",
                            action: "Cancel",
                            label: "Open Cancel Subscription Modal",
                          });
                          this.props.togglePopup();
                        }}
                        className={style.account}
                      >
                        Cancel subscription
                      </a>
                    )}
                  </div>
                </div>
                {(!subscribed || canceled) && payment.provider !== INVOICE && (
                  <div>
                    <h2>Your abonnement is deactivated</h2>
                    {subscribed && canceled && (
                      <p>
                        You still have full access until{" "}
                        <span>{nextBillingDate}</span>.
                      </p>
                    )}
                    <p>
                      To get back to your account and all your statistics click
                      the button below and get started again.
                    </p>
                    <Button
                      label="Resubscribe now"
                      onClick={() => {
                        ReactGA.event({
                          category: "Subscription",
                          action: "Resubscribe",
                          label: "Start Process",
                        });
                        this.resubscribeBtnClicked(this);
                      }}
                    />
                    <a
                      onClick={this.props.togglePopup}
                      className={style.account}
                    >
                      Delete Account
                    </a>
                  </div>
                )}
                {!subscribed && payment.provider === INVOICE && (
                  <div>
                    <h2>Your abonnement is deactivated</h2>
                    <p>
                      To get back to your account and all your statistics click
                      the button below and get started again.
                    </p>
                    <Button
                      label="Resubscribe now"
                      onClick={() => {
                        this.resubscribeBtnClicked(this);
                      }}
                    />
                    <a
                      onClick={this.props.togglePopup}
                      className={style.account}
                    >
                      Delete Account
                    </a>
                  </div>
                )}
              </div>
            </div>
            <img src="/payment.png" alt="payment" />
          </div>

          {payment.provider !== INVOICE && (
            <div>
              <h1>Latest bills</h1>
              <div id={style.payment} style={{ minHeight: "150px" }}>
                <ReactTable
                  manual
                  data={this.props.invoices}
                  loading={this.props.invoicesLoading}
                  LoadingComponent={ReactTableSpinner}
                  columns={columns}
                  minRows={0}
                  resizable={false}
                  getTrProps={this.setActiveClass}
                  showPagination={false}
                />
              </div>
            </div>
          )}

          {displayAddressChangeDialog ? (
            <PopUp onClose={this.toggleAddressChangedDialog}>
              <div className={PopUpStyle.content_wrapper}>
                <h2>Change address</h2>
                <AddressForm
                  account={account}
                  onSave={this.saveAddress}
                  onCancel={this.toggleAddressChangedDialog}
                />
              </div>
            </PopUp>
          ) : null}

          {this.props.showPopup && (
            <PopUp>
              {subscribed && !canceled === true ? (
                <div className={PopUpStyle.content_wrapper}>
                  <h2>Do you really want to cancel your subscription?</h2>

                  <div className={PopUpStyle.warning}>
                    <img src="/icons/warning.svg" alt="" />
                    <p>
                      Upon termination of your subscription, you and all other
                      users will lose access to Links, Campaigns and Linkboards,
                      as well as any statistics regarding use of the service.
                    </p>
                  </div>

                  <Form>
                    <Form.Input
                      id="confirmCheckout"
                      width={400}
                      type="text"
                      label={
                        'Please type the word "unsubscribe" below to unsubscribe your account.'
                      }
                      placeholder={'Enter "unsubscribe"'}
                      name="unsubscribe"
                      autoComplete="off"
                    />

                    <Content button>
                      <Button
                        secondary
                        label="Cancel"
                        onClick={this.props.togglePopup}
                        type="reset"
                      />
                      <Button
                        onClick={this.confirmUnsubscribe}
                        label="Cancel subscription"
                      />
                    </Content>
                  </Form>
                </div>
              ) : (
                <div className={PopUpStyle.content_wrapper}>
                  <h2>Do you really want to delete your account?</h2>
                  <div className={PopUpStyle.warning}>
                    <img src="/icons/warning.svg" alt="" />
                    <p>Upon account deletion, you lose all statistics.</p>
                  </div>

                  <Form.Input
                    id="confirmCheckout"
                    width={400}
                    type="text"
                    label="To confirm please type the word „delete“."
                    placeholder={'Enter "delete"'}
                    name="delete"
                    autoComplete="off"
                  />

                  <Content button>
                    <Button
                      secondary
                      label="Cancel"
                      onClick={this.props.togglePopup}
                      type="reset"
                    />
                    <Button
                      onClick={() => {
                        ReactGA.event({
                          category: "Account",
                          action: "Delete",
                          label: "Delete account confirmed",
                        });
                        this.confirmDelete();
                      }}
                      label="Delete account now"
                    />
                  </Content>
                </div>
              )}
            </PopUp>
          )}
        </Content>
      </StripeProvider>
    );
  }
}

export default connect(
  "showPopup,invoices,invoicesLoading,user,loading",
  actions
)(Payment);
