import React, { Component } from 'react';
import { connect } from 'react-unistore';
import ReactGA from 'react-ga';
import actions from '../../../../actions';
import Content from '../../../../components/Content';
import style from './style.module.scss';
import Copy from '../../../../components/Copy';
import Button from '../../../../components/Button';

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { link } = this.props;

    return (
      <Content successPage>

        <div className={style['content-container']}>
          <h1>Well done!</h1>
          <h2>Shortlink successfully created.</h2>

          <table className={`ReactTable ${style['table-general']}`}>
            <tr>
              <th>Title:</th>
              <td>{link.title}</td>
            </tr>
            <tr>
              <th>Target URL:</th>
              <td><a className={style.shorten} href={link.targetUrl} target="_blank">{link.targetUrl}</a></td>
            </tr>
            <tr>
              <th>Group:</th>
              <td>{link.usergroup}</td>
            </tr>
          </table>

          <p>
            <b>Shortlink:</b>
            {' '}
            <Copy value={link.url} />
          </p>

          <Content button style={{ display: 'inline-block', marginTop: '10px' }}>
            <Button
              label="Edit link"
              secondary
              onClick={() => {
                ReactGA.event({
                  category: 'Link',
                  action: 'Edit',
                  label: 'Open Editor (from Success Page)',
                });
                this.props.history.push(`/links/edit/${link.id}`);
              }}
            />
            <Button
              label="Links overview"
              onClick={
              () => {
                ReactGA.event({
                  category: 'Link',
                  action: 'Back to Overview',
                  label: 'Back to Link Overview',
                });
                this.props.history.push(`/links?highlightId=${link.id}`);
              }
}
            />

          </Content>
        </div>

        <div className={style['image-container']}>
          <img src="/shortlink-success.png" alt="" />
        </div>

      </Content>
    );
  }
}

export default connect('link', actions)(Step2);
