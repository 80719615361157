import { socialIconOptions } from '../util/socialIconLibrary';

export const LB_DEFAULT_BUTTON = {
  backgroundColor: '',
  id: null,
  color: '',
  text: 'Button',
  iconType: 'no-icon',
  iconFile: {
    name: null,
    url: null,
  },
  icon: null,
  targetUrl: '',
  isActive: true,
};
export const LB_DEFAULT_PRODUCT = {
  image: null,
  price: '0',
  text: 'Product 1',
  id: null,
  shortUrl: '',
  targetUrl: '',
  imageFile: {
    name: null,
    url: null,
  },
  isActive: true,
};
export const LB_DEFAULT_SOCIAL = {
  backgroundColor: '',
  color: '',
  iconType: 'socicon-facebook',
  icon: '',
  targetUrl: '',
  iconFile: {
    name: null,
    url: null,
  },
  id: null,
  isActive: true,
};

export const LB_DEFAULTS = {
  id: -1,
  linkboardBranding: {},
  buttons: [],
  logoFile: {
    name: null,
    url: null,
  },
  headerFile: {
    name: null,
    url: null,
  },
  grid: false,
  headline: 'Headline',
  description: 'Description',
  imageFile: null,
  preview: '',
  currency: 'EUR',
  products: [],
  shortUrl: '',
  socials: [],
  title: '',
  type: 'standard',
  user: '',
  usergroup: '',
  domain: '',
  code: '',
  pixelId: '',
  policyUrl: '',
};

function findLastIndex(array, searchKey, searchValue) {
  const index = array.slice().reverse().findIndex((x) => x[searchKey] === searchValue);
  const count = array.length - 1;
  const finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
}

/*
 * get temporary id for objects created in browser;
 * - returns negative ids to avoid collisions with server generated ids
 *
 * i.e. -1, -2, -3 ,-4 ...
 *
 * precondition:
 *  - the user must only be allowed to delete the last item of the stack (pop());
 *  - otherwise this approach will obviously generate collisions
 *
 * @param {Array} arrayOfObjects
 * @returns {number} id
 */
const getTemporaryId = (arrayOfObjects) => (arrayOfObjects.length + 1) * -1;

/**
 *  precondition:
 *  - the user must only be allowed to delete the last item of the stack (pop());
 *  - otherwise this approach will not work correctly
 * @param {*} items
 */
const removeItem = (items) => {
  // temp items have a negative id
  const workingItems = items;
  // if the item has been persisted already we just disable it
  if (items[items.length - 1].id > 0) {
    const indexOfLastActiveItem = findLastIndex(items, 'isActive', true);
    if (indexOfLastActiveItem !== -1) {
      workingItems[indexOfLastActiveItem].isActive = false;
    }
  } else { // temporary items can just be dropped
    workingItems.pop();
  }
  return workingItems;
};
/**
 * Perform actions on linkboard
 * collection of pure functions
 */
const LinkboardActions = {

  applyGlobalBranding(linkboard, branding) {
    const brandedLinkboard = linkboard;

    brandedLinkboard.logoFile = branding.logoFile;
    brandedLinkboard.headerFile = branding.headerFile;
    brandedLinkboard.linkboardBranding = {
      font: branding.font,
      backgroundColor: branding.backgroundColor,
      fontColor: branding.fontColor,
      primaryButtonColor: branding.primaryButtonColor,
      primaryTextColor: branding.primaryTextColor,
      secondaryButtonColor: branding.secondaryButtonColor,
      secondaryTextColor: branding.secondaryTextColor,
    };

    return brandedLinkboard;
  },

  applyGlobalBrandingForEditing(linkboard, branding) {
    const brandedLinkboard = linkboard;

    brandedLinkboard.linkboardBranding = {
      primaryButtonColor: branding.primaryButtonColor,
      primaryTextColor: branding.primaryTextColor,
      secondaryButtonColor: branding.secondaryButtonColor,
      secondaryTextColor: branding.secondaryTextColor,
      ...linkboard.linkboardBranding,
    };

    return brandedLinkboard;
  },

  addButton(linkboard) {
    const { buttons } = linkboard;
    return {
      ...linkboard,
      buttons: [
        ...buttons,
        {
          ...LB_DEFAULT_BUTTON,
          id: getTemporaryId(buttons),
          backgroundColor: buttons.length > 1
            ? buttons[buttons.length - 1].backgroundColor
            : linkboard.linkboardBranding.primaryButtonColor,
          color: buttons.length > 1
            ? buttons[buttons.length - 1].color
            : linkboard.linkboardBranding.primaryTextColor,
        },
      ],
    };
  },

  removeButton(linkboard) {
    const { buttons } = linkboard;
    const updatedButtons = removeItem(buttons);

    return {
      ...linkboard,
      buttons: updatedButtons,
    };
  },

  addProduct(linkboard) {
    const { products } = linkboard;
    return {
      ...linkboard,
      products: [
        ...products,
        {
          ...LB_DEFAULT_PRODUCT,
          id: getTemporaryId(products),
        },
      ],
    };
  },

  removeProduct(linkboard) {
    const { products } = linkboard;
    const updatedProducts = removeItem(products);
    return {
      ...linkboard,
      products: updatedProducts,
    };
  },

  addSocial(linkboard) {
    const { socials } = linkboard;
    const options = socialIconOptions;

    const notInUseOptions = options.filter((option) => {
      let notInUse = true;
      if (option.id === 'no-icon') {
        notInUse = false;
      }
      socials.forEach((social) => {
        if (social.iconType === option.id) {
          notInUse = false;
        }
      });
      return notInUse;
    });

    return {
      ...linkboard,
      socials: [
        ...socials,
        {
          ...LB_DEFAULT_SOCIAL,
          id: getTemporaryId(socials),
          backgroundColor: socials.length > 1
            ? socials[socials.length - 1].backgroundColor
            : linkboard.linkboardBranding.primaryButtonColor,
          color: socials.length > 1
            ? socials[socials.length - 1].color
            : linkboard.linkboardBranding.primaryTextColor,
          iconType: notInUseOptions.length > 0 ? notInUseOptions[0].id : 'no-icon',
        },
      ],
    };
  },

  removeSocial(linkboard) {
    const { socials } = linkboard;
    const updatedSocials = removeItem(socials);
    return {
      ...linkboard,
      socials: updatedSocials,
    };
  },
};

export default LinkboardActions;
