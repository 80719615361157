/* eslint-disable import/prefer-default-export */
import { get, post } from './common';

export const fetchAccount = async () => {
  const account = await get('/api/account');
  localStorage.setItem('account', JSON.stringify(account));
};

export const getAccount = async () => {
  const account = await get('/api/account');
  localStorage.setItem('account', JSON.stringify(account));
  return account;
};

export const updateAddress = async (payload) => post('/api/account', 'POST', payload);
