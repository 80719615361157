import ReactGA from 'react-ga';
import LinkboardActions, { LB_DEFAULTS } from '../model/linkboard';
import { getCode, validateCode } from '../api/data-management';
import {
  createLinkboard, getLinkboard, getLinkboards, updateLinkboard,
} from '../api/linkboards';
import { refreshUserData, toggleSnackbar } from './general';
import { updateBranding } from '../api/branding';

export const EDITOR_STATE_SELECT_TEMPLATE = 'EDITOR_STATE_SELECT_TEMPLATE';
export const EDITOR_STATE_EDITOR = 'EDITOR_STATE_EDITOR';
export const EDITOR_STATE_SUMMARY = 'EDITOR_STATE_SUMMARY';

const DEFAULT_CONFIG = {
  steps: [],
  currentStep: null,
  useHeaderImage: true,
  useLogoImage: true,
  overrideGlobalBrandingOnSave: false,
  // only one section can be expanded at a given time
  expandedSection: 'meta-data',
};

const REGEX_VALID_URL = /\b(?:(?:https?):\/\/|www\.)[-a-z0-9+&@#/%?=~_|!:,.;]*[-a-z0-9+&@#/%=~_|]/i;

// Helpers

const getPropertyValidationObject = (hasError = false, errorMessage = null) => ({
  error: hasError,
  message: hasError ? errorMessage : null,
});

/**
 * transforms workingCopy into something the api will understand
 * @param {*} workingCopy
 */
const prepareForApi = (workingCopy) => {
  const linkboard = { ...workingCopy };
  if (linkboard.id === -1) { // remove temp id
    delete linkboard.id;
  }

  if (linkboard.logoFile && linkboard.logoFile.url !== null) {
    linkboard.brandingImageFile = linkboard.logoFile.url;
  } else {
    linkboard.brandingImageFile = null;
  }
  delete linkboard.logoFile;
  if (linkboard.headerFile && linkboard.headerFile.url !== null) {
    linkboard.imageFile = linkboard.headerFile.url;
  } else {
    linkboard.imageFile = null;
  }
  delete linkboard.headerFile;

  linkboard.buttons = linkboard.buttons.map((item) => {
    const iForApi = { ...item };
    if (iForApi.id < 0) { // remove temp id
      iForApi.id = null;
    }

    if (iForApi.iconFile.url !== null) {
      iForApi.icon = iForApi.iconFile.url;
    }
    delete iForApi.iconFile;
    return iForApi;
  });

  linkboard.products = linkboard.products.map((item) => {
    const iForApi = { ...item };
    if (iForApi.id < 0) { // remove temp id
      iForApi.id = null;
    }

    if (iForApi.imageFile.url !== null) {
      iForApi.image = iForApi.imageFile.url;
    }
    delete iForApi.imageFile;
    return iForApi;
  });

  linkboard.socials = linkboard.socials.map((item) => {
    const iForApi = { ...item };
    if (iForApi.id < 0) { // remove temp id
      iForApi.id = null;
    }

    if (iForApi.iconFile.url !== null) {
      iForApi.icon = iForApi.iconFile.url;
    }
    delete iForApi.iconFile;
    return iForApi;
  });

  linkboard.brandingBackgroundColor = linkboard.linkboardBranding.backgroundColor;
  linkboard.brandingFont = linkboard.linkboardBranding.font;
  linkboard.brandingFontColor = linkboard.linkboardBranding.fontColor;

  delete linkboard.linkboardBranding;

  // const data = new FormData();
  // Object.keys(linkboard).forEach((key) => {
  //   switch (key) {
  //     case 'buttons':
  //     case 'products':
  //     case 'socials':
  //       data.set(key, JSON.stringify(linkboard.key));
  //       break;
  //     case 'linkboardBranding':
  //       Object.keys(linkboard[key]).forEach((brandingKey) => {
  //         data.set(brandingKey, linkboard[key][brandingKey]);
  //       });
  //       break;
  //     default:
  //       data.set(key, linkboard[key]);
  //   }
  // });

  return linkboard;
};

const loadFile = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.data);
  reader.addEventListener('load', () => {
    resolve(reader.result);
  });
});

/// //////////////////////
// Linkboard Validation //
/// //////////////////////

export const propertyHasValidationErrors = (
  propertyName,
  validation,
) => validation && (propertyName in validation) && validation[propertyName].error;

export const propertiesHaveValidationErrors = (arrayOfPropertyNames, validation) => {
  let hasError = false;
  arrayOfPropertyNames.forEach((property) => {
    if (propertyHasValidationErrors(property, validation)) {
      hasError = true;
    }
  });
  return hasError;
};

export const itemsHaveValidationErrors = (itemsValidationData) => {
  let hasError = false;

  itemsValidationData.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'id' && item[key].error === true) {
        hasError = true;
      }
    });
  });

  return hasError;
};

const errorMessages = {
  title: 'Please fill out this field.',
  code: 'The Slash-Tag must not contain any whitespace and can not be blank.',
  policy: 'Please enter a valid URL.',
  headerFile: 'Please upload image or select No.',
  logoFile: 'Please upload image or select No.',
};

const validateProperty = (name, value, config) => {
  // eslint-disable-next-line default-case
  switch (name) {
    case 'title':
    case 'code':
      // can't be blank
      return getPropertyValidationObject(
        value === '',
        errorMessages[name],
      );
    case 'policyUrl':
      // optional url
      return getPropertyValidationObject(
        (value !== '' && REGEX_VALID_URL.test(String(value).toLocaleLowerCase()) === false),
        errorMessages[name],
      );
    case 'headerFile':
      return getPropertyValidationObject(
        (config.useHeaderImage === true && !value.url),
        errorMessages[name],
      );
    case 'logoFile':
      return getPropertyValidationObject(
        (config.useLogoImage === true && !value.url),
        errorMessages[name],
      );
    case 'backgroundColor': // from linkboardBranding
    case 'font': // from linkboardBranding
    case 'fontColor': // from linkboardBranding
    case 'primaryButtonColor': // from linkboardBranding
    case 'primaryTextColor': // from linkboardBranding
    case 'secondaryButtonColor': // from linkboardBranding
    case 'secondaryTextColor': // from linkboardBranding
    case 'domain': // is excpected to be valid
    case 'usergroup': // is excpected to be valid
    case 'headline': // optional
    case 'description': // optional
    default:
      return getPropertyValidationObject();
  }
};

const itemErrorMessages = {
  text: 'Button text can not be blank.',
  targetUrl: 'Please enter a valid URL.',
  imageFile: 'Please upload an image.',
};

const validateItemProperty = (name, value) => {
  // eslint-disable-next-line default-case
  switch (name) {
    case 'text':
      // can't be blank
      return getPropertyValidationObject(
        value === '',
        itemErrorMessages[name],
      );
    case 'targetUrl':
      // required url
      return getPropertyValidationObject(
        (REGEX_VALID_URL.test(String(value).toLocaleLowerCase()) === false),
        itemErrorMessages[name],
      );
    case 'imageFile':
      return getPropertyValidationObject(
        (!value.url),
        itemErrorMessages[name],
      );
    case 'backgroundColor': // from linkboardBranding
    case 'font': // from linkboardBranding
    case 'fontColor': // from linkboardBranding
    default:
      return getPropertyValidationObject();
  }
};

const validateLinkboardItems = (items) => {
  const itemsValidations = [];

  items.forEach((item) => {
    const itemValidation = {};
    Object.keys(item).forEach((key) => {
      itemValidation[key] = validateItemProperty(key, item[key]);
    });
    itemValidation.id = item.id;
    itemsValidations.push(itemValidation);
  });

  return itemsValidations;
};

const validateLinkboard = (linkboard, config) => {
  const validation = {};

  Object.keys(linkboard).forEach((key) => {
    switch (key) {
      case 'buttons':
      case 'products':
      case 'socials':
        validation[key] = validateLinkboardItems(linkboard[key]);
        break;
      case 'linkboardBranding':
        // eslint-disable-next-line no-case-declarations
        const brandingValidation = {};
        Object.keys(linkboard[key]).forEach((brandingKey) => {
          brandingValidation[brandingKey] = validateProperty(
            brandingKey,
            linkboard[key][brandingKey],
          );
        });
        validation[key] = brandingValidation;
        break;
      default:
        validation[key] = validateProperty(key, linkboard[key], config);
    }
  });
  return validation;
};

/**
 *
 * @param {*} validation validation object returned by validateLinkboard
 * @return {bool} true if validation does not have an error; false otherwise
 */
const verifyValidation = (validation) => {
  const validationJSON = JSON.stringify(validation);

  // this should be quicker than iterating over everything
  return validationJSON.indexOf('"error":true') === -1;
};

/// /////////////////////
// Linkboard Lifecycle //
/// /////////////////////

export const discard = async (store) => {
  store.setState({
    linkboardEditor: {
      config: DEFAULT_CONFIG,
      workingCopy: LB_DEFAULTS,
      validation: {},
    },
  });
};

export const initForCreation = async (store, state) => {
  let linkboard = LB_DEFAULTS;
  const code = await getCode();
  linkboard.code = code.code;

  linkboard.domain = state.domains[0].id;
  linkboard.usergroup = state.usergroups[0].id;

  linkboard = LinkboardActions.applyGlobalBranding(linkboard, state.branding);

  store.setState({
    linkboardEditor: {
      config: {
        ...DEFAULT_CONFIG,
        steps: [
          EDITOR_STATE_SELECT_TEMPLATE,
          EDITOR_STATE_EDITOR,
          EDITOR_STATE_SUMMARY,
        ],
        currentStep: EDITOR_STATE_SELECT_TEMPLATE,

      },
      workingCopy: linkboard,
      validation: {},
    },
  });
};

export const initForEditing = async (store, state, id) => {
  store.setState({ loading: true });
  let linkboard = await getLinkboard(id);

  linkboard = LinkboardActions.applyGlobalBrandingForEditing(linkboard, state.branding);

  store.setState({
    loading: false,
    linkboardEditor: {
      config: {
        ...DEFAULT_CONFIG,
        steps: [
          EDITOR_STATE_EDITOR,
          EDITOR_STATE_SUMMARY,
        ],
        currentStep: EDITOR_STATE_EDITOR,
        useHeaderImage: linkboard.headerFile.url !== null,
        useLogoImage: linkboard.logoFile.url !== null,
      },
      workingCopy: linkboard,
      validation: {},
    },
  });
};

export const generateDummyContent = (linkboard) => {
  let updatedLinkboard = linkboard;
  // eslint-disable-next-line default-case
  switch (linkboard.type) {
    case 'standard':
      updatedLinkboard = LinkboardActions.addButton(updatedLinkboard);
      updatedLinkboard = LinkboardActions.addSocial(updatedLinkboard);
      break;
    case 'ecommerce':
      updatedLinkboard.grid = true;
      updatedLinkboard = LinkboardActions.addButton(updatedLinkboard);
      updatedLinkboard = LinkboardActions.addProduct(updatedLinkboard);
      updatedLinkboard = LinkboardActions.addSocial(updatedLinkboard);
      break;
  }
  return updatedLinkboard;
};

/// //////////////////
// Update Linkboard //
/// //////////////////

/**
 * update property with value and clear validation error for the given property
 * @param {*} store
 * @param {*} state
 * @param {*} property
 * @param {*} value
 */
export const updateLinkboardProperty = async (store, state, property, value) => {
  const { workingCopy: linkboard, config, validation } = state.linkboardEditor;

  switch (property) {
    case 'font':
    case 'fontColor':
    case 'backgroundColor':
      linkboard.linkboardBranding[property] = value;
      // reset validation when property changes
      if (validation.linkboardBranding && validation.linkboardBranding[property]) {
        validation.linkboardBranding[property] = getPropertyValidationObject();
      }
      break;
    case 'logoFile':
    case 'headerFile':
      if (value && value.restoreCache === true) {
        linkboard[property] = {
          url: value.url,
          name: value.name,
        };
      } else if (value !== null) { // value is expected to be fileir object from uppy
        const result = await loadFile(value);
        linkboard[property] = {
          url: result,
          name: linkboard[property].name === null ? value.data.name : linkboard[property].name,
        };
      } else {
        linkboard[property] = {
          url: null,
          name: null,
        };
      }
      // reset validation when property changes
      if (validation[property]) {
        validation[property] = getPropertyValidationObject();
      }
      break;
    default:
      linkboard[property] = value;
      // reset validation when property changes
      if (validation[property]) {
        validation[property] = getPropertyValidationObject();
      }
  }

  store.setState({
    linkboardEditor: {
      config,
      workingCopy: linkboard,
      validation,
    },
  });
};

/// /////////////////////////////////////////////////////////////////////
// add, remove and update Linkboard Items (buttons, produtcs, socials) //
/// /////////////////////////////////////////////////////////////////////

/**
 *
 * @param {*} store
 * @param {*} state
 * @param {*} entitiesToSearch {buttons | products | socials}
 * @param {*} id the id of the entiry object
 * @param {*} property the property to update
 * @param {*} value the value to assign to the property
 */
export const updateLinkboardItemProperty = async (
  store,
  state,
  entitiesToSearch,
  id,
  property,
  value) => {
  const { workingCopy: linkboard, config, validation } = state.linkboardEditor;

  const index = linkboard[entitiesToSearch].findIndex((i) => i.id === id);

  switch (property) {
    case 'iconFile':
    case 'imageFile':
      if (value && value.restoreCache === true) {
        linkboard[entitiesToSearch][index][property] = {
          url: value.url,
          name: value.name,
        };
      } else if (value !== null) { // value is expected to be fileir object from uppy
        const result = await loadFile(value);
        linkboard[entitiesToSearch][index][property] = {
          url: result,
          name: linkboard[entitiesToSearch][index][property].name === null
            ? value.data.name
            : linkboard[entitiesToSearch][index][property].name,
        };
      } else {
        linkboard[entitiesToSearch][index][property] = {
          url: null,
          name: null,
        };
      }
      // reset validation when property changes iff object was previously validated
      if (validation[entitiesToSearch] !== undefined
        && validation[entitiesToSearch][index] !== undefined) {
        validation[entitiesToSearch][index][property] = getPropertyValidationObject();
      }
      break;
    default:
      linkboard[entitiesToSearch][index][property] = value;
      // reset validation when property changes iff object was previously validated
      if (validation[entitiesToSearch] !== undefined
        && validation[entitiesToSearch][index] !== undefined) {
        validation[entitiesToSearch][index][property] = getPropertyValidationObject();
      }
  }

  store.setState({
    linkboardEditor: {
      config,
      workingCopy: linkboard,
      validation,
    },
  });
};

export const addLinkboardItem = async (store, state, entity) => {
  const { workingCopy, config } = state.linkboardEditor;
  let { validation } = state.linkboardEditor;
  let linkboard = workingCopy;

  // eslint-disable-next-line default-case
  switch (entity) {
    case 'button':
      linkboard = LinkboardActions.addButton(linkboard);
      break;
    case 'product':
      linkboard = LinkboardActions.addProduct(linkboard);
      break;
    case 'social':
      linkboard = LinkboardActions.addSocial(linkboard);
      break;
  }

  if (JSON.stringify(validation) !== '{}') {
    validation = validateLinkboard(workingCopy, config);
  }

  store.setState({
    linkboardEditor: {
      config,
      workingCopy: linkboard,
      validation,
    },
  });
};

export const removeLinkboardItem = async (store, state, entity) => {
  const { workingCopy, config } = state.linkboardEditor;
  let { validation } = state.linkboardEditor;
  let linkboard = workingCopy;

  // eslint-disable-next-line default-case
  switch (entity) {
    case 'button':
      linkboard = LinkboardActions.removeButton(linkboard);
      break;
    case 'product':
      linkboard = LinkboardActions.removeProduct(linkboard);
      break;
    case 'social':
      linkboard = LinkboardActions.removeSocial(linkboard);
      break;
  }

  if (JSON.stringify(validation) !== '{}') {
    validation = validateLinkboard(workingCopy, config);
  }

  store.setState({
    linkboardEditor: {
      config,
      workingCopy: linkboard,
      validation,
    },
  });
};

/// ///////////////
// Editor Config //
/// ///////////////

export const toggleSection = async (store, state, section) => {
  const { workingCopy, config, validation } = state.linkboardEditor;

  config.expandedSection = config.expandedSection !== section ? section : '';

  store.setState({
    linkboardEditor: {
      config,
      workingCopy,
      validation,
    },
  });
};

const updateGlobalBranding = async (linkboardPreppedForApi, branding) => {
  const brandingData = {
    imageFile: linkboardPreppedForApi.brandingImageFile,
    headerImageFile: linkboardPreppedForApi.imageFile,
    font: linkboardPreppedForApi.brandingFont,
    fontColor: linkboardPreppedForApi.brandingFontColor,
    backgroundColor: linkboardPreppedForApi.brandingBackgroundColor,
    primaryButtonColor: branding.primaryButtonColor,
    primaryTextColor: branding.primaryTextColor,
    secondaryButtonColor: branding.secondaryButtonColor,
    secondaryTextColor: branding.secondaryTextColor,
  };

  await updateBranding(brandingData);
};

export const nextStep = async (store, state) => {
  let { workingCopy, validation } = state.linkboardEditor;
  const { config } = state.linkboardEditor;
  const { branding } = state;
  const { steps, currentStep } = config;

  const next = steps[steps.indexOf(currentStep) + 1];

  // eslint-disable-next-line default-case
  switch (next) { // next step; prepare; validate; ...
    case EDITOR_STATE_EDITOR:
      workingCopy = generateDummyContent(workingCopy);
      config.currentStep = next;
      break;
    case EDITOR_STATE_SUMMARY:

      validation = validateLinkboard(workingCopy, config);
      if (verifyValidation(validation)) {
        const linkboardPreparedForApi = prepareForApi(workingCopy);
        store.setState({ loading: true });
        if (workingCopy.id === -1) { // create linkboard
          try {
            // check if slash tag is available
            await validateCode(workingCopy.code);

            try {
              ReactGA.event({
                category: 'Linkboard',
                action: 'Create',
                label: 'Create Linkboard',
              });
              const res = await createLinkboard(linkboardPreparedForApi);
              const createdLinkboard = await getLinkboards(res.id);
              workingCopy = createdLinkboard;
              config.currentStep = next;
            } catch (err) {
              toggleSnackbar(store, state, `${err}`);
            }
          } catch (err) {
            validation.code = getPropertyValidationObject(true, 'Slash-Tag already in use.');
            toggleSnackbar(store, state, 'Slash-Tag already in use.');
          }
        } else { // update linkboard
          try {
            ReactGA.event({
              category: 'Linkboard',
              action: 'Edit',
              label: 'Save Changes',
            });
            const res = await updateLinkboard(linkboardPreparedForApi);
            const updatedLinkboard = await getLinkboards(res.id);
            workingCopy = updatedLinkboard;
            config.currentStep = next;
          } catch (err) {
            toggleSnackbar(store, state, `${err}`);
          }
        }
        if (config.overrideGlobalBrandingOnSave === true) {
          try {
            await updateGlobalBranding(linkboardPreparedForApi, branding);
            await refreshUserData(store, state);
          } catch (err) {
            toggleSnackbar(store, state, 'Failed to update global branding.');
          }
        }
      } else {
        toggleSnackbar(store, state, 'Please complete all required fields!');
      }
      break;
  }

  store.setState({
    loading: false,
    linkboardEditor: {
      config,
      workingCopy,
      validation,
    },
  });
};

export const updateConfigProperty = async (store, state, property, value) => {
  const { workingCopy, config, validation } = state.linkboardEditor;

  config[property] = value;

  store.setState({
    linkboardEditor: {
      config,
      workingCopy,
      validation,
    },
  });
};

export const toggleOverrideGlobalBrandingOnSave = async (store, state) => {
  const { workingCopy, config, validation } = state.linkboardEditor;

  config.overrideGlobalBrandingOnSave = !config.overrideGlobalBrandingOnSave;

  store.setState({
    linkboardEditor: {
      config,
      workingCopy,
      validation,
    },
  });
};
/// /////////
// Exports //
/// /////////

export default {
  initForCreation,
  initForEditing,
  updateLinkboardProperty,
  nextStep,
  toggleSection,
  addLinkboardItem,
  removeLinkboardItem,
  toggleOverrideGlobalBrandingOnSave,
  updateConfigProperty,
  updateLinkboardItemProperty,
  discard,
};
