import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import Form from '../../../components/Form';
import Button from '../../../components/Button';
import actions from '../../../actions';
import { validateRequiredFields } from '../../../util/validates';
import Content from '../../../components/Content';
import { createGroup } from '../../../api/groups';

class GroupsCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Groups Create',
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {
      history, toggleSnackbar, toggleLoading, refreshAdminData,
    } = this.props;
    const { name } = this.state;
    const payload = JSON.stringify({ name });

    // TODO: do not access dom directly in react app!!!
    const requiredFields = Array.from(document.querySelectorAll('[data-required]'));
    const error = validateRequiredFields(requiredFields);

    if (!error) {
      toggleLoading(true);
      try {
        ReactGA.event({
          category: 'Group',
          action: 'Create',
          label: 'Create Group',
        });
        const json = await createGroup(payload);
        // refetch users and groups
        await refreshAdminData();
        toggleLoading(false);
        history.push(`/groups?highlightId=${json.id}`);
      } catch (err) {
        toggleLoading(false);
        toggleSnackbar(err.message);
      }
    } else {
      toggleSnackbar('Please complete all required fields!');
    }
  }

  render() {
    const { name } = this.state;
    const { history } = this.props;
    return (
      <Content>
        <h1>Create group</h1>
        <Form>

          <Form.Input
            name="name"
            value={name}
            onInput={this.handleChange}
            type="text"
            label="Name"
            data-required
            errorMessage="Group must not be empty."
            width={100}
            placeholder="Group name"
          />

          <Content button>
            <Button label="Cancel" secondary onClick={() => history.push('/groups')} />
            <Button label="Save changes" onClick={this.handleSubmit} />
          </Content>

        </Form>
      </Content>
    );
  }
}

GroupsCreate.propTypes = {
  toggleSnackbar: PropTypes.func.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  refreshAdminData: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect('', actions)(GroupsCreate);
