import React, { Component } from 'react';

import style from './style.module.scss';

export default class AddButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const buttonClass = `${style.button} ${this.props.color} ${this.props.icon}`;

    return (
      <div className={style.button_wrapper} onClick={this.props.onClick}>
        <span className={buttonClass} />
        <p className={style.button_text}>{this.props.text}</p>
      </div>
    );
  }
}
