export const socialIconOptions = [
  {
    name: 'No icon selected',
    id: 'no-icon',
  },
  {
    name: 'Facebook',
    id: 'socicon-facebook',
  },
  {
    name: 'Instagram',
    id: 'socicon-instagram',
  },
  {
    name: 'Twitter',
    id: 'socicon-twitter',
  },
  {
    name: 'Pinterest',
    id: 'socicon-pinterest',
  },
  {
    name: 'TikTok',
    id: 'socicon-tiktok',
  },
  {
    name: 'Snapchat',
    id: 'socicon-snapchat',
  },
  {
    name: 'Whatsapp',
    id: 'socicon-whatsapp',
  },
  {
    name: 'Messenger',
    id: 'socicon-messenger',
  },
  {
    name: 'Vimeo',
    id: 'socicon-vimeo',
  },
  {
    name: 'YouTube',
    id: 'socicon-youtube',
  },
  {
    name: 'LinkedIn',
    id: 'socicon-linkedin',
  },
  {
    name: 'Xing',
    id: 'socicon-xing',
  },
  {
    name: 'Twitch',
    id: 'socicon-twitch',
  },
  {
    name: 'Mail',
    id: 'socicon-mail',
  },
  {
    name: 'Internet',
    id: 'socicon-internet',
  },
  {
    name: 'Unsplash',
    id: 'socicon-unsplash',
  },
  {
    name: 'iTunes',
    id: 'socicon-itunes',
  },
  {
    name: 'Spotify',
    id: 'socicon-spotify',
  },
  {
    name: 'Deezer',
    id: 'socicon-deezer',
  },
  {
    name: 'Last.fm',
    id: 'socicon-lastfm',
  },
  {
    name: 'SoundCloud',
    id: 'socicon-soundcloud',
  },
  {
    name: 'App Store',
    id: 'socicon-appstore',
  },
  {
    name: 'Android',
    id: 'socicon-android',
  },
  {
    name: 'Apple',
    id: 'socicon-apple',
  },
  {
    name: 'Play',
    id: 'socicon-play',
  },
  {
    name: 'Amazon',
    id: 'socicon-amazon',
  },
  {
    name: 'Airbnb',
    id: 'socicon-airbnb',
  },
  {
    name: 'Yelp',
    id: 'socicon-yelp',
  },
  {
    name: 'Dribbble',
    id: 'socicon-dribbble',
  },
  {
    name: 'Delicious',
    id: 'socicon-delicious',
  },
  {
    name: 'Medium',
    id: 'socicon-medium',
  },
  {
    name: 'HomeAdvisor',
    id: 'socicon-homeadvisor',
  },
  {
    name: 'GoToMeeting',
    id: 'socicon-gotomeeting',
  },
  {
    name: 'Google Hangouts',
    id: 'socicon-googlehangouts',
  },
  {
    name: 'Zoom',
    id: 'socicon-zoom',
  },
  {
    name: 'Skype',
    id: 'socicon-skype',
  },
  {
    name: 'Alibaba',
    id: 'socicon-alibaba',
  },
  {
    name: 'Google Calendar',
    id: 'socicon-googlecalendar',
  },
  {
    name: 'Doodle',
    id: 'socicon-doodle',
  },
  {
    name: 'Booking',
    id: 'socicon-booking',
  },
  {
    name: 'Google Maps',
    id: 'socicon-googlemaps',
  },
  {
    name: 'Youtube Gaming',
    id: 'socicon-yt-gaming',
  },
  {
    name: 'Behance',
    id: 'socicon-behance',
  },
  {
    name: 'Blogger',
    id: 'socicon-blogger',
  },
  {
    name: 'RSS',
    id: 'socicon-rss',
  },
  {
    name: 'PayPal',
    id: 'socicon-paypal',
  },
  {
    name: 'Flickr',
    id: 'socicon-flickr',
  },
  {
    name: 'Foursquare',
    id: 'socicon-foursquare',
  },
  {
    name: 'Wikipedia',
    id: 'socicon-wikipedia',
  },
];
