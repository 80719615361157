import React from 'react';
import Select from 'react-select';

import style from './style.module.scss';

const SelectField = (props) => {
  // takes the api response in form of {id, name} and maps it to {value, label} which is needed by react-select.
  // always return an object as {value, label} for the react-select value field.
  const renamedOptions = props.options.map((el) => ({
    value: el.id, label: el.name,
  }));

  // filters for a match of the unique id supplied by the api in options.
  const value = renamedOptions.filter((option) => option.value === props.value || option.label === props.value)[0];

  const colourStyles = {
    option: (styles, { data }) => ({
      ...styles,
      fontFamily: data.font,
      fontSize: data.fontSize,
    }),
  };

  const label = props.label || 'Label';
  const className = `${style.input_wrapper} width_${props.width} ${props.error ? style.error : ''} `;
  const placeholder = props.placeholder || 'Select';

  return (
    <div className={`selectboxWrapper ${className} ${props['data-required'] ? 'required' : ''}`} data-type="select" data-required={props['data-required']}>
      <label htmlFor={props.name}>{label}</label>
      <Select
        name={props.name}
        styles={colourStyles}
        value={props.value ? value : renamedOptions[0]}
        options={renamedOptions}
        placeholder={placeholder}
        classNamePrefix="selectbox"
        onChange={props.onChange}
        isDisabled={props.isDisabled}
      />
      {props.error && <div className={style.error_message}>{props.errorMessage}</div>}
    </div>
  );
};

export default SelectField;
