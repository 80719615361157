import React from 'react';
import style from './style.module.scss';

const Accordion = (props) => (
  <section className={style.accordion}>
    {props.headline
      ? <h2 className="hl_l">{props.headline}</h2>
      : null}
    {props.children}
  </section>
);

export default Accordion;
