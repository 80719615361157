import React, { Component } from 'react';
import { connect } from 'react-unistore';
import ReactGA from 'react-ga';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import actions from '../../actions';
import {
  isCanceled,
  isSubscribed,
} from '../../util/user-management';
import Content from '../../components/Content';
import Search from "../../components/Form/components/Search";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      filteredUsers: this.props.users || [],
    };

    this.setActiveClass = this.setActiveClass.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
  }

  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Users',
    });
  }

  setActiveClass(state, rowInfo) {
    const { search } = this.props.location;
    const query = new URLSearchParams(search);

    const newuser = query.get('newuser');
    const newUsers = newuser
      ? newuser.split(',')
        .map((userId) => parseInt(userId, 10))
      : [];
    if (newUsers.includes(rowInfo.original.id)) {
      return {
        className: 'animated',
      };
    }
    return {};
  }

  updateSearchQuery(query) {
    const filtered = this.props.users.filter((user) => {
      return user.firstname?.toLowerCase().includes(query.toLowerCase()) ||
          user.lastname?.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase());
    });

    this.setState({
        filteredUsers: filtered,
    })
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  toggleDeletePopup(userId) {
    this.setState({ userId });
    this.props.togglePopup();
  }

  async deleteUser() {
    const input = document.getElementById('confirmCheckout');
    const inputVar = input.value;
    input.classList.remove('error');

    // checks whether the input is identical
    if (inputVar === 'delete') {
      input.classList.remove('error');

      await this.props.deleteUser(this.state.userId);
    } else {
      input.classList.add('error');
    }
  }

  render() {
    const subscriptionCanceled = isCanceled();

    const columns = [
      {
        Header: 'User',
        id: 'users',
        accessor: (row) => `${row.firstname} ${row.lastname}`,
        Cell: (props) => (
          <div>
            <div className="left">
              <h6>{props.original.firstname === undefined ? 'Unknown' : `${props.original.firstname} ${props.original.lastname}`}</h6>
              {props.original.roles.includes('ROLE_SUPER_ADMIN') && <p className="label">Admin</p>}
            </div>
            <p className="small">{props.original.email}</p>
          </div>
        ),
        filterable: false,
        sortable: true,
      }, {
        Header: 'Group(s)',
        id: 'Groups',
        accessor: (row) => `${row.firstname} ${row.lastname}`,
        Cell: (props) => {
          const groups = props.original.usergroups.map((item, i, array) => (
            <span key={item.name + i}>
              {item.name}
              {i + 1 < array.length ? ', ' : ''}
            </span>
          ));

          return (
            <div className="cellPulldown">
              {groups.map((group) => group)}
            </div>
          );
        },
        filterable: false,
        sortable: true,
      }, {
        Header: 'Edit',
        id: 'Edit',
        headerClassName: 'center',
        className: 'icons',
        accessor: (row) => `${row.id}`,
        Cell: (props) => (
          <div className="cellPulldown center">
            <Link
              to={`/user/edit/${props.original.id}`}
              onClick={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Edit',
                  label: 'Open Editor',
                });
              }}
            >
              <img src="/icons/edit.svg" />
            </Link>
          </div>
        ),
        filterable: false,
        sortable: false,
        width: 100,

      },
    ];

    return (
      <Content>

        <Content header>
          <h1>Users</h1>
          <Content button>

            <Button
              isDisabled={!isSubscribed()}
              title={!isSubscribed() ? 'You cannot invite users without an active subscription.' : null}
              label="Invite users"
              onClick={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Invite',
                  label: 'Open Editor',
                });
                this.props.history.push('/user/create');
              }}
            />
            <Search placeholder="Search" autocomplete={false} callback={this.updateSearchQuery}
            />

          </Content>
        </Content>

        <ReactTable
          data={this.state.filteredUsers}
          columns={columns}
          defaultPageSize={7}
          PaginationComponent={Pagination}
          minRows={0}
          getTrProps={this.setActiveClass}
        />
      </Content>
    );
  }
}

export default connect('users,showPopup', actions)(Users);
