import React, { Component } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';

export default class PayPalPayment extends Component {
  constructor(props) {
    super(props);

    this.createSubscription = this.createSubscription.bind(this);
    this.updateSubscription = this.updateSubscription.bind(this);
  }

  async createSubscription(data, actions) {
    return actions.subscription.create({
      plan_id: process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_ID,
      quantity: this.props.quantity,
      start_time: this.props.startTime,
    });
  }

  async updateSubscription(data, actions) {
    return actions.subscription.revise(this.props.subscriptionId, {
      quantity: this.props.quantity,
    });
  }

  render() {
    return (
      <PayPalButton
        options={{
          disableFunding: 'credit,card',
          intent: 'subscription',
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          currency: 'EUR',
          vault: true,
        }}
        style={{
          layout: 'horizontal',
          color: 'white',
          shape: 'rect',
          label: 'checkout',
          tagline: 'true',
          size: 'small',
          height: 55,
        }}
        createSubscription={this.props.update ? this.updateSubscription : this.createSubscription}
        onApprove={this.props.onSubmit}
        onError={this.props.handleErrorMessage}
      />
    );
  }
}
