import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';

const CharacterLimitation = ({ value, limit }) => {
  const valueLength = value.length;

  if (valueLength < limit) {
    return null;
  }

  return (
    <p className={style.character_box_warning}>Looks like your button text is a bit too long.</p>
  );
};

CharacterLimitation.propTypes = {
  limit: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default CharacterLimitation;
