import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import { clearError } from '../../../../util/validates';
import CharacterLimitation from './CharacterLimitation';
import BeaconLink from "../../../BeaconLink";

const Input = ({
  width,
  className: classNameFromProps,
  placeholder,
  name,
  value,
  containerStyle,
  label,
  beaconId,
  error,
  isDisabled,
  errorMessage,
  onChange,
  showLengthWarningAtLength,
  ...rest
}) => {
  const className = `${style.input_wrapper} width_${width} ${classNameFromProps || ''} `;
  return (
    <div className={className} style={containerStyle}>
      {label && <label htmlFor={name}>{beaconId ? (<BeaconLink beaconId={beaconId} label={label} />) : label}</label>}
      <div>
        <input
          className={error ? style.error : ''}
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          disabled={isDisabled}
          readOnly={isDisabled}
          onChange={onChange || (() => clearError())}
        />
        <div className={style.error_message}>{error === true ? errorMessage : ''}</div>
        {showLengthWarningAtLength && (<CharacterLimitation value={value} limit={showLengthWarningAtLength} />) }
      </div>
    </div>
  );
};
Input.defaultProps = {
  isDisabled: false,
  beaconId: null,
  errorMessage: null,
  error: null,
  containerStyle: null,
  className: '',
  name: null,
  onChange: null,
  showLengthWarningAtLength: null,
};

Input.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  containerStyle: PropTypes.node,
  label: PropTypes.string.isRequired,
  beaconId: PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.string,
  showLengthWarningAtLength: PropTypes.number,
};

export default Input;
