import React, { Component } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import actions from '../../actions';
import style from './style.module.scss';
import Form from '../../components/Form';
import Button from '../../components/Button';
import Content from '../../components/Content';
import Spinner from '../../components/Spinner';
import { updateBranding } from '../../api/branding';

class Branding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branding: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleImageRemove = this.handleImageRemove.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleBrandingChange = this.handleBrandingChange.bind(this);
    this.editBranding = this.editBranding.bind(this);
    this.getBlob = this.getBlob.bind(this);
  }

  componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Branding',
    });
  }

  static getDerivedStateFromProps(props, state) {
    console.log(state);
    if (state.branding !== null) {
      return null;
    }
    const { branding } = props;
    return {
      branding,
    };
  }

  getBlob() {
    const { branding } = this.state;
    const { preview } = branding;
    const file = preview;

    return fetch(file)
      .then((response) => response.blob()); // returns a Blob
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSelectChange(item, name) {
    const { branding } = this.state;
    const value = item.label;
    this.setState({ branding: { ...branding, [name]: value } });
  }

  handleImageChange(file, name, imageFileName) {
    const { branding } = this.state;
    const reader = new FileReader();
    reader.readAsDataURL(file.data);

    reader.addEventListener('load', () => {
      this.setState({
        branding: {
          ...branding,
          [name]: reader.result,
          [imageFileName]: file.data,
        },
      });
    });
  }

  handleImageRemove(name, imageFileName) {
    const { branding } = this.state;
    this.setState({
      branding: {
        ...branding, [name]: null, [imageFileName]: null, imageFile: null,
      },
    });
  }

  handleBrandingChange(propertyName, event) {
    const { branding } = this.state;
    this.setState({
      branding: {
        ...branding,
        [event.target.name]: [event.target.value],
      },
    });
  }

  handleColorChange(name, color) {
    const { branding } = this.state;
    if (color.hex) {
      this.setState({ branding: { ...branding, [name]: color.hex } });
    } else {
      this.setState({ branding: { ...branding, [name]: color.target.value } });
    }
  }

  async editBranding(e) {
    e.preventDefault();
    const { toggleLoading, refreshAdminData, toggleSnackbar } = this.props;
    const { branding } = this.state;
    const {
      preview,
      previewHeader,
      font,
      fontColor,
      backgroundColor,
      primaryButtonColor,
      primaryTextColor,
      secondaryButtonColor,
      secondaryTextColor,
    } = branding;

    const data = {
      imageFile: preview !== '' ? preview : null,
      headerImageFile: previewHeader !== '' ? previewHeader : null,
      font,
      fontColor,
      backgroundColor,
      primaryButtonColor,
      primaryTextColor,
      secondaryButtonColor,
      secondaryTextColor,
    };

    toggleLoading(true);

    try {
      ReactGA.event({
        category: 'Branding',
        action: 'Update',
        label: 'Save Changes',
      });
      await updateBranding(data);
      // refetch users and groups
      // TODO: only branding refresh is required
      await refreshAdminData();
      toggleLoading(false);
      toggleSnackbar('Sucessfully updated branding.', 'success');
    } catch (err) {
      toggleLoading(false);
      toggleSnackbar(err.message);
    }
  }

  render() {
    const { branding } = this.state;
    const { history } = this.props;

    if (branding === null) {
      return (<Spinner show={branding === null} />);
    }
    return (
      <Content id={style.branding}>
        <h1>Branding</h1>
        {Object.keys(branding).length > 0 && (
          <Form>
            <div className={style.button_wrapper}>
              <h3>Header & Logo</h3>
              <Form.FileUploader
                label="Header image"
                id="brandingHeader"
                onChange={(file) => this.handleImageChange(file, 'previewHeader', 'headerImageFile')}
                handleImageRemove={() => this.handleImageRemove('previewHeader', 'headerImageFile')}
                height={200}
                width={50}
                maxNumberOfFiles={1}
                file={branding.previewHeader}
                imageName={branding.headerImageFileName}
                data={['Recommended format: landscape, max. file size 1 MB, Type: JPG, PNG']}
              />

              <Form.FileUploader
                label="Profile picture/logo"
                id="brandingLogo"
                onChange={(file) => this.handleImageChange(file, 'preview', 'imageFile')}
                handleImageRemove={() => this.handleImageRemove('preview', 'imageFile')}
                height={200}
                width={33}
                maxNumberOfFiles={1}
                file={branding.preview}
                imageName={branding.fileName}
                data={['Recommended format: 1:1, Max. file size 1 MB, Type: JPG, PNG']}
              />
              <div style={{ width: '150px' }} />

              <h3>Fonts & Colors</h3>
              <Form.FontPicker
                label="Font"
                name="font"
                width={100}
                value={branding.font}
                onChange={(item) => this.handleSelectChange(item, 'font')}
              />

              <Form.ColorPicker
                label="Background color"
                color={branding.backgroundColor}
                colorPickerChange={(event) => this.handleColorChange('backgroundColor', event)}
                inputChange={(event) => this.handleBrandingChange('backgroundColor', event)}
                width={100}
                name="backgroundColor"
                popoverPosTop="calc(-100% - -30px)"
              />

              <Form.ColorPicker
                label="Font color"
                color={branding.fontColor}
                colorPickerChange={(event) => this.handleColorChange('fontColor', event)}
                inputChange={(event) => this.handleBrandingChange('fontColor', event)}
                width={100}
                name="fontColor"
                popoverPosTop="calc(-100% - -30px)"
              />
              <div className={`button_wrapper ${style.half}`}>
                <h3>Buttons</h3>

                <Form.ColorPicker
                  label="Button primary color"
                  color={branding.primaryButtonColor}
                  colorPickerChange={(event) => this.handleColorChange('primaryButtonColor', event)}
                  inputChange={(event) => this.handleBrandingChange('primaryButtonColor', event)}
                  width={50}
                  name="primaryButtonColor"
                />

                <Form.ColorPicker
                  label="Text primary color"
                  color={branding.primaryTextColor}
                  colorPickerChange={(event) => this.handleColorChange('primaryTextColor', event)}
                  inputChange={(event) => this.handleBrandingChange('primaryTextColor', event)}
                  width={50}
                  name="primaryTextColor"
                />

                <Form.ColorPicker
                  label="Button secondary color"
                  color={branding.secondaryButtonColor}
                  colorPickerChange={(event) => this.handleColorChange('secondaryButtonColor', event)}
                  inputChange={(event) => this.handleBrandingChange('secondaryButtonColor', event)}
                  width={50}
                  name="secondaryButtonColor"
                />

                <Form.ColorPicker
                  label="Text secondary color"
                  color={branding.secondaryTextColor}
                  colorPickerChange={(event) => this.handleColorChange('secondaryTextColor', event)}
                  inputChange={(event) => this.handleBrandingChange('secondaryTextColor', event)}
                  width={50}
                  name="secondaryTextColor"
                />

              </div>
              <Content button>
                <Button label="Cancel" secondary onClick={() => history.push('/')} />
                <Button label="Save changes" onClick={this.editBranding} />
              </Content>
            </div>

          </Form>
        )}
      </Content>
    );
  }
}

Branding.defaultProps = {
  branding: null,
};

Branding.propTypes = {
  branding: PropTypes.shape({}),
  toggleLoading: PropTypes.func.isRequired,
  toggleSnackbar: PropTypes.func.isRequired,
  refreshAdminData: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect('branding', actions)(Branding);
