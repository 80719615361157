import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import style from './style.module.scss';
import { addDecimalDivider } from './Tooltip';
import BeaconLink from "../BeaconLink";

export default class ListChart extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { data, containerStyle, headline, beaconId } = this.props;

    const columns = [
      {
        Header: 'Source',
        name: 'source',
        id: 'source',
        accessor: (row) => row[0],
        filterable: false,
        Cell: (props) => (
          <div className={style.listChartEntry}>
            <p>{props.original[0] != '' ? props.original[0] : 'direkt' }</p>
          </div>
        ),
        sortable: false,
      },
      {
        Header: 'Clicks',
        name: 'clicks',
        id: 'clicks',
        accessor: (row) => row[1],
        filterable: false,
        Cell: (props) => (
          <div className={style.listChartEntryBold}>
            <p>{`${addDecimalDivider(props.original[1], ',')} Views`}</p>
          </div>
        ),
        sortable: false,
        // headerClassName: 'sort',
        headerStyle: {
          textAlign: 'right',
          paddingRight: 10,
        },
        style: {
          textAlign: 'right',
        },
      },
    ];

    return (
      <div className={style.listChartWrapper} style={containerStyle}>
        <h2 className={style.chartHeadline}>{beaconId ? (<BeaconLink beaconId={beaconId} label={headline} />) : headline}</h2>
        <div className={style.chart}>
          {data !== undefined ? (
            <ReactTable
              data={Object.entries(data)}
              columns={columns}
              defaultPageSize={5}
              showPagination={false}
              minRows={0}
              resizable={false}
            />
          ) : <span>loading...</span> }
        </div>
      </div>
    );
  }
}
