import React from 'react';
import Select from 'react-select';
import style from './style.module.scss';
import { fontOptions } from '../../../../util/fontLibrary';

const FontPicker = (props) => {
  // takes the api response in form of {id, name} and maps it to {value, label} which is needed by react-select.
  // always return an object as {value, label} for the react-select value field.
  const data = fontOptions.map((el) => ({
    label: el.name,
  }));

  const value = data.filter((option) => option.value === props.value || option.label === props.value)[0];

  const label = props.label || 'Label';
  const className = `${style.input_wrapper} width_${props.width} ${props.error ? style.error : ''} `;

  const customStyles = {
    option: (styles, { data }) => ({
      ...styles,
      fontFamily: data.label,
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontFamily: data.label,
    }),
  };

  return (
    <div
      className={`selectboxWrapper ${className} ${props['data-required'] ? 'required' : ''}`}
      data-type="select"
      data-required={props['data-required']}
    >
      <label htmlFor={props.name}>{label}</label>
      <Select
        name={props.name}
        value={props.value ? value : data[0]}
        classNamePrefix="selectbox"
        onChange={props.onChange}
        isDisabled={props.isDisabled}
        defaultValue={data[0].label}
        options={data}
        styles={customStyles}
      />
      {props.error && <div className={style.error_message}>{props.errorMessage}</div>}
    </div>
  );
};

export default FontPicker;
