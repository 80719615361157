export const downloadCsvFile = (blob) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'text/csv' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = `${new Date().toISOString()}_linkhawk_export.csv`;
  link.dispatchEvent(new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window,
  }));
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 0);
};

/**
 * Converts an object containing id and name to an object
 * that is compatible with react-select.
 * Namely { value: id, label: name }.
 *
 * @param { id } id
 * @param { name } name
 */
export const convertToSelectValue = ({ id, name }) => ({ value: id, label: name });

export const paymentMethodIcon = (paymentMethod) => {
  switch (paymentMethod) {
    case 'visa':
      return '/icons/payment-methods/icons/visa.svg';
    case 'mastercard':
      return '/icons/payment-methods/icons/mastercard.svg';
    case 'amex':
      return '/icons/payment-methods/icons/amex.svg';
    case 'discover':
      return '/icons/payment-methods/icons/discover.svg';
    case 'diners':
      return '/icons/payment-methods/icons/diners.svg';
    case 'paypal':
      return '/icons/payment-methods/icons/paypal.svg';
    case 'invoice':
      return '/icons/payment-methods/icons/invoice.svg';
    default:
      return '';
  }
};

export function formatToCurrency(amount) {
  return (amount).toFixed(2);// .replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
