import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import style from './style.module.scss';
import 'react-table/react-table.css';
import Button from '../../components/Button';
import actions from '../../actions';
import Content from '../../components/Content';
import { clearErrors } from '../../util/validates';
import Linkboard from '../../components/Linkboard';
import Phone from '../../components/Phone';
import Grid from '../../components/Grid';
import Copy from '../../components/Copy';
import Pagination from '../../components/Pagination';
import Search from '../../components/Form/components/Search';

class Linkboards extends Component {
  constructor(props) {
    super(props);
    this.state = { queryString: null };
    this.pageChange = this.pageChange.bind(this);
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Linkboards',
    });
    clearErrors();
    await this.props.getLinkboards(0, this.props.linkboards.pageSize);
  }

  updateData(data) {
    this.setState({ ...this.state, ...data });
  }

  pageChange(page) {
    const { queryString } = this.state;
    this.props.getLinkboards(page, this.props.linkboards.pageSize, queryString);
  }

  async updateSearchQuery(query) {
    const { getLinkboards, linkboards } = this.props;
    const { queryString } = this.state;

    if (queryString !== query) {
      this.setState({ queryString: query });
      await getLinkboards(0, linkboards.pageSize, query);
    }
  }

  render() {
    const { queryString } = this.state;
    return (
      <Content>

        {(this.props.linkboards.fetched && !this.props.linkboards.items.length && queryString === null)
        && (
          <Content small center>
            <img
              src={`${process.env.PUBLIC_URL}linkboard_create.png`}
              alt="create a Linkboard"
              style={{ width: '300px' }}
            />
            <h1>Create a Linkboard</h1>
            <p>
              Create a Linkboard to connect your marketing channels and build a home for your
              links.
            </p>
            <Button
              label="Create linkboard"
              onClick={() => {
                ReactGA.event({
                  category: 'Linkboard',
                  action: 'Create',
                  label: 'Open Editor',
                });
                this.props.history.push('/linkboards/create');
              }}
              width={200}
            />
          </Content>
        )}

        {this.props.linkboards.fetched ? (
          <div>
            {this.props.linkboards.items.length > 0 || queryString !== null
              ? (
                <Content header>
                  <h1>Linkboards</h1>
                  <Content button>
                    <Button
                      label="Create new Linkboard"
                      onClick={() => {
                        ReactGA.event({
                          category: 'Linkboard',
                          action: 'Create',
                          label: 'Open Editor',
                        });
                        this.props.history.push('/linkboards/create');
                      }}
                    />
                    <Search
                      placeholder="Search"
                      autocomplete={false}
                      callback={this.updateSearchQuery}
                    />
                  </Content>
                </Content>
              )
              : null}

            {(this.props.linkboards.items.length > 0)
        && (
          <div>

            <Grid>
              {this.props.linkboards.items.map((linkboard) => (
                <div className={style['linkboard-container']} id={linkboard.id}>
                  <Phone>
                    <Linkboard
                      preview
                      showOptions
                      linkboard={linkboard}
                      branding={this.props.branding}
                      linkboardBranding={linkboard.linkboardBranding}
                    />
                  </Phone>

                  <div className={style['info-container']}>
                    <h3>{linkboard.title}</h3>
                    <p>
                      {`Clicks: ${linkboard.hits}`}
                    </p>
                    <div style={{ translate: '22px' }}>
                      <Copy value={linkboard.shortUrl} />
                    </div>
                  </div>
                </div>
              ))}
            </Grid>

            <Pagination
              pages={this.props.linkboards.pages}
              page={this.props.linkboards.currentPage}
              onPageChange={this.pageChange}
              previousText="PREV"
              nextText="NEXT"
            />

          </div>
        )}
            {(this.props.linkboards.items.length === 0 && queryString !== null)
            && (<Content small center><div>No Linkboards found.</div></Content>)}
          </div>

        ) : null}

      </Content>
    );
  }
}

export default connect('linkboards, branding', actions)(Linkboards);
