import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactTable from 'react-table';
import { connect } from 'react-unistore';
import { Link } from 'react-router-dom';
import actions from '../../actions';
import 'react-table/react-table.css';
import Button from '../../components/Button';
import Content from '../../components/Content';
import { getExternalDomains, getDomains, updateAccountDefaultDomain } from '../../api/domains';
import { getAccount } from '../../api/account';
import Spinner from '../../components/Spinner';
import Form from '../../components/Form';

class Domains extends Component {
  constructor(props) {
    super(props);
    this.state = { externalDomains: null, loading: false, defaultDomainSelection: null };

    this.updateDefaultDomain = this.updateDefaultDomain.bind(this);
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Domains',
    });

    const domains = await getDomains();
    const account = await getAccount();
    const externalDomains = await getExternalDomains();
    this.setState({
      externalDomains,
      domains,
      defaultDomainSelection: account.defaultDomain !== null
        ? account.defaultDomain.id
        : domains[0].id,
    });
  }

  async updateDefaultDomain() {
    const { toggleSnackbar, refreshUserData } = this.props;
    const { defaultDomainSelection } = this.state;
    this.setState({ loading: true });

    try {
      await updateAccountDefaultDomain(defaultDomainSelection);
      await refreshUserData();
      toggleSnackbar('Default domain updated', true);
    } catch (e) {
      toggleSnackbar(e.message);
    }
    this.setState({ loading: false });
  }

  render() {
    const { history } = this.props;
    const {
      externalDomains, domains, loading, defaultDomainSelection,
    } = this.state;

    if (externalDomains === null || loading === true) {
      return (<Spinner show />);
    }

    const getStatus = (status) => {
      // eslint-disable-next-line default-case
      switch (status) {
        case 'AVAILABLE':
          return (
            <>
              <span style={{ color: 'green' }}>•</span>
              {' '}
              available
            </>
          );
        case 'PENDING_VERIFICATION':
          return (
            <>
              <span style={{ color: 'red' }}>•</span>
              {' '}
              update nameservers!
            </>
          );
        case 'PENDING_ACTIVATION':
          return (
            <>
              <span style={{ color: 'yellow' }}>•</span>
              {' '}
              activation in progress
            </>
          );
      }
    };

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        filterable: false,
        sortable: true,
        headerClassName: 'sort',
        Cell: (props) => (
          <Link
            to={`/domains/details/${props.original.id}`}
            onClick={() => {
              ReactGA.event({
                category: 'Domain',
                action: 'Details',
                label: 'Open Editor',
              });
            }}
          >
            {props.original.name}
          </Link>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        filterable: false,
        sortable: true,
        Cell: (props) => <p>{getStatus(props.original.status)}</p>,
        headerClassName: 'sort',
      }, {
        Header: 'Details',
        id: 'details',
        headerClassName: 'center',
        className: 'icons',
        accessor: (row) => `${row.id}`,
        Cell: (props) => (
          <div className="center">
            <Link
              to={`/domains/details/${props.original.id}`}
              onClick={() => {
                ReactGA.event({
                  category: 'Domain',
                  action: 'Details',
                  label: 'Open Editor',
                });
              }}
            >
              <img alt="edit group" src="/icons/edit.svg" />
            </Link>
          </div>
        ),
        filterable: false,
        sortable: false,
        width: 100,

      },
    ];

    return (
      <Content>

        <h1>Domains</h1>
        <div style={{
          backgroundColor: '#EFEFEF',
          width: '500px',
          borderRadius: '6px',
          padding: '24px 20px',
        }}
        >
          <h2 style={{ paddingTop: '24px' }}>Default Domain</h2>
          <p>Please select the default domain for your account.</p>
          <Form>
            <Form.SelectField
              label="Default Domain"
              name="default_domain"
              width={100}
              options={domains}
              onChange={(option) => this.setState({ defaultDomainSelection: option.value })}
              value={defaultDomainSelection}
            />

          </Form>
          <Content button>
            <Button
              label="Save"
              onClick={this.updateDefaultDomain}
            />
          </Content>
        </div>
        <h2>Custom Domains</h2>
        <p>
          {`Here you can add domains you registered for use with linkhawk.
          You can only use domains registered for this specific purpose as
          you will be required to point the domain's nameservers to linkhawk.com.`}
        </p>
        <ReactTable
          data={externalDomains}
          columns={columns}
          defaultSorted={[
            {
              id: 'createdAt',
              desc: false,
            },
          ]}
          defaultPageSize={7}
          showPagination={false}
          minRows={0}
          resizable={false}
          getTrProps={this.setActiveClass}
        />

        <Content button>
          <Button
            label="Add domain"
            onClick={() => {
              ReactGA.event({
                category: 'Domain',
                action: 'Add',
                label: 'Open Editor',
              });
              history.push('/domains/add');
            }}
          />
        </Content>
      </Content>
    );
  }
}

Domains.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({}).isRequired,
  toggleSnackbar: PropTypes.func.isRequired,
  refreshUserData: PropTypes.func.isRequired,
};

export default connect(null, actions)(Domains);
