import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import actions from '../../../actions';
import 'react-table/react-table.css';
import { addDomain } from '../../../api/domains';
import Spinner from '../../../components/Spinner';
import Content from '../../../components/Content';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import MultiSteps from '../../../components/MultiSteps';
import { LinkhawkServiceEntityConflict } from '../../../api/common';

class AddDomain extends Component {
  constructor(props) {
    super(props);
    this.state = { domainName: '', loading: false, error: null };

    this.add = this.add.bind(this);
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Domains',
    });
  }

  async add() {
    // eslint-disable-next-line func-names
    const validateDomain = function (domain) {
      // eslint-disable-next-line no-useless-escape
      const re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
      return domain.match(re);
    };
    const { domainName } = this.state;
    const { toggleSnackbar, history } = this.props;

    if (!validateDomain(domainName)) {
      this.setState({ error: 'Not a valid domain.' });
      return;
    }

    this.setState({ loading: true });
    try {
      const domain = await addDomain(domainName);
      history.push(`/domains/details/${domain.id}`);
    } catch (e) {
      if (e instanceof LinkhawkServiceEntityConflict) {
        toggleSnackbar('Domain has already been added to linkhawk.');
      } else {
        toggleSnackbar(e.message);
      }
    }
    this.setState({ loading: false });
  }

  render() {
    const { history } = this.props;
    const { loading, error } = this.state;

    if (loading) {
      return (<Spinner show />);
    }

    return (
      <Content>

        <MultiSteps
          description={['Add', 'DNS', 'Submit', 'Activation', 'Ready']}
          totalSteps={5}
          currentStep={1}
          disabledSteps={[2, 3, 4, 5]}
        />
        <h1>Add Domain</h1>

        <Content button style={{ display: 'inline-block' }}>

          <Form>
            <Form.Input
              label="Domain Name"
              name="domainName"
              placeholder="mydomain.com"
              width={100}
              error={error !== null}
              errorMessage={error}
              onChange={(e) => { this.setState({ domainName: e.target.value }); }}
            />

          </Form>
          <Content button>
            <Button
              label="Cancel"
              secondary
              onClick={() => {
                history.push('/domains');
              }}
            />
            <Button
              label="Add"

              onClick={() => {
                this.add();
              }}
            />
          </Content>
        </Content>
      </Content>
    );
  }
}

AddDomain.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  toggleSnackbar: PropTypes.func.isRequired,
};

export default connect(null, actions)(AddDomain);
