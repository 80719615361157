import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import style from './style.module.scss';
import Step1 from './Step1';
import Step2 from './Step2';
import actions from '../../../actions';
import Content from '../../../components/Content';
import MultiSteps from '../../../components/MultiSteps';
import { getLatestPayment } from '../../../util/user-management';
import { createUsers } from '../../../api/users';
import { INVOICE } from '../../../util/constants';

class UserCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      disabledSteps: [2],
      users: [
        {
          userId: 1,
          firstname: '',
          lastname: '',
          email: '',
          role: 'ROLE_USER',
          usergroups: [],
        },
      ],
      defaultGroupId: null,
      quantity: 1,
      inactiveSubmit: true,
    };

    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.setStep = this.setStep.bind(this);
    this.handleDisabledSteps = this.handleDisabledSteps.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleErrorMessage = this.handleErrorMessage.bind(this);
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'User Create',
    });
    const defaultGroupId = this.props.groups[0].id;
    const users = this.state.users.map((user) => ({ ...user, usergroups: [defaultGroupId] }));
    this.setState({ users, defaultGroupId });
  }

  setStep(step) {
    this.setState({ currentStep: step });
  }

  handleDisabledSteps(disabledSteps) {
    this.setState({ disabledSteps });
  }

  async handleSubmit() {
    const {
      history, toggleLoading, refreshAdminData, toggleSnackbar,
    } = this.props;

    const { users } = this.state;
    toggleLoading(true);
    try {
      const json = await createUsers(JSON.stringify({ users }));
      // refetch users and groups
      await refreshAdminData();
      toggleLoading(false);
      history.push(`/user?newuser=${json.ids.join(',')}`);
    } catch (err) {
      toggleLoading(false);
      toggleSnackbar(err.message);
    }
  }

  handleErrorMessage() {
    this.props.toggleSnackbar('PayPal is currently processing your payment. Please try again later.');
  }

  previousStep() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  nextStep() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  updateData(data) {
    this.setState({ ...this.state, ...data });
  }

  render() {
    const {
      users, defaultGroupId, currentStep, quantity, disabledSteps,
    } = this.state;
    const payment = getLatestPayment();
    const { history } = this.props;
    const getCurrentStep = (step) => this.setState({ currentStep: step });

    const getSteps = function () {
      if (payment.provider === INVOICE) {
        return ['Create users', 'Overview'];
      }
      return ['Create users', 'Payment'];
    };

    return (
      <Content>

        <MultiSteps
          description={getSteps()}
          totalSteps={2}
          currentStep={currentStep}
          disabledSteps={disabledSteps}
          getCurrentStep={getCurrentStep}
        />

        <div className={style.steps_wrapper}>
          {currentStep === 1
            && (
            <Step1
              users={users}
              defaultGroupId={defaultGroupId}
              quantity={quantity}
              nextStep={this.nextStep}
              updateData={this.updateData}
              handleDisabledSteps={this.handleDisabledSteps}
              history={history}
            />
            )}

          {currentStep === 2
            && (
            <Step2
              quantity={quantity}
              handleSubmit={this.handleSubmit}
              handleErrorMessage={this.handleErrorMessage}
              prevStep={this.previousStep}
              updateData={this.updateData}
            />
            )}
        </div>
      </Content>
    );
  }
}

export default connect('groups', actions)(UserCreate);
