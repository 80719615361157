import { get, post, del } from './common';

export const getUser = async () => get('/api/user');

export const getUsers = async (id = null) => get(`/api/users${id ? `/${id}` : ''}`);

export const updateUser = async (id, payload) => post(`/api/users/${id}`, 'POST', payload);

export const deleteUser = async (id) => del(`/api/users/${id}`);

export const changePassword = async (payload) => post('/api/user/change-password', 'POST', payload);

export const createUsers = async (payload) => post('/api/users', 'POST', payload);

export const setPassword = async (confirmationToken, newPassword, newPasswordValidation) => post('/api/user/set-password', 'POST', JSON.stringify({
  confirmationToken, newPassword, newPasswordValidation,
}), true);

export const resetPassword = async (email) => post('/api/user/request-password-reset', 'POST', JSON.stringify({
  email,
}), true);
