/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momenttz from 'moment-timezone';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import style from '../style.module.scss';
import Filter from '../Filter';

export default class DateFilter extends Component {
  constructor(props) {
    super(props);
    const { dateStart, dateEnd, selectedRange } = this.props;
    this.state = {
      showsOverlay: false,
      dateStart,
      dateEnd,
      selectedRange,
      currentlyDisplayedMonth: moment().toDate(),
      to: moment(dateEnd).startOf('day').add(12, 'hours').toDate(),
      from: moment(dateStart).startOf('day').add(12, 'hours').toDate(),
      enteredTo: moment(dateEnd).startOf('day').add(12, 'hours').toDate(),
    };

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.applyRange = this.applyRange.bind(this);
    this.renderNavbar = this.renderNavbar.bind(this);
    this.renderTagButtons = this.renderTagButtons.bind(this);

    this.calendarWrapper = React.createRef();
    this.openOverlayBtnWrapper = React.createRef();
  }

  getInitialState(day) {
    return {
      selectedRange: null,
      from: day || null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.
    };
  }

  static getDerivedStateFromProps(props, state) {
    console.log(state);
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  handleDayClick(day) {
    const { from, to } = this.state;
    if (from && to /* && day >= from && day <= to */) {
      this.handleResetClick(day);
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day,
      });
      this.props.callback({ from, to: day });
    }
  }

  handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }

  handleResetClick(day) {
    this.setState(this.getInitialState(day));
  }

  applyRange({ name, from, to }) {
    this.setState({
      from, to, enteredTo: to, selectedRange: name, currentlyDisplayedMonth: moment().toDate(),
    });

    this.props.callback({ from, to });
  }

  openCalendar() {
    const { showsOverlay } = this.state;
    const calendar = this.calendarWrapper.current;
    const target = this.openOverlayBtnWrapper.current.getBoundingClientRect();

    if (window.innerWidth > 1300) {
      // center
      calendar.style.left = `${target.x - ((390 + 14 - target.width) / 2)}px`;
    } else {
      // right align
      calendar.style.left = `${target.x - 150}px`;
    }
    calendar.style.top = `${target.y + target.height - 28}px`;

    this.setState({ showsOverlay: !showsOverlay });
  }

  renderTagButtons() {
    const options = [
      {
        description: '7d',
        from: moment().startOf('day').add(12, 'hours').subtract(7, 'days')
          .toDate(),
        to: moment().startOf('day').subtract(12, 'hours').toDate(),
      },
      {
        description: '14d',
        from: moment().startOf('day').add(12, 'hours').subtract(14, 'days')
          .toDate(),
        to: moment().startOf('day').subtract(12, 'hours').toDate(),
      },
      {
        description: '30d',
        from: moment().startOf('day').add(12, 'hours').subtract(30, 'days')
          .toDate(),
        to: moment().startOf('day').subtract(12, 'hours').toDate(),
      },
      {
        description: '6m',
        from: moment().startOf('day').add(12, 'hours').subtract(6, 'month')
          .toDate(),
        to: moment().startOf('day').subtract(12, 'hours').toDate(),
      },
    ];

    const buttons = options.map((option) => (
      <button
        type="button"

        className={option.description === this.state.selectedRange ? style.calendarRangePresetsButtonActive : ''}
        onClick={(e) => {
          e.preventDefault();
          this.applyRange(
            { name: option.description, from: option.from, to: option.to },
          );
        }}
      >
        {option.description}
      </button>
    ));

    return (
      <div className={style.calendarRangePresets}>
        {buttons}
      </div>
    );
  }

  renderNavbar({
    month, previousMonth, nextMonth, onPreviousClick, onNextClick,
  }) {
    return (
      <div style={{ paddingLeft: '6px', paddingRight: '6px', paddingBottom: '12px' }}>
        <div style={{ paddingTop: '20px' }}>
          <div className={style.calendarNavBar}>
            <div className="column">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ currentlyDisplayedMonth: previousMonth });
                  onPreviousClick();
                }}
                style={{ textAlign: 'left' }}
              >
                <img
                  alt="previous month"
                  src="/icons/arrow-calendar.svg"
                  style={{ textAlign: 'left' }}
                />
              </button>
            </div>
            <div
              className="column"
              style={{ marginTop: '5px' }}
            >
              {moment(month).format('MMMM YYYY')}
            </div>
            <div className="column">
              { moment(month).endOf('month').isBefore(moment()) ? (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ currentlyDisplayedMonth: nextMonth });
                    onNextClick();
                  }}
                  style={{ textAlign: 'right' }}
                >
                  <img
                    alt="next month"
                    src="/icons/arrow-calendar.svg"
                    style={{ transform: 'rotate(180deg)' }}
                  />
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {this.renderTagButtons()}
      </div>
    );
  }

  render() {
    const {
      showsOverlay, dateStart, dateEnd, from, to, enteredTo, currentlyDisplayedMonth,
    } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = { before: this.state.from };
    const selectedDays = [from, { from, to: enteredTo }];

    function renderCaption() {
      return (
        <div />
      );
    }

    function overlayButtonLabel() {
      if (to === null || from === null) {
        return 'Pick Time Frame';
      }
      return `${moment(from).format('DD.MM.Y')} - ${moment(to).format('DD.MM.Y')}`;
    }

    return (

      <div
        ref={this.openOverlayBtnWrapper}
        className={style.dateFilter}
      >
        <Filter
          id="openFilterOverlayBtn"
          label={overlayButtonLabel()}
          onClick={() => this.openCalendar()}
          showsOverlay={showsOverlay}
        />
        <div
          ref={this.calendarWrapper}
          id="calendarWrapper"
          className={!showsOverlay ? style.calendarWrapper : [style.calendarWrapper, 'active'].join(' ')}
        >
          {
            showsOverlay
              ? (
                <div>
                  <button
                    type="button"
                    onClick={() => { this.setState({ showsOverlay: false }); }}
                    className={style.calendarCloseBtn}
                  />
                  <DayPicker
                  // containerProps={{ tabIndex: -1 }}
                    disabledDays={{
                      before: disabledDays.before,
                      after: moment.utc().toDate(),

                    }}
                    month={currentlyDisplayedMonth}
                    className="Range"
                    numberOfMonths={1}
                    initialMonth={currentlyDisplayedMonth}
                    selectedDays={selectedDays}
                    captionElement={renderCaption}
                    navbarElement={this.renderNavbar}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                    onDayMouseEnter={this.handleDayMouseEnter}
                  />
                </div>

              ) : null
          }
        </div>
        <div style={{
          textAlign: 'right', color: 'grey', paddingTop: 8, fontSize: '16px',
        }}
        >
          {momenttz.tz.guess()}
        </div>
      </div>
    );
  }
}

DateFilter.propTypes = {
  callback: PropTypes.func.isRequired,
  dateStart: PropTypes.string.isRequired,
  dateEnd: PropTypes.string.isRequired,
};
