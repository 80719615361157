import moment from 'moment-timezone';
import { get, post } from '../api/common';
import { getLinks as getLink } from '../api/links';

export const getLinks = async (store, state, page, limit, query = null, sorted = []) => {
  store.setState({ loading: true });

  const searchQuery = query !== null ? `&search=${query}` : '';

  const sortParam = sorted.map(sort => `${sort.id}`).join(',');
  const sortQuery = sortParam ? `&sort=${sortParam}` : '';

  const sortDirectionParam = sorted.length > 0 ? sorted[0].desc ? 'desc' : 'asc' : '';
  const sortDirectionQuery = sortDirectionParam ? `&sortDirection=${sortDirectionParam}` : '';

  const res = await get(`/api/links?page=${page}&limit=${limit}${searchQuery}${sortQuery}${sortDirectionQuery}&timezone=${moment.tz.guess()}`, null, false);
  const total = res.headers.get('X-Total-Count');
  const items = await res.json();

  return {
    links: {
      ...state.links,
      items,
      currentPage: page,
      pages: Math.ceil(total / limit),
      fetched: true,
    },
    loading: false,
  };
};

export const createLink = async (store, state, payload) => {
  const json = await post('/api/links', 'POST', payload);

  const { id } = json;
  const link = await getLink(id);

  store.setState({
    link,
    error: false,
  });
};
