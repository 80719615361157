import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import AccordionItem from '../../../../Accordion/Item';
import {
  propertyHasValidationErrors,
  propertiesHaveValidationErrors,
} from '../../../../../actions/linkboardEditor';
import Form from '../../../../Form';
import Content from '../../../../Content';
import { socialIconOptions } from '../../../../../util/socialIconLibrary';

class ButtonItem extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { updateLinkboardItemProperty, item } = this.props;
    updateLinkboardItemProperty('buttons', item.id, event.target.name, event.target.value);
  }

  render() {
    const {
      item: button,
      expanded,
      toggleDisplayState,
      positionInList,
      validationData,
    } = this.props;

    const {
      id,
      text,
      color: selectedColor,
      backgroundColor: selectedBackgroundColor,
      targetUrl,
      iconType,
      iconFile,
    } = button;

    return (

      <AccordionItem
        type="checkbox"
        box
        checked={expanded}
        onClick={() => toggleDisplayState()}
        headline={`${positionInList}. Button`}
        id={`button-${id}`}
        error={propertiesHaveValidationErrors(
          ['text', 'color', 'backgroundColor', 'targetUrl', 'iconType', 'iconFile'],
          validationData,
        )}
      >

        <div>
          <Form>
            <Form.Input
              type="text"
              label="Button text"
              width={100}
              placeholder="Example"
              name="text"
              value={text}
              showLengthWarningAtLength={20}
              onInput={this.onChange}
              error={propertyHasValidationErrors('text', validationData)}
              errorMessage={propertyHasValidationErrors('text', validationData) ? validationData.text.message : null}
            />

            <Form.ColorPicker
              label="Text color"
              placeholder="Choose text color"
              color={selectedColor}
              colorPickerChange={(color) => this.onChange({ target: { name: 'color', value: color.hex } })}
              inputChange={this.onChange}
              width={50}
              name="color"
              error={propertyHasValidationErrors('color', validationData)}
              errorMessage={propertyHasValidationErrors('color', validationData) ? validationData.color.message : null}
            />

            <Form.ColorPicker
              label="Button color"
              placeholder="Choose button color"
              color={selectedBackgroundColor}
              colorPickerChange={(color) => this.onChange({ target: { name: 'backgroundColor', value: color.hex } })}
              inputChange={this.onChange}
              width={50}
              name="backgroundColor"
              error={propertyHasValidationErrors('backgroundColor', validationData)}
              errorMessage={propertyHasValidationErrors('backgroundColor', validationData) ? validationData.backgroundColor.message : null}
            />

            <Form.Input
              data-required
              label="Button target URL*"
              type="text"
              data-type="url"
              width={100}
              placeholder="https://example.com"
              name="targetUrl"
              value={targetUrl}
              onInput={this.onChange}
              error={propertyHasValidationErrors('targetUrl', validationData)}
              errorMessage={propertyHasValidationErrors('targetUrl', validationData) ? validationData.targetUrl.message : null}
            />

            <div className={style['buttons-icon-container']}>
              <h5>Do you want to add an icon on your button?</h5>
              <Content bar>
                <Form.Checkbox
                  name={`toggleIcon${id}`}
                  id={`icon${id}`}
                  label="Yes, add an icon"
                  width={33}
                  type="radio"
                  onClick={() => this.onChange({ target: { name: 'iconType', value: '' } })}
                  checked={iconType !== 'no-icon'}
                  containerStyle={{}}
                />

                <Form.Checkbox
                  name={`toggleIcon${id}`}
                  id={`NoIcon${id}`}
                  label="No, just text"
                  width={66}
                  type="radio"
                  onClick={() => this.onChange({ target: { name: 'iconType', value: 'no-icon' } })}
                  checked={iconType === 'no-icon'}
                  containerStyle={{}}
                />
              </Content>

              {iconType !== 'no-icon' && (
              <div className={style['buttons-image-container']}>

                <Form.FileUploader
                  label="Image upload"
                  id={`buttonIcon${id}`}
                  onChange={(file) => {
                    this.onChange({ target: { name: 'iconType', value: 'custom' } });
                    this.onChange({ target: { name: 'iconFile', value: file } });
                  }}
                  handleImageRemove={() => {
                    this.onChange({ target: { name: 'iconType', value: 'no-icon' } });
                    this.onChange({ target: { name: 'iconFile', value: null } });
                  }}
                  height={100}
                  width={50}
                  maxNumberOfFiles={1}
                  file={iconFile.url}
                  imageName={iconFile.name}
                />

                <div className={style['vertical-separator']}>
                  <p>or</p>
                </div>

                <Form.SelectField
                  label="Icon"
                  name="iconType"
                  width={50}
                  options={socialIconOptions}
                  value={iconType}
                  isDisabled={iconType === 'custom'}
                  onChange={(option) => this.onChange({ target: { name: 'iconType', value: option.value } })}
                  placeholder="Choose"
                />
              </div>
              )}

            </div>
          </Form>
        </div>
      </AccordionItem>

    );
  }
}

ButtonItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    targetUrl: PropTypes.string,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleDisplayState: PropTypes.func.isRequired,
  positionInList: PropTypes.number.isRequired,
  updateLinkboardItemProperty: PropTypes.func.isRequired,
  validationData: PropTypes.shape({}).isRequired,
};

export default ButtonItem;
