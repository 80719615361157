import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import linkboardActions, {
  propertyHasValidationErrors,
  propertiesHaveValidationErrors,
} from '../../../../actions/linkboardEditor';
import AccordionItem from '../../../Accordion/Item';
import Form from '../../../Form';

class HeadSection extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { updateLinkboardProperty } = this.props;
    updateLinkboardProperty(event.target.name, event.target.value);
  }

  render() {
    const {
      headline,
      description,
      expandedSection,
      toggleSection,
      validationData,
    } = this.props;

    const SECTION_NAME = 'headline-description';
    return (
      <AccordionItem
        type="checkbox"
        checked={expandedSection === SECTION_NAME}
        onClick={() => toggleSection(SECTION_NAME)}
        headline="Headline & Description"
        id="text"
        error={propertiesHaveValidationErrors(['headline', 'description'], validationData)}
      >
        <Form>
          <Form.Input
            label="Headline"
            placeholder="Headline of your Linkboard"
            width={100}
            name="headline"
            value={headline}
            onInput={this.onChange}
            error={propertyHasValidationErrors('headline', validationData)}
            errorMessage={propertyHasValidationErrors('headline', validationData) ? validationData.headline.message : null}
          />

          <Form.Input
            label="Description"
            placeholder="Describe your Linkboard"
            width={100}
            name="description"
            value={description}
            onInput={this.onChange}
            error={propertyHasValidationErrors('description', validationData)}
            errorMessage={propertyHasValidationErrors('description', validationData) ? validationData.description.message : null}
          />
        </Form>
      </AccordionItem>
    );
  }
}

HeadSection.propTypes = {
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  updateLinkboardProperty: PropTypes.func.isRequired,
  toggleSection: PropTypes.func.isRequired,
  expandedSection: PropTypes.string.isRequired,
  validationData: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
  const {
    linkboardEditor,
  } = state;
  return {
    headline: linkboardEditor.workingCopy.headline,
    description: linkboardEditor.workingCopy.description,
    expandedSection: linkboardEditor.config.expandedSection,
    validationData: linkboardEditor.validation,
  };
}

export default connect(mapStateToProps, (store) => ({
  updateLinkboardProperty:
     async (...args) => linkboardActions.updateLinkboardProperty(store, ...args),
  toggleSection: async (...args) => linkboardActions.toggleSection(store, ...args),
}))(HeadSection);
