import React from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import style from '../style.module.scss';

const DayPicker = ({
  width,
  className: classNameFromProps,
  placeholder,
  name,
  value,
  containerStyle,
  label,
  error,
  isDisabled,
  errorMessage,
  onChange,
}) => {
  const className = `${style.input_wrapper} width_${width} ${classNameFromProps || ''} `;
  const FORMAT = 'YYYY-MM-DD';
  function formatDate(date, format, locale) {
    return moment(date).format(format);
  }
  return (
    <div className={className} style={containerStyle}>
      {label && <label htmlFor={name}>{label}</label>}
      <div>
        <DayPickerInput
          formatDate={formatDate}
          name={name}
          placeholder={placeholder}
          format={FORMAT}
          value={value}
          onDayChange={onChange}
          disabled={isDisabled}
          readOnly={isDisabled}
        />
        <div className={style.error_message}>{error === true ? errorMessage : ''}</div>
      </div>
    </div>
  );
};
DayPicker.defaultProps = {
  isDisabled: false,
  errorMessage: null,
  error: null,
  containerStyle: null,
  className: '',
  name: null,
  onChange: null,
};

DayPicker.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  containerStyle: PropTypes.node,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.string,
};

export default DayPicker;
