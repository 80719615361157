import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import style from './style.module.scss';
import linkboardActions, {
  propertyHasValidationErrors,
  propertiesHaveValidationErrors,
} from '../../../../actions/linkboardEditor';
import Content from '../../../Content';
import AccordionItem from '../../../Accordion/Item';
import * as user from '../../../../util/user-management';
import Form from '../../../Form';
import BeaconLink from "../../../BeaconLink";

class BrandingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerImageCache: null,
      logoImageCache: null,
    };
    this.onChange = this.onChange.bind(this);
    this.setUseHeaderImage = this.setUseHeaderImage.bind(this);
    this.setUseLogoImage = this.setUseLogoImage.bind(this);
  }

  onChange(event) {
    const { updateLinkboardProperty } = this.props;
    updateLinkboardProperty(event.target.name, event.target.value);
  }

  setUseHeaderImage(bool) {
    const { headerFile, updateConfigProperty } = this.props;
    const { headerImageCache } = this.state;
    if (bool) {
      this.onChange({
        target: {
          name: 'headerFile',
          value: { restoreCache: true, ...headerImageCache },
        },
      });
    } else {
      this.setState({ headerImageCache: headerFile });
      this.onChange({
        target: {
          name: 'headerFile',
          value: null
        }
      });
    }
    updateConfigProperty('useHeaderImage', bool);
  }

  setUseLogoImage(bool) {
    const { logoFile, updateConfigProperty } = this.props;
    const { logoImageCache } = this.state;
    if (bool) {
      this.onChange({
        target: {
          name: 'logoFile',
          value: { restoreCache: true, ...logoImageCache },
        },
      });
    } else {
      this.setState({ logoImageCache: logoFile });
      this.onChange({
        target: {
          name: 'logoFile',
          value: null
        }
      });
    }
    updateConfigProperty('useLogoImage', bool);
  }

  render() {
    const {
      font,
      fontColor,
      backgroundColor,
      expandedSection,
      toggleSection,
      headerFile,
      logoFile,
      overrideGlobalBrandingOnSave,
      toggleOverrideGlobalBrandingOnSave,
      useLogoImage, useHeaderImage,
      validationData,
    } = this.props;

    const errorMessage = (message) => (
      <div
        className={style.error_message}
      >
        {message}
        <img src="/icons/warning.svg" style={{
          marginLeft: '6px',
          width: '15px',
          height: '21px'
        }} alt=""/>
      </div>
    );

    const SECTION_NAME = 'branding';
    return (
      <AccordionItem
        type="checkbox"
        checked={expandedSection === SECTION_NAME}
        onClick={() => toggleSection(SECTION_NAME)}
        headline="Branding"
        id="branding"
        error={
          propertiesHaveValidationErrors(
            ['font', 'fontColor', 'backgroundColor', 'logoFile', 'headerFile'], validationData,
          )
        }
      >
        <Form>
          <BeaconLink beaconId={137} type="button--small" label="How to add a branding to your Linkboard?" />
          <h6 style={{
            marginBottom: '6px',
            marginTop: '12px',
            marginLeft: '0',
          }}>Do you want to add an header image to your linkboard?
          </h6>
          <Content
            bar
            style={{
              padding: 0,
              marginBottom: '25px',
            }}
          >
            <Form.Checkbox
              name="header-image-toggle-0"
              id="header-image-toggle-0"
              label="Yes, add Image"
              width={33}
              type="radio"
              onClick={() => this.setUseHeaderImage(true)}
              checked={useHeaderImage === true}
              containerStyle={{}}
            />

            <Form.Checkbox
              name="header-image-toggle-1"
              id="header-image-toggle-1"
              label="No"
              width={66}
              type="radio"
              onClick={() => this.setUseHeaderImage(false)}
              checked={useHeaderImage === false}
              containerStyle={{}}
            />
            {useHeaderImage === true && propertyHasValidationErrors('headerFile', validationData)
              ? errorMessage(validationData.headerFile.message)
              : null}
          </Content>

          {useHeaderImage === true ? (
            <Form.FileUploader
              label="Header image"
              id="image"
              onChange={(file) => this.onChange({
                target: {
                  name: 'headerFile',
                  value: file
                }
              })}
              handleImageRemove={() => this.onChange({
                target: {
                  name: 'headerFile',
                  value: null
                }
              })}
              height={100}
              width={50}
              maxNumberOfFiles={1}
              file={headerFile.url}
              imageName={headerFile.name}
              data={['Recommended format: landscape, max. file size 1 MB, Type: JPG, PNG']}
            />
          ) : null}

          <h6 style={{
            marginBottom: '6px',
            marginTop: '12px'
          }}>
            Do you want to add an profile picture or logo to your linkboard?
          </h6>
          <Content
            bar
            style={{
              padding: 0,
              marginBottom: '20px',
            }}
          >
            <Form.Checkbox
              name="logo-image-toggle-0"
              id="logo-image-toggle-0"
              label="Yes, add logo"
              width={33}
              type="radio"
              onClick={() => this.setUseLogoImage(true)}
              checked={useLogoImage === true}
              containerStyle={{}}
            />
            <Form.Checkbox
              name="logo-image-toggle-1"
              id="logo-image-toggle-1"
              label="No"
              width={66}
              type="radio"
              onClick={() => this.setUseLogoImage(false)}
              checked={useLogoImage === false}
              containerStyle={{}}
            />
            {useLogoImage === true && propertyHasValidationErrors('logoFile', validationData)
              ? errorMessage(validationData.logoFile.message)
              : null}
          </Content>
          {useLogoImage === true ? (
            <Form.FileUploader
              label="Profile picture/logo"
              id="logo"
              onChange={(file) => this.onChange({
                target: {
                  name: 'logoFile',
                  value: file
                }
              })}
              handleImageRemove={() => this.onChange({
                target: {
                  name: 'logoFile',
                  value: null
                }
              })}
              height={100}
              width={50}
              maxNumberOfFiles={1}
              file={logoFile.url}
              imageName={logoFile.name}
              data={['Recommended format: 1:1, Max. file size 1 MB, Type: JPG, PNG']}
            />
          ) : null}

          <Form.ColorPicker
            label="Background color"
            placeholder="Choose"
            color={backgroundColor}
            colorPickerChange={(color) => this.onChange({
              target: {
                name: 'backgroundColor',
                value: color.hex
              }
            })}
            inputChange={this.onChange}
            width={100}
            name="backgroundColor"
          />

          <Form.FontPicker
            label="Font"
            name="font"
            width={50}
            value={font}
            onChange={(item) => this.onChange({
              target: {
                name: 'font',
                value: item.label
              }
            })}
          />

          <Form.ColorPicker
            label="Font color"
            placeholder="Choose"
            color={fontColor}
            colorPickerChange={(color) => this.onChange({
              target: {
                name: 'fontColor',
                value: color.hex
              }
            })}
            inputChange={this.onChange}
            width={50}
            name="fontColor"
          />

          {user.isAdmin() ? (
            <Form.Checkbox
              type="checkbox"
              label="Use these settings as default for your branding"
              checked={overrideGlobalBrandingOnSave}
              onClick={() => toggleOverrideGlobalBrandingOnSave()}
            >
              <span>
                <img src="/icons/info.svg" alt=""/>
                <p className="tooltip">
                  Set profile picture/logo, background color, font
                  <br/>
                  {' '}
                  and font color as your default branding.
                </p>
              </span>
            </Form.Checkbox>
          ) : null}

        </Form>
      </AccordionItem>
    );
  }
}

BrandingSection.propTypes = {
  font: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  updateLinkboardProperty: PropTypes.func.isRequired,
  toggleSection: PropTypes.func.isRequired,
  expandedSection: PropTypes.string.isRequired,
  toggleOverrideGlobalBrandingOnSave: PropTypes.func.isRequired,
  updateConfigProperty: PropTypes.func.isRequired,
  useHeaderImage: PropTypes.bool.isRequired,
  useLogoImage: PropTypes.bool.isRequired,
  overrideGlobalBrandingOnSave: PropTypes.bool.isRequired,
  headerFile: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  logoFile: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  validationData: PropTypes.shape({
    logoFile: PropTypes.shape({ message: PropTypes.string }),
    headerFile: PropTypes.shape({ message: PropTypes.string }),
  }).isRequired,
};

function mapStateToProps(state) {
  const {
    linkboardEditor,
  } = state;
  const branding = linkboardEditor.workingCopy.linkboardBranding;
  return {
    backgroundColor: branding.backgroundColor,
    fontColor: branding.fontColor,
    font: branding.font,
    useHeaderImage: linkboardEditor.config.useHeaderImage,
    useLogoImage: linkboardEditor.config.useLogoImage,
    headerFile: linkboardEditor.workingCopy.headerFile,
    logoFile: linkboardEditor.workingCopy.logoFile,
    description: linkboardEditor.workingCopy.description,
    expandedSection: linkboardEditor.config.expandedSection,
    overrideGlobalBrandingOnSave: linkboardEditor.config.overrideGlobalBrandingOnSave,
    validationData: linkboardEditor.validation,
  };
}

export default connect(mapStateToProps, (store) => ({
  updateLinkboardProperty:
    async (...args) => linkboardActions.updateLinkboardProperty(store, ...args),
  toggleSection: async (...args) => linkboardActions.toggleSection(store, ...args),
  updateConfigProperty: async (...args) => linkboardActions.updateConfigProperty(store, ...args),
  toggleOverrideGlobalBrandingOnSave:
    async (...args) => linkboardActions.toggleOverrideGlobalBrandingOnSave(store, ...args),
}))(BrandingSection);
