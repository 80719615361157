import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { connect } from 'react-unistore';

import ReactGA from 'react-ga';
import SubscribedAdminRoute from './Routing/SubscribedAdminRoute';
import SubscribedUserRoute from './Routing/SubscribedUserRoute';
import UserRoute from './Routing/UserRoute';
import AdminRoute from './Routing/AdminRoute';
import AccountingRoute from './Routing/AccountingRoute';

import Snackbar from './Snackbar';
import Spinner from './Spinner';

import Login from '../routes/login';
import Logout from '../routes/logout';

import LinksCreateWizard from '../routes/links/create';
import LinksEdit from '../routes/links/edit';
import LinksDetail from '../routes/links/detail';
import Links from '../routes/links';

import CampaignsCreate from '../routes/campaigns/create';
import CampaignsEdit from '../routes/campaigns/edit';
import CampaignsDetail from '../routes/campaigns/detail';
import Campaigns from '../routes/campaigns';

import LinkboardEditor from '../routes/linkboards/editor';
import LinkboardsDetail from '../routes/linkboards/detail';
import Linkboards from '../routes/linkboards';

import Analytics from '../routes/analytics';

import UserCreate from '../routes/user/create';
import UserEdit from '../routes/user/edit';
import User from '../routes/user';

import GroupsCreate from '../routes/groups/create';
import GroupsEdit from '../routes/groups/edit';
import Groups from '../routes/groups';

import Payment from '../routes/payment';
import PaymentSteps from '../routes/payment/steps';

import PasswordChange from '../routes/password/password_change';
import PasswordSet from '../routes/password/password_set';
import PasswordReset from '../routes/password/password_reset';
import Branding from '../routes/branding';

import Inactive from '../routes/inactive';
import closedAccount from '../routes/closedAccount';

import Welcome from '../routes/welcome';
import Offline from './Offline';

import { logSessionStatusToConsole } from '../api/common';
import Accounting from '../routes/accounting';
import AccountingAccount from '../routes/accounting/account';
import AccountingInvoiceEditor from '../routes/accounting/invoice/editor';
import ScrollToTop from './ScrollToTop';
import Domains from '../routes/domains';
import AddDomain from '../routes/domains/add';
import DomainDetails from '../routes/domains/details';

ReactGA.initialize(process.env.REACT_APP_GA_UA, {
  debug: false,
  titleCase: false,
});

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  constructor(props) {
    super(props);
    logSessionStatusToConsole();
  }

  render() {
    const {
      snackbarVisible, snackbarText, snackbarType, loading, networkError,
    } = this.props;

    // this shloud only be used if backend is completely offline or unreachable
    // app can't be bootstrapped
    if (networkError && networkError.recoverable === false) {
      return (<Offline message={networkError.message} />);
    }

    return (
      <div id="app">
        <section className="content">
          <BrowserRouter>
            <ScrollToTop>
              <Switch>

                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/welcome" component={PasswordSet} />
                <Route path="/new-password" component={PasswordSet} />
                <Route path="/reset-password" component={PasswordReset} />
                <Route path="/closed-account" component={closedAccount} />

                <SubscribedUserRoute path="/links/create" component={LinksCreateWizard} />
                <SubscribedUserRoute path="/links/edit/:id" component={LinksEdit} />
                <SubscribedUserRoute path="/links/detail/:id" component={LinksDetail} />
                <SubscribedUserRoute path="/links/:id?" component={Links} />

                <SubscribedUserRoute path="/campaigns/create" component={CampaignsCreate} />
                <SubscribedUserRoute path="/campaigns/edit/:id" component={CampaignsEdit} />
                <SubscribedUserRoute path="/campaigns/detail/:id" component={CampaignsDetail} />
                <SubscribedUserRoute path="/campaigns" component={Campaigns} />

                <SubscribedUserRoute path="/linkboards/create" component={LinkboardEditor} />
                <SubscribedUserRoute path="/linkboards/edit/:id" component={LinkboardEditor} />
                <SubscribedUserRoute path="/linkboards/detail/:id" component={LinkboardsDetail} />
                <SubscribedUserRoute path="/linkboards" component={Linkboards} />

                <SubscribedUserRoute path="/analytics" component={Analytics} />

                <SubscribedAdminRoute path="/user/create" component={UserCreate} />
                <SubscribedAdminRoute path="/user/edit/:id" component={UserEdit} />
                <SubscribedAdminRoute path="/user" component={User} />

                <SubscribedAdminRoute path="/groups/create" component={GroupsCreate} />
                <SubscribedAdminRoute path="/groups/edit/:id" component={GroupsEdit} />
                <SubscribedAdminRoute path="/groups" component={Groups} />

                <AdminRoute path="/payment/choose-payment" component={PaymentSteps} />
                <AdminRoute path="/payment" component={Payment} />
                <AdminRoute path="/branding" component={Branding} />
                <AdminRoute path="/domains/add" component={AddDomain} />
                <AdminRoute path="/domains/details/:id" component={DomainDetails} />
                <AdminRoute path="/domains" component={Domains} />

                <UserRoute path="/password" component={PasswordChange} />

                <UserRoute path="/account-inactive" component={Inactive} />

                <AccountingRoute path="/accounting/account/:accountId/edit/:invoiceId" component={AccountingInvoiceEditor} />
                <AccountingRoute path="/accounting/account/:accountId/new" component={AccountingInvoiceEditor} />
                <AccountingRoute path="/accounting/account/:id/list" component={AccountingAccount} />
                <AccountingRoute path="/accounting" component={Accounting} />

                <SubscribedUserRoute path="/" component={Welcome} />

              </Switch>
            </ScrollToTop>
          </BrowserRouter>

          <Snackbar show={snackbarVisible} type={snackbarType}>
            <span>{snackbarText}</span>
          </Snackbar>

          <Spinner show={loading} />
        </section>
      </div>
    );
  }
}
App.defaultProps = {
  networkError: null,
};
App.propTypes = {
  snackbarVisible: PropTypes.bool.isRequired,
  snackbarText: PropTypes.string.isRequired,
  snackbarType: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  networkError: PropTypes.shape({
    recoverable: PropTypes.bool.isRequired,
    message: PropTypes.string,
  }),
};

export default connect(
  'snackbarVisible,snackbarText,snackbarType,loading,networkError',
  null,
)(App);
