import React, { Component } from 'react';
import { connect } from 'react-unistore';
import ReactGA from 'react-ga';
import style from './style.module.scss';
import buttonStyle from '../../../../components/Form/multiStepForm.scss';
import Button from '../../../../components/Button';
import actions from '../../../../actions';
import {
  PAYPAL, STRIPE, INVOICE, SUBSCRIPTION_PRICE,
} from '../../../../util/constants';
import PayPalPayment from '../../../../components/PayPalPayment/PayPalPayment';
import { getAccount, getLatestPayment, getStripeCard } from '../../../../util/user-management';
import { paymentMethodIcon, formatToCurrency } from '../../../../util/helperFunctions';
import Content from '../../../../components/Content';
import LinkRouter from '../../../../components/Link';

class Step2 extends Component {
  subscribedUserCount() {
    const account = getAccount();
    const currentUserCount = account.quantity;
    return currentUserCount + this.props.quantity;
  }

  render() {
    const payment = getLatestPayment();
    const price = formatToCurrency(SUBSCRIPTION_PRICE);
    const totalPrice = formatToCurrency(SUBSCRIPTION_PRICE * this.subscribedUserCount());

    const { quantity } = this.props;

    if (payment.provider === INVOICE) {
      return (
        <div>
          <div>
            <h1 style={{ marginBottom: '10px' }}>Overview</h1>
            <p className={style.text_wrapper}>
              One step left to finish your order!
              After that we will update your subscription and send out an invitation email to your new users.
            </p>

            <div className={style.box}>
              <div className={style.content_wrapper}>
                <h2>Update of subscription</h2>
                <div className={style.active_user}>
                  <img src="/icons/usergroups.svg" alt="" />
                  <p style={{ marginTop: '-2px' }}>
                    Active users:&nbsp;
                    {this.subscribedUserCount()}
                  </p>

                </div>
                <br />
                <p>
                  Your next invoice will reflect these changes as to your agreement.
                </p>
              </div>

              <div className={style.content_wrapper}>
                <h2>Method of payment</h2>

                <div>
                  <p>
                    You have chosen
                    <b>&nbsp;Invoice&nbsp;</b>
                    as your payment method.
                  </p>
                </div>

              </div>
            </div>

            <Content bar>

              <LinkRouter back onClick={this.props.prevStep} />

              <Button
                label="Create Users at Cost"
                type="submit"
                className={buttonStyle.submitButton}
                onClick={() => {
                  ReactGA.event({
                    category: 'User',
                    action: 'Invite',
                    label: 'Order at Cost (Invoice)',
                    value: quantity,
                  });
                  this.props.handleSubmit();
                }}
              />

            </Content>

          </div>
        </div>
      );
    }

    return (
      <div>
        <div>
          <h1 style={{ marginBottom: '10px' }}>Payment overview</h1>
          <p className={style.text_wrapper}>
            One step left to finish your order!
            After that we will update your subscription and send out an invitation email to your new users.
          </p>

          <div className={style.box}>
            <div className={style.content_wrapper}>
              <h2>Update of subscription</h2>
              <div className={style.active_user}>
                <img src="/icons/usergroups.svg" alt="" />
                <p>
                  Active users:&nbsp;
                  {this.subscribedUserCount()}
                </p>
                <img
                  onClick={this.props.prevStep}
                  src="/icons/edit.svg"
                  alt=""
                />
              </div>
              <p>
                <p>
                  The price per active user is
                  {' '}
                  <b>{`${price} €`}</b>
                  {' '}
                  per month.
                </p>
                In total
                {' '}
                <b>
                  {`${totalPrice} €`}

                </b>
                {' '}
                per month.
              </p>
            </div>

            <div className={style.content_wrapper}>
              <h2>Method of payment</h2>
              {payment.provider === PAYPAL
                  && (
                    <div>
                      <p>
                        You have chosen
                        <b> &nbsp;PayPal&nbsp;</b>
                        as your payment method.
                      </p>
                      <img className={style.paypal_icon} src={`../../../${paymentMethodIcon('paypal')}`} alt="PayPal" />
                    </div>
                  )}

              {payment.provider === STRIPE
                && (
                <div>
                  <p>
                    You have chosen
                    <b>&nbsp;Credit Card&nbsp;</b>
                    as your payment method.
                  </p>
                  <p>
                    <img className={style.creditcard_icon} src={`../../../${paymentMethodIcon(getStripeCard().brand)}`} alt="Credit Card" />
                    Credit Card Number: **** **** ****
                    {' '}
                    {getStripeCard().last4}
                  </p>
                </div>
                )}

            </div>
          </div>

          <div className={style.pricebar}>
            <div>
              <h4>
                Total price:
                <b>
                  {`${totalPrice} €`}
                </b>
                <span className={style.font_light}> incl. VAT</span>
              </h4>
            </div>
          </div>

          <Content bar>

            <LinkRouter back onClick={this.props.prevStep} />

            { payment.provider === PAYPAL
              ? (
                <div style={{ width: '250px' }}>
                  <PayPalPayment
                    update
                    subscriptionId={payment.payPalSubscriptionId}
                    quantity={this.subscribedUserCount()}
                    onSubmit={() => {
                      ReactGA.event({
                        category: 'User',
                        action: 'Invite',
                        label: 'Order at Cost (PayPal)',
                        value: quantity,
                      });
                      this.props.handleSubmit();
                    }}
                    handleErrorMessage={this.props.handleErrorMessage}
                  />
                </div>
              )
              : (
                <Button
                  label="Order at cost"
                  type="submit"
                  className={buttonStyle.submitButton}
                  onClick={() => {
                    ReactGA.event({
                      category: 'User',
                      action: 'Invite',
                      label: 'Order at Cost (Stripe)',
                      value: quantity,
                    });
                    this.props.handleSubmit();
                  }}
                />
              )}

          </Content>

        </div>
      </div>
    );
  }
}

export default connect('', actions)(Step2);
