import React, { Component } from 'react';
import { connect } from 'react-unistore';
import ReactGA from 'react-ga';
import style from './style.module.scss';
import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import SecondaryButton from '../../../../components/SecondaryButton';
import { validateRequiredFields, validateUnusedEmails } from '../../../../util/validates';
import actions from '../../../../actions';
import Content from '../../../../components/Content';
import { fetchAccount } from '../../../../api/account';
import ParagraphRequiredField from '../../../../components/ParagraphRequiredField';

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonShow: false,
      rolesAll: [{
        id: 'ROLE_USER',
        name: 'User',
      }, {
        id: 'ROLE_SUPER_ADMIN',
        name: 'Admin',
      }],
    };

    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.handleIndexSelectChange = this.handleIndexSelectChange.bind(this);
    this.checkForSubmitButton = this.checkForSubmitButton.bind(this);
    this.checkForButton = this.checkForButton.bind(this);
    this.increase = this.increase.bind(this);
    this.decrease = this.decrease.bind(this);
  }

  handleIndexChange(event, name, index) {
    const users = this.props.users.map((user) => (user.userId === index + 1
      ? {
        ...user,
        [name]: event.target.value,
      }
      : user));
    this.props.updateData({ users });
  }

  handleIndexSelectChange(items, name, index) {
    const value = items !== null ? Array.isArray(items) ? items.map((item) => item.value) : items.value : [];
    const users = this.props.users.map((user) => (user.userId === index + 1
      ? {
        ...user,
        [name]: value,
      }
      : user));
    this.props.updateData({ users });
  }

  async checkForSubmitButton() {
    const {
      toggleLoading, toggleSnackbar, handleDisabledSteps, nextStep,
    } = this.props;
    // TODO: do not access dom directly in react app!!!
    const requiredFields = Array.from(document.querySelectorAll('[data-required]'));
    const error = validateRequiredFields(requiredFields);

    if (!error) {
      // TODO: do not access dom directly in react app!!!
      const emails = Array.from(document.querySelectorAll('input[type="email"]'));
      toggleLoading(true);
      try {
        const invalidEmails = await validateUnusedEmails(emails);

        if (!invalidEmails) {
          ReactGA.event({
            category: 'User',
            action: 'Invite',
            label: 'Next',
            value: emails.length,
          });
          // make sure subscriber count is up to date
          await fetchAccount();
          nextStep();
          handleDisabledSteps([]);
        } else {
          toggleSnackbar('You tried to invite a user that is already registered on Linkhawk. Please use another email address.');
        }

        toggleLoading(false);
      } catch (err) {
        toggleLoading(false);
        toggleSnackbar(err.message);
      }
    }
  }

  checkForButton(quantity) {
    if (quantity > 1) {
      this.setState({
        buttonShow: true,
      });
    } else {
      this.setState({
        buttonShow: false,
      });
    }
  }

  increase() {
    const { users, defaultGroupId, quantity } = this.props;
    const updatedQuantity = quantity + 1;
    this.props.updateData({
      users: [
        ...users,
        {
          userId: updatedQuantity,
          firstname: '',
          lastname: '',
          email: '',
          role: 'ROLE_USER',
          usergroups: [defaultGroupId],
        },
      ],
      quantity: updatedQuantity,
    });

    this.checkForButton(updatedQuantity);
  }

  decrease() {
    const { users, quantity } = this.props;
    const updatedQuantity = quantity - 1;
    if (users.length < 1) return;
    users.pop();

    this.props.updateData({
      users,
      quantity: updatedQuantity,
    });

    this.checkForButton(updatedQuantity);
  }

  render() {
    const { users } = this.props;

    return (
      <div id={style.createUser}>
        <Form>
          <h1 className="center">Invite users</h1>
          {users.map((user, i) => (

            <div id={`user${i}`} key={i + 1} className={style.formInputs}>

              <Form.Input
                name="firstname"
                value={user.firstname}
                onInput={(e) => this.handleIndexChange(e, 'firstname', i)}
                type="text"
                label="First name*"
                width={33}
                data-required
                placeholder="First Name"
                errorMessage="First name must not be empty"
                autoComplete="off"
              />

              <Form.Input
                name="lastname"
                value={user.lastname}
                onInput={(e) => this.handleIndexChange(e, 'lastname', i)}
                type="text"
                label="Last name"
                width={33}
                placeholder="Last Name"
                errorMessage="Last name must not be empty"
                autoComplete="off"
              />

              <Form.Input
                name="email"
                value={user.email}
                onInput={(e) => this.handleIndexChange(e, 'email', i)}
                type="email"
                label="Email address*"
                data-required
                width={33}
                placeholder="Email address"
                errorMessage="Email address must not be empty"
                autoComplete="off"
              />

              <Form.SelectField
                name="role"
                label="Role"
                width={50}
                options={this.state.rolesAll}
                value={user.role}
                placeholder="Select"
                data-required
                onChange={(items) => this.handleIndexSelectChange(items, 'role', i)}
              />

              <Form.MultiSelectField
                name="usergroups"
                label="Usergroup"
                width={50}
                options={this.props.groups}
                value={user.usergroups}
                placeholder="Example"
                data-required
                onChange={(items) => this.handleIndexSelectChange(items, 'usergroups', i)}
                errorMessage="Usergroups must not be empty"
              />
            </div>

          ))}

          <div className={style.button_wrapper}>
            <SecondaryButton
              onClick={this.increase}
              text="Add another user"
              color="secondary"
              icon="plus"
            />

            {this.state.buttonShow === true
              ? (
                <SecondaryButton
                  onClick={this.decrease}
                  text="Delete last user"
                  color="primary"
                  icon="minus"
                />
              )
              : null}
          </div>

          <Content bar noPadding>
            <ParagraphRequiredField />
            <Content button>
              <Button label="Cancel" secondary onClick={() => this.props.history.push('/user')} />
              <Button onClick={this.checkForSubmitButton} label="Next" type="button" />
            </Content>
          </Content>

        </Form>
      </div>
    );
  }
}

export default connect('groups', actions)(Step1);
