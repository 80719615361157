import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import 'react-table/react-table.css';
import { getExternalDomain, activateDomain } from '../../../api/domains';
import Spinner from '../../../components/Spinner';
import Content from '../../../components/Content';
import _ from './style.module.scss';
import Button from '../../../components/Button';
import MultiSteps from '../../../components/MultiSteps';

class DomainDetails extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const { id } = match.params;
    this.state = { domain: null, id: parseInt(id, 10) };
    this.startActivation = this.startActivation.bind(this);
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Domains',
    });
    const { id } = this.state;
    const domain = await getExternalDomain(id);
    this.setState({ domain });
  }

  async startActivation() {
    const { id } = this.state;
    this.setState({ domain: null });
    try {
      const result = await activateDomain(id);
    } catch (e) {
      console.log(e);
    }
    const domain = await getExternalDomain(id);
    this.setState({ domain });
  }

  render() {
    const { history } = this.props;
    const { domain, id } = this.state;

    const getMultiSteps = (currentStep) => (
      <MultiSteps
        description={['Add', 'DNS', 'Submit', 'Activation', 'Ready']}
        totalSteps={5}
        currentStep={currentStep}
        disabledSteps={[1, 2, 3, 4, 5].filter((e) => e !== currentStep)}
      />
    );

    if (domain === null) {
      return (<Spinner show />);
    }

    return (
      <Content center className="custom_dns">

        {domain.status === 'PENDING_VERIFICATION' && domain.verification_result === false ? (
          <>
            {getMultiSteps(2)}
            <h1>Update your DNS NS records</h1>
            <h3>{domain.name}</h3>
            <p>
              In order to use your domain with linkhawk you need to point your domains nameservers to linkhawk.
              {' '}
              <br />
              In your providers DNS settins update the following NS records:
            </p>
            <div style={{
              lineHeight: '24px',
              backgroundColor: '#F7F7F7',
              padding: '16px',
              paddingLeft: '16px',
              paddingRight: '32px',
              borderRadius: '4px',
            }}
            >
              <ul>
                <li>
                  Change
                  {' '}
                  <b>{domain.nameservers[0]}</b>
                  {' '}
                  to
                  {' '}
                  <b>ns1.linkhawk.com</b>
                </li>
                <li>
                  Change
                  {' '}
                  <b>{domain.nameservers[1]}</b>
                  {' '}
                  to
                  {' '}
                  <b>ns2.linkhawk.com</b>
                </li>
                <li>
                  Change
                  {' '}
                  <b>{domain.nameservers[2]}</b>
                  {' '}
                  to
                  {' '}
                  <b>ns3.linkhawk.com</b>
                </li>
                <li>
                  Change
                  {' '}
                  <b>{domain.nameservers[3]}</b>
                  {' '}
                  to
                  {' '}
                  <b>ns4.linkhawk.com</b>
                </li>
              </ul>

            </div>
            <p>
              After the records have been updated it can take
              {' '}
              <b>up to 48 hours</b>
              {' '}
              for the changes to take affect.
              <br />
              You can check if the change has been propagated on this page.
            </p>
          </>
        ) : null}
        {domain.status === 'PENDING_VERIFICATION' && domain.verification_result === true ? (
          <>
            {getMultiSteps(3)}
            <h1>Looks like your nameserver records are all set!</h1>
            <h3>{domain.name}</h3>
            <p>
              You can start the activation process by pressing the button below. As this step involves some manual labour the activation can take
              {' '}
              <b>up to 72 hours</b>
              .
              {' '}
              <br />
              You will be notified by mail when your domain is ready.
            </p>

          </>
        ) : null}

        {domain.status === 'PENDING_ACTIVATION' ? (
          <>
            {getMultiSteps(4)}
            <h1>Activation in progress</h1>
            <h3>{domain.name}</h3>
            <p>
              You will be notified by mail when your domain is ready.

            </p>
          </>
        ) : null}

        {domain.status === 'AVAILABLE' ? (
          <>
            {getMultiSteps(5)}
            <h1>Your domain is ready to be used!</h1>
            <h3>{domain.name}</h3>
          </>
        ) : null}

        <Content button style={{ display: 'inline-block' }}>

          <Button
            label="Back to domain overview"
            secondary
            onClick={() => {
              history.push('/domains');
            }}
          />
          {domain.status === 'PENDING_VERIFICATION' && domain.verification_result === true ? (

            <Button
              label="Activate my Domain!"
              onClick={() => {
                this.startActivation();
              }}
            />

          ) : null}
        </Content>
      </Content>
    );
  }
}

DomainDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default DomainDetails;
