import React from 'react';
import style from './style.module.scss';
import BeaconLink from "../../BeaconLink";

// eslint-disable-next-line react/prefer-stateless-function
class AccordionItem extends React.Component {
  render() {
    const { props } = this;

    const styles = `${props.secondary
      ? style.accordion_item_secondary
      : props.box
        ? style.accordion_item_box
        : style.accordion_item}`;

    const type = `${props.type === 'checkbox' ? 'checkbox' : 'radio'}`;

    const title = props.name ? `${props.name}` : 'accordion';

    const {headline, beaconId} = props;

    return (
      <div
        className={`${styles} ${
          props.secondary
            ? 'accordionItemSecondary'
            : props.box
              ? 'accordionItemBox'
              : 'accordionItem'}`}
      >
        <input
          className={style.input}
          checked={props.checked}
          type={type}
          name={title}
          id={props.id}
          onClick={props.onClick}
        />
        <label
          className={`${style.accordion_headline} ${props.error ? 'error do_not_remove_using_global_doc_query_bs' : ''}`}
          htmlFor={props.id}
        >
          {beaconId ? (<BeaconLink beaconId={beaconId} label={headline} />) : headline}


        </label>
        <div className="panel">
          {props.children}
        </div>
      </div>
    );
  }
}

export default AccordionItem;
