import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-unistore';
import style from './style.module.scss';
import Form from '../../../Form';
import linkboardActions, { itemsHaveValidationErrors } from '../../../../actions/linkboardEditor';
import AccordionItem from '../../../Accordion/Item';
import SecondaryButton from '../../../SecondaryButton';

class ItemsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedItemIndex: 0,
    };
    this.onChange = this.onChange.bind(this);
    this.toggleItemAtIndex = this.toggleItemAtIndex.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    // if we add an item that item becomes the expanded item
    if (items.length > prevProps.items.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ expandedItemIndex: items.length - 1 });
    }
  }

  onChange(event) {
    const { updateLinkboardProperty } = this.props;
    updateLinkboardProperty(event.target.name, event.target.value);
  }

  toggleItemAtIndex(index) {
    const { expandedItemIndex } = this.state;
    this.setState({
      expandedItemIndex: expandedItemIndex === index
        ? !expandedItemIndex
        : index,
    });
  }

  render() {
    const {
      expandedSection,
      toggleSection,
      entity,
      items,
      maxItems,
      addButtonText,
      removeButtonText,
      name,
      noItemsMessage,
      itemComponent: ItemComponent,
      updateLinkboardItemProperty,
      grid,
      currency,
      addLinkboardItem,
      removeLinkboardItem,
      validationDataArray,
    } = this.props;

    const entities = `${entity}s`;

    const { expandedItemIndex } = this.state;

    return (
      <AccordionItem
        type="checkbox"
        checked={expandedSection === entities}
        onClick={() => toggleSection(entities)}
        headline={name}
        id={entities}
        error={itemsHaveValidationErrors(validationDataArray)}
      >

        {items.length === 0 ? (
          <h5>{noItemsMessage}</h5>) : null}

        {items.length > 0 && entities === 'products' ? (
          <div className={style['align-content']}>
            <div className={style['set-grid-container']}>
              <p>Position</p>

              <div
                className={style['grid-item']}
                data-active={grid}
                onClick={() => this.onChange({ target: { name: 'grid', value: true } })}
              >
                <span />
                <span />
                <span />
                <span />
              </div>

              <div
                className={style['no-grid-item']}
                data-active={!grid}
                onClick={() => this.onChange({ target: { name: 'grid', value: false } })}
              >
                <span />
                <span />
              </div>
            </div>
            <Form.Input
              type="text"
              label="Currency"
              width={25}
              placeholder="EUR"
              name="currency"
              value={currency}
              onInput={this.onChange}
            />
          </div>
        ) : null}

        {items.map((item, index) => (
          <ItemComponent
            item={item}
            key={`${entities}-${item.id}`}
            positionInList={index + 1}
            expanded={expandedItemIndex === index}
            toggleDisplayState={() => this.toggleItemAtIndex(index)}
            updateLinkboardItemProperty={updateLinkboardItemProperty}
            validationData={
              validationDataArray.length > 0
                ? validationDataArray.find((data) => data.id === item.id)
                : {}
            }
          />
        ))}

        {(items.length < maxItems) && (
          <SecondaryButton
            onClick={() => { addLinkboardItem(entity); }}
            text={addButtonText}
            color="secondary"
            icon="plus"
          />
        )}

        {items.length > 0 && (
          <SecondaryButton
            onClick={() => { removeLinkboardItem(entity); }}
            text={removeButtonText}
            color="primary"
            icon="minus"
          />
        )}

      </AccordionItem>
    );
  }
}

ItemsSection.propTypes = {
  updateLinkboardProperty: PropTypes.func.isRequired,
  toggleSection: PropTypes.func.isRequired,
  updateLinkboardItemProperty: PropTypes.func.isRequired,
  expandedSection: PropTypes.string.isRequired,
  entity: PropTypes.oneOf(['button', 'product', 'social']).isRequired,
  items: PropTypes.arrayOf().isRequired,
  addButtonText: PropTypes.string.isRequired,
  removeButtonText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  maxItems: PropTypes.number.isRequired,
  noItemsMessage: PropTypes.string.isRequired,
  itemComponent: PropTypes.elementType.isRequired,
  grid: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  addLinkboardItem: PropTypes.func.isRequired,
  removeLinkboardItem: PropTypes.func.isRequired,
  validationDataArray: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

function mapStateToProps(state, props) {
  const {
    linkboardEditor,
  } = state;
  const { entity } = props;
  const entities = `${entity}s`;
  const { validation } = linkboardEditor;
  return {
    expandedSection: linkboardEditor.config.expandedSection,
    items: linkboardEditor.workingCopy[entities].filter((i) => i.isActive),
    grid: linkboardEditor.workingCopy.grid,
    currency: linkboardEditor.workingCopy.currency,
    validationDataArray: (entities in validation) ? validation[entities] : [],
  };
}

export default connect(mapStateToProps, (store) => ({
  updateLinkboardProperty:
     async (...args) => linkboardActions.updateLinkboardProperty(store, ...args),
  updateLinkboardItemProperty:
     async (...args) => linkboardActions.updateLinkboardItemProperty(store, ...args),
  toggleSection: async (...args) => linkboardActions.toggleSection(store, ...args),
  addLinkboardItem: async (...args) => linkboardActions.addLinkboardItem(store, ...args),
  removeLinkboardItem: async (...args) => linkboardActions.removeLinkboardItem(store, ...args),
}))(ItemsSection);
