import React from 'react';
import style from './style.module.scss';

const AutocompleteInput = (props) => {
  const className = `${style.input_wrapper} width_${props.width} ${props.className ? props.className : ''} ${props.error ? `${style.error} error` : ''}`;
  return (
    <div className={className} style={props.containerStyle}>
      {props.label && <label>{props.label}</label>}
      <div>
        <input
          type="text"
          name={props.name}
          autoComplete="true"
          placeholder={props.placeholder}
          value={props.value}
          {...props}
          disabled={props.isDisabled}
        />
        {props.error && <div className={style.error_message}>{props.errorMessage}</div>}
      </div>
    </div>
  );
};

export default AutocompleteInput;
