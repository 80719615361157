import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import ReactTable from 'react-table';
import style from './style.module.scss';
import BeaconLink from '../BeaconLink';

class GeoLocationChart extends Component {
  constructor(props) {
    super(props);
    // const { data, config } = props;

    this.state = {
      isFetchingData: false,
      data: null,
      // config,
      selectedIndex: null,

    };

    this.locationSelected = this.locationSelected.bind(this);
  }

  componentDidMount() {
    this.timeRangeDidChange();
  }

  componentDidUpdate(prevProps) {
    const {
      dateStart, dateEnd,
    } = this.props;
    if (dateStart !== prevProps.dateStart || dateEnd !== prevProps.dateEnd) {
      this.timeRangeDidChange();
    }
  }

  async timeRangeDidChange() {
    const {
      data, fetchAnalytics, dateStart, dateEnd, id,
    } = this.props;
    const { selectedIndex } = this.state;
    this.setState({
      isFetchingData: true,
    });

    let analytics;
    if (selectedIndex !== null && selectedIndex !== undefined && data[selectedIndex] !== undefined) {
      analytics = await fetchAnalytics(id, { dateStart, dateEnd }, 'hits_by_location', data[selectedIndex].code);
    } else {
      analytics = await fetchAnalytics(id, { dateStart, dateEnd }, 'hits_by_location');
    }
    this.setState({
      isFetchingData: false,
      data: analytics.hitsByLocation.data,
      config: analytics.hitsByLocation.config,
    });
  }

  locationInListSelected(locationName) {
    const { data } = this.state;

    let index = 0;
    data.forEach((item, idx) => {
      if (item.name === locationName) {
        index = idx;
      }
    });
    this.locationSelected(index);
  }

  async locationSelected(index) {
    const { data, config } = this.state;
    const {
      fetchAnalytics, dateStart, dateEnd, id,
    } = this.props;
    this.setState({ isFetchingData: true });
    let analytics;
    if (config.region === 'world') {
      analytics = await fetchAnalytics(id, { dateStart, dateEnd }, 'hits_by_location', data[index].code);
    } else {
      analytics = await fetchAnalytics(id, { dateStart, dateEnd }, 'hits_by_location');
    }
    this.setState({
      selectedIndex: config.region === 'world' ? index : null,
      isFetchingData: false,
      data: analytics.hitsByLocation.data,
      config: analytics.hitsByLocation.config,
    });
  }

  render() {
    const {
      containerStyle, headline, keyColors, beaconId
    } = this.props;
    const {
      data, config, isFetchingData,
    } = this.state;

    if (data === null) {
      return (
        <div className={style.geoChartWrapper} style={containerStyle}>
          <h2 className={style.chartHeadline}>{beaconId ? (<BeaconLink beaconId={beaconId} label={headline} />) : headline}</h2>
          <div style={{ height: ' 35vh' }}>loading ...</div>
        </div>
      );
    }

    const chartData = [['Country', 'Clicks']].concat(
      data.map(({ code, hits }) => ([code, hits])),
    );

    const chartEvents = [
      {
        eventName: 'select',
        callback: ({ chartWrapper }) => {
          const selection = chartWrapper.getChart().getSelection();
          if (selection.length === 1) {
            const item = selection[0];
            this.locationSelected(item.row);
          }
        },
      },
    ];

    const columns = [
      {
        Header: 'Location',
        accessor: 'name',
        Cell: (props) => {
          if (config.region === 'world') {
            return (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.locationInListSelected(props.original.name);
                }}
              >
                {props.original.name}
              </a>

            );
          }
          return props.original.name;
        },
      },
      {
        Header: 'Clicks',
        accessor: 'hits',
      },
      {
        Header: '%',
        accessor: 'percentage',
        style: {
          fontWeight: 'bold',
        },
      },
    ];

    const rows = data.map(({ name, hits, percentage }) => ({ name, hits: `${hits} Clicks`, percentage }));

    return (
      <div className={style.geoChartWrapper} style={containerStyle}>
        <h2 className={style.chartHeadline}>{beaconId ? (<BeaconLink beaconId={beaconId} label={headline} />) : headline}</h2>

        { !isFetchingData ? (
          <div className={style.chart} style={{ display: 'flex', flex: 1 }}>
            <div style={{ flexDirection: 'row', marginRight: '30px' }}>
              {config.region !== 'world' ? (
                <button
                  type="button"
                  className={style.backButton}
                  onClick={this.locationSelected}
                >
                  Back to Overview
                </button>
              ) : null}

              <ReactTable
                data={rows}
                columns={columns}
                defaultPageSize={5}
                showPagination={false}
                minRows={0}
                resizable={false}
              />
            </div>
            <div style={{ flexDirection: 'row', flexGrow: 1 }}>
              <Chart
                width="100%"
                height="100%"
                chartType="GeoChart"
                options={{
                  colorAxis: {
                    colors: keyColors,
                  },
                  region: config.region,
                  resolution: config.resolution,
                }}
                data={chartData}
                mapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                rootProps={{ 'data-testid': '1' }}
                chartEvents={chartEvents}
              />
            </div>

          </div>
        )
          : (
            <div style={{ height: ' 35vh' }}>loading ...</div>
          ) }
        {/* <div id="barChartTooltip" className="tooltip" /> */}
      </div>
    );
  }
}

export default GeoLocationChart;
