import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ReactTable from 'react-table';
import { connect } from 'react-unistore';
import { Link } from 'react-router-dom';
import 'react-table/react-table.css';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import actions from '../../actions';
import Content from '../../components/Content';
import { clearErrors } from '../../util/validates';
import Copy from '../../components/Copy';
import Search from '../../components/Form/components/Search';
import BeaconLink from "../../components/BeaconLink";

class Campaigns extends Component {
    constructor(props) {
        super(props);
        this.state = { queryString: null };
        this.setActiveClass = this.setActiveClass.bind(this);
        this.updateSearchQuery = this.updateSearchQuery.bind(this);
    }

    async componentDidMount() {
        ReactGA.ga('send', {
        hitType: 'pageview',
        page: window.location.pathname,
        title: 'Campaigns',
        });
        const { getCampaigns, campaigns } = this.props;
        clearErrors();
        window.scrollTo(0, 0);
        await getCampaigns(0, campaigns.pageSize);
    }

    setActiveClass(state, rowInfo) {
        const { loading } = this.props;
        const { search } = this.props.location;
        const query = new URLSearchParams(search);
        const highlightId = query.get('highlightId');

        if (rowInfo.original.id === parseInt(highlightId) && !loading) {
        return {
            className: 'animated',
        };
        }
        return {};
    }

    async updateSearchQuery(query) {
        const { getCampaigns, campaigns } = this.props;
        const { queryString } = this.state;

        if (queryString !== query) {
        this.setState({ queryString: query });
        await getCampaigns(0, campaigns.pageSize, query);
        }
    }

    render() {
        const {
        campaigns,
        history,
        getCampaigns,
        } = this.props;
        const {
        items,
        currentPage,
        pages,
        pageSize,
        fetched,
        } = campaigns;

        const { queryString } = this.state;

        const columns = [
        {
            Header: 'Name',
            name: 'link',
            id: 'link_id',
            accessor: (row) => `${row.title} ${row.created} ${row.user} ${row.usergroup}`,
            filterable: false,
            sortable: false,
            Cell: (props) => {
            let date = new Date(props.original.created_at);
            date = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
            return (
                <div>
                <h6>{props.original.title}</h6>
                <p className="small">
                    Created:&nbsp;
                    <span>{date}</span>
                    {' '}
                    <br />
                    User:&nbsp;
                    <span>{props.original.user}</span>
                    <br />
                    Group:&nbsp;
                    <span>{props.original.usergroup}</span>
                </p>
                </div>
            );
            },
        }, {
            id: 'short_urls',
            Header: 'Shortlinks',
            accessor: 'shortUrls', // row => `${row.shortUrls}`,
            filterable: false,
            sortable: false,
            width: 150,
            Cell: (props) => (
            <div className="cellPulldown">
                {
                props.value.map((shortUrl) => (
                    <Copy key={`copy-${shortUrl.url}`} value={shortUrl.url} maxWidth={150} />
                ))
                }
            </div>
            ),

        }, {
            id: 'Clicks',
            Header: 'Clicks',
            accessor: 'shortUrls',
            headerClassName: 'center',
            className: 'center',
            filterable: false,
            sortable: false,
            width: 100,
            Cell: (props) => (
            <div className="cellPulldown">
                {
                props.value.map((shortUrl) => (
                    <p key={`clicks-${shortUrl.url}`}>{shortUrl.views}</p>
                ))
                }
            </div>
            ),
        }, {
            id: 'TotalClicks',
            Header: 'Total',
            accessor: 'views',
            headerClassName: 'center',
            className: 'center',
            filterable: false,
            sortable: false,
            width: 90,
            Cell: (props) => <div className="cellPulldown"><p className="big">{props.value}</p></div>,
        }, {
            Header: 'Target URL',
            accessor: 'targetUrl',
            id: 'target_url',
            filterable: false,
            sortable: false,
            headerClassName: 'margin_left',
            className: 'margin_left',
            Cell: (props) => {
            let trimmedString = props.original.targetUrl;
            if (trimmedString.length > 30) {
                trimmedString = `${trimmedString.substring(0, 30)}...`;
            }
            return (
                <div className="cellPulldown">
                <a
                    title={props.original.targetUrl}
                    target="_blank"
                    className="long-value"
                    rel="noopener noreferrer"
                    href={props.original.targetUrl}
                >
                    {trimmedString}
                </a>
                </div>
            );
            },
        }, {
            Header: 'Edit',
            id: 'Edit',
            headerClassName: 'center',
            className: 'icons',
            accessor: (row) => `${row.id}`,
            Cell: (props) => (
            <div className="cellPulldown center">
                <Link
                to={`/campaigns/edit/${props.original.id}`}
                onClick={() => {
                    ReactGA.event({
                    category: 'Campaign',
                    action: 'Edit',
                    label: 'Open Editor',
                    });
                }}
                >
                <img alt="edit campaign" src="/icons/edit.svg" />
                </Link>
            </div>
            ),
            filterable: false,
            sortable: false,
            width: 100,

        }, {
            Header: 'Analytics',
            id: 'Analytics',
            accessor: (row) => `${row.id}`,
            Cell: (props) => (
            <div className="cellPulldown center">
                <a
                href={`/campaigns/detail/${props.original.id}`}
                onClick={() => {
                    ReactGA.event({
                    category: 'Campaign',
                    action: 'View',
                    label: 'Analytics',
                    });
                }}
                >
                <img alt="show analytics" src="/icons/chart-bar-system.svg" />
                </a>
            </div>
            ),
            filterable: false,
            sortable: false,
            width: 100,
            headerClassName: 'center',
            className: 'icons',
        },
        ];

        return (
        <Content>

            {(fetched && !items.length && queryString === null)
            && (
                <Content center small>
                <img src="/campaigns_create.png" alt="create a campaign" style={{ width: '300px' }} />
                <h1>Create a campaign </h1>
                <p>Start tracking your customers’ journeys on every link and channel from a single, sleek dashboard.</p>
                <Button
                    label="Create campaign"
                    onClick={() => {
                    ReactGA.event({
                        category: 'Campaign',
                        action: 'Create',
                        label: 'Open Editor',
                    });

                    history.push('/campaigns/create');
                    }}
                    width={200}
                />
                </Content>
            )}

            {(fetched && (items.length > 0 || queryString !== null))
            && (
                <div>
                <Content header>
                    <h1>Campaigns</h1>
                    <Content button>
                    <Button
                        label="Create new campaign"
                        onClick={() => {
                        ReactGA.event({
                            category: 'Campaign',
                            action: 'Create',
                            label: 'Open Editor',
                        });
                        history.push('/campaigns/create');
                        }}
                    />
                    <Search
                        placeholder="Search"
                        autocomplete={false}
                        callback={this.updateSearchQuery}
                    />
                    </Content>
                </Content>

                <ReactTable
                    data={items}
                    page={currentPage}
                    pages={pages}
                    defaultPageSize={pageSize}
                    manual
                    onFetchData={async (state) => getCampaigns(state.page, state.pageSize, queryString)}
                    columns={columns}
                    minRows={0}
                    resizable={false}
                    getTrProps={this.setActiveClass}
                    PaginationComponent={Pagination}
                />
                </div>
            )}

        </Content>
        );
    }
}

export default connect('campaigns,loading', actions)(Campaigns);
