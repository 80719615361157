/* eslint-disable no-underscore-dangle */
import React, { createRef, Component } from 'react';
import Chart from 'chart.js';
import style from './style.module.scss';
import { customTooltips, addDecimalDivider } from './Tooltip';

// --Chart Style Options--//
Chart.helpers.drawRoundedTopRectangle = function (ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  // top right corner
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  // bottom right	corner
  ctx.lineTo(x + width, y + height);
  // bottom left corner
  ctx.lineTo(x, y + height);
  // top left
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
};

Chart.elements.RoundedTopRectangle = Chart.elements.Rectangle.extend({
  draw() {
    const { ctx } = this._chart;
    const vm = this._view;
    let left; let right; let top; let bottom; let signX; let signY; let
      borderSkipped;
    let { borderWidth } = vm;

    if (!vm.horizontal) {
      // bar
      left = vm.x - vm.width / 2;
      right = vm.x + vm.width / 2;
      top = vm.y;
      bottom = vm.base;
      signX = 1;
      signY = bottom > top ? 1 : -1;
      borderSkipped = vm.borderSkipped || 'bottom';
    } else {
      // horizontal bar
      left = vm.base;
      right = vm.x;
      top = vm.y - vm.height / 2;
      bottom = vm.y + vm.height / 2;
      signX = right > left ? 1 : -1;
      signY = 1;
      borderSkipped = vm.borderSkipped || 'left';
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
      // borderWidth shold be less than bar width and bar height.
      const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
      borderWidth = borderWidth > barSize ? barSize : borderWidth;
      const halfStroke = borderWidth / 2;
      // Adjust borderWidth when bar top position is near vm.base(zero).
      const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
      const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
      const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
      const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
      // not become a vertical line?
      if (borderLeft !== borderRight) {
        top = borderTop;
        bottom = borderBottom;
      }
      // not become a horizontal line?
      if (borderTop !== borderBottom) {
        left = borderLeft;
        right = borderRight;
      }
    }

    // calculate the bar width and roundess
    const barWidth = Math.abs(left - right);
    const roundness = this._chart.config.options.barRoundness || 0.5;
    const radius = barWidth * roundness * 0.5;

    // keep track of the original top of the bar
    const prevTop = top;

    // move the top down so there is room to draw the rounded top
    top = prevTop + radius;
    const barRadius = top - prevTop;

    const myGradient = ctx.createLinearGradient(0, 0, 0, 470);
    myGradient.addColorStop(0, vm.backgroundColor.replace(', 1)', ',0.7)'));
    myGradient.addColorStop(0.2, vm.backgroundColor.replace(', 1)', ',0.75)'));
    myGradient.addColorStop(0.5, vm.backgroundColor.replace(', 1)', ',0.8)'));
    myGradient.addColorStop(0.9, vm.backgroundColor.replace(', 1)', ',0.9)'));
    myGradient.addColorStop(1, vm.backgroundColor);

    ctx.beginPath();
    ctx.fillStyle = myGradient;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // draw the rounded top rectangle
    Chart.helpers.drawRoundedTopRectangle(ctx, left + (barWidth / 4), (top - barRadius + 1), barWidth / 2, bottom - prevTop, barRadius);
    // Chart.helpers.drawRoundedTopRectangle(ctx, left, (top - barRadius + 1), barWidth, bottom - prevTop, barRadius * 2);
    ctx.fill();
    if (borderWidth) {
      ctx.stroke();
    }

    // restore the original top value so tooltips and scales still work
    top = prevTop;
  },
});

Chart.defaults.bar = Chart.helpers.clone(Chart.defaults.bar);

Chart.controllers.bar = Chart.controllers.bar.extend({
  dataElementType: Chart.elements.RoundedTopRectangle,
});
// --Chart Style Options--//

class BarChart extends Component {
  constructor() {
    super();
    this.chartRef = createRef();
    this.chart = null;
  }

  componentDidMount() {
    const { data, keyColor } = this.props;
    const barChartRef = this.chartRef.current.getContext('2d');

    const datasets = Array.isArray(data)
      ? data.map((dataItem) => ({
        data: Object.values(dataItem.data),
        backgroundColor: dataItem.color,
        label: dataItem.label,
        fill: false,
      }))
      : [{
        data: Object.values(data),
        backgroundColor: keyColor,
        fill: false,
      }];

    // eslint-disable-next-line no-new
    this.chart = new Chart(barChartRef, {
      type: 'bar',
      data: {
        labels: Array.isArray(data) ? Object.keys(data[0].data) : Object.keys(data),
        datasets,
      },
      options: {
        legend: {
          display: Array.isArray(data),
          position: 'bottom',
          align: 'start',
          labels: {
            boxWidth: 16,
            fontSize: 17,
            fontColor: '#2F2F2F',
            padding: 24,
          },
        },
        title: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            gridLines: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              beginAtZero: true,
              userCallback(label, index, labels) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(label) === label) {
                  return addDecimalDivider(label, ',');
                }
              },
            },
          }],
          xAxes: [{
            gridLines: {
              drawBorder: false,
              borderColor: '#EBEBEB',
            },
            ticks: {
              precision: 1,
            },
          }],
        },
        tooltips: {
          enabled: false,
          mode: 'x',
          yAlign: 'bottom',
          intersect: false,
          custom: (toolTipModel) => customTooltips(toolTipModel, 'barChartTooltip', 'time'),
        },
      },
    });
  }

  componentDidUpdate() {
    const { data, keyColor } = this.props;

    const datasets = Array.isArray(data)
      ? data.map((dataItem) => ({
        data: Object.values(dataItem.data),
        backgroundColor: dataItem.color,
        label: dataItem.label,
        fill: false,
      }))
      : [{
        data: Object.values(data),
        backgroundColor: keyColor,
        label: 'Clicks',
        fill: false,
      }];
    this.chart.data = {
      labels: Array.isArray(data) ? Object.keys(data[0].data) : Object.keys(data),
      datasets,
    };
    this.chart.update(0);
  }

  render() {
    const { containerStyle, headline } = this.props;
    return (
      <div className={style.barChartWrapper} style={containerStyle}>
        <h2 className={style.chartHeadline}>{headline}</h2>
        <div className={style.chart}>
          <canvas
            ref={this.chartRef}
          />
        </div>
        <div id="barChartTooltip" className="tooltip" />
      </div>
    );
  }
}

export default BarChart;
