import React from 'react';
import style from './style.module.scss';

const Spinner = (props) => {
  const show = `${style.wrapper} ${props.show ? style.show : ''}`;

  return (
    <div className={show}>
      <div className={style.wrapper_circle}>
        <div className={style.circle_dark} />
        <div className={style.circle_light} />
      </div>
      <p>loading</p>
    </div>
  );
};

export default Spinner;
