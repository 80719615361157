/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as user from '../../../util/user-management';
import Footer from '../../Footer';
import Header from '../../Header';

const UserRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  const isLoggedIn = user.isLoggedIn();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.isAccounting()) {
          return (
            <Redirect to={{ pathname: '/accounting', state: { from: props.location } }} />
          );
        }

        return (isLoggedIn ? (
          <div>
            <Header activeLink={props.location.pathname} showNavigation={user.role()} />
            <Component {...props} />
          </div>

        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        ));
      }}
    />
  );
};
export default UserRoute;
