import {
  togglePopup,
  toggleSnackbar,
  refreshUserData,
  refreshAdminData,
  setRefreshed,
  setLoading,
  logout,
} from './general';
import {
  unsubscribe,
  subscribeViaStripe,
  subscribeViaPayPal,
  deleteAccount,
  getInvoices,
} from './account';
import {
  getCampaigns, createCampaign,
} from './campaigns';
import {
  getLinkboards,
} from './linkboards';
import {
  getLinks, createLink,
} from './links';
import { createTag } from './tags';
import { getUsers } from './users';

export default (store) => ({
  getLinks: async (...args) => getLinks(store, ...args),
  createLink: async (...args) => createLink(store, ...args),
  getCampaigns: async (...args) => getCampaigns(store, ...args),
  createCampaign: async (...args) => createCampaign(store, ...args),
  getLinkboards: async (...args) => getLinkboards(store, ...args),
  createTag: async (...args) => createTag(store, ...args),
  getUsers: async (...args) => getUsers(store, ...args),
  deleteAccount: async (...args) => deleteAccount(store, ...args),
  subscribeViaPayPal: async (...args) => subscribeViaPayPal(store, ...args),
  subscribeViaStripe: async (...args) => subscribeViaStripe(store, ...args),
  unsubscribe: async (...args) => unsubscribe(store, ...args),
  getInvoices: async (...args) => getInvoices(store, ...args),
  toggleSnackbar: (...args) => toggleSnackbar(store, ...args),
  togglePopup: (...args) => togglePopup(store, ...args),
  toggleLoading: (...args) => setLoading(store, ...args),
  setRefreshed: () => setRefreshed(store),
  refreshUserData: (...args) => refreshUserData(store, ...args),
  refreshAdminData: (...args) => refreshAdminData(store, ...args),
  logout: () => logout(store),
});
