import React, { Component } from 'react';
import style from './style.module.scss';
import Button from '../Button';
import Content from '../Content';
import Form from '../Form';
import Separator from '../Separator';

// eslint-disable-next-line react/prefer-stateless-function
export default class AddressForm extends Component {
  constructor(props) {
    super(props);
    const { account } = this.props;

    this.state = {
      city: account.city,
      country: account.country,
      firstname: account.firstname,
      lastname: account.lastname,
      company: account.name,
      vat: account.vat,
      postalCode: account.postalCode,
      streetName: account.streetName,
      streetNumber: account.streetNumber,
      showValidationErrors: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveAdress = this.saveAddress.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    console.log(JSON.stringify(state));
  }

  saveAddress() {
    const { onSave } = this.props;
    const addressData = { ...this.state };
    delete addressData.showValidationErrors;
    if (addressData.city !== ''
      && addressData.country !== ''
      && addressData.firstname !== ''
      && addressData.lastname !== ''
      && addressData.postalCode !== ''
      && addressData.streetName !== ''
      && addressData.streetNumber !== '') {
      this.setState({ showValidationErrors: false });
      onSave(addressData);
    } else {
      this.setState({ showValidationErrors: true });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const {
      firstname, lastname, streetName, streetNumber, postalCode, vat, company, country, city, showValidationErrors,
    } = this.state;
    return (
      <div className={style.addressFormContainer}>
        <Form>
          <Form.Input
            name="firstname"
            label="First Name*"
            type="text"
            value={firstname}
            onChange={this.handleChange}
            width={50}
            error={showValidationErrors && firstname === ''}

          />
          <Form.Input
            name="lastname"
            label="Last Name*"
            type="text"
            value={lastname}
            onChange={this.handleChange}
            width={50}
            error={showValidationErrors && lastname === ''}
          />

          <Form.Input
            name="streetName"
            label="Street*"
            type="text"
            value={streetName}
            onChange={this.handleChange}
            width={75}
            error={showValidationErrors && streetName === ''}

          />
          <Form.Input
            name="streetNumber"
            label="Number*"
            type="text"
            value={streetNumber}
            onChange={this.handleChange}
            width={25}
            error={showValidationErrors && streetNumber === ''}
          />

          <Form.Input
            name="postalCode"
            label="PostCode*"
            type="text"
            value={postalCode}
            onChange={this.handleChange}
            width={25}
            error={showValidationErrors && postalCode === ''}
          />
          <Form.Input
            name="city"
            label="City*"
            type="text"
            value={city}
            onChange={this.handleChange}
            width={25}
            error={showValidationErrors && city === ''}

          />
          <Form.Input
            name="country"
            label="Country*"
            type="text"
            value={country}
            onChange={this.handleChange}
            width={50}
            error={showValidationErrors && country === ''}
          />

          <Separator primary />

          <Form.Input
            name="company"
            label="Company"
            type="text"
            value={company}
            onChange={this.handleChange}
            width={50}
          />
          <Form.Input
            name="vat"
            label="VAT"
            type="text"
            value={vat}
            onChange={this.handleChange}
            width={50}
          />

          <span className={style.requiredField}>* required field</span>
        </Form>
        <Content button style={{ maxWidth: '450px', marginLeft: '170px' }}>
          <Button
            secondary
            label="Cancel"
            onClick={this.props.onCancel}
            type="reset"
          />
          <Button
            onClick={() => {
              this.saveAddress();
            }}
            label="Save changes"
          />
        </Content>
      </div>
    );
  }
}
