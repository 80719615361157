import React, { Component } from 'react';
import ReactGA from 'react-ga';
import style from './style.module.scss';

class Copy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipVisible: false,
      tooltipText: 'copy to clipboard',
    };

    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.tip = this.tip.bind(this);
    this.unTip = this.unTip.bind(this);
  }

  copyToClipboard(text) {
    const copyTextArea = document.createElement('textarea');
    copyTextArea.value = `https://${text}`;
    document.body.appendChild(copyTextArea);
    copyTextArea.select();
    try {
      ReactGA.event({
        category: 'Shortlink',
        action: 'Copy to Clipboard',
        label: `Copy to Clipboard [${window.location.pathname}]`,
      });
      document.execCommand('copy');
      this.tip('copied!');
    } catch (err) {
      this.tip('Ooops! Unable to copy');
    }
    document.body.removeChild(copyTextArea);
  }

  tip(text) {
    this.setState({
      tooltipVisible: true,
      tooltipText: text,
    });
  }

  unTip() {
    this.setState({ tooltipVisible: false });
  }

  render() {
    const tooltip = `${style.tooltip} ` + 'copyTooltip';
    const maxWidth = this.props.maxWidth ? this.props.maxWidth : 200;

    return (
      <div className={style.copyToClipboard}>

        <div className={style.iconWrapper}>
          <img
            onClick={() => this.copyToClipboard(this.props.value)}
            className="copyToClipboard"
            onMouseOver={() => this.tip('copy to clipboard')}
            onMouseOut={() => this.unTip()}
            src="/icons/copy.svg"
            alt=""
          />
          <span data-visible={this.state.tooltipVisible} className={tooltip}>{this.state.tooltipText}</span>
          <a href={`https://${this.props.value}`} target="_blank" rel="noreferrer noopener" style={{ maxWidth: `${maxWidth}px` }}>{this.props.value}</a>

        </div>

      </div>
    );
  }
}

export default Copy;
