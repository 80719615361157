import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-unistore';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import actions from '../../../../actions';
import Content from '../../../../components/Content';
import Spinner from '../../../../components/Spinner';
import { createInvoice, getAccounts, updateInvoice } from '../../../../api/accounting';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import { formatToCurrency } from '../../../../util/helperFunctions';

export const INVOICE_MODE_CREATE = 'INVOICE_MODE_CREATE';
export const INVOICE_MODE_EDIT = 'INVOICE_MODE_EDIT';

class AccountingInvoiceEditor extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const mode = location.pathname.includes('/new') ? INVOICE_MODE_CREATE : INVOICE_MODE_EDIT;

    this.state = {
      account: null,
      invoice: null,
      mode,
      isFetching: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.save = this.save.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    const { mode } = this.state;
    const { match } = this.props;
    const { accountId, invoiceId } = match.params;
    const accounts = await getAccounts();
    const account = accounts.filter((acc) => acc.id === parseInt(accountId, 10))[0];
    const invoice = account.invoices.filter((i) => i.number === parseInt(invoiceId, 10))[0];
    this.setState(
      {
        account,
        showValidationErrors: false,
        invoice: mode === INVOICE_MODE_EDIT
          ? this.normalizeInvoice(invoice)
          : {
            accountId: account.id,
            invoiceDate: moment().format('YYYY-MM-DD'),
            company: account.name,
            name: `${account.firstname} ${account.lastname}`,
            addressFirstLine: `${account.street} ${account.street_number}`,
            addressSecondLine: `${account.postal_code} ${account.city}`,
            billingPeriodStart: moment().subtract(1, 'day').subtract(1, 'month').format('YYYY-MM-DD'),
            billingPeriodEnd: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            country: account.country,
            vatId: account.vat === null ? '' : account.vat,
            quantity: account.numberOfUsers,
            price: 1900,
          },
      },
    );
  }

  normalizeInvoice(invoice) {
    const normalized = {
      ...invoice,
      invoiceDate: moment(invoice.invoiceDate).format('YYYY-MM-DD'),
      billingPeriodStart: moment(invoice.billingPeriodStart).format('YYYY-MM-DD'),
      billingPeriodEnd: moment(invoice.billingPeriodEnd).format('YYYY-MM-DD'),
      vatId: invoice.vatId === null ? '' : invoice.vatId,
    };

    return normalized;
  }

  onChange(event) {
    const { invoice } = this.state;
    let value = null;
    switch (event.target.name) {
      case 'price':
      case 'quantity':
        if (event.target.value.length === 0) {
          value = 0;
        } else if (isNaN(event.target.value)) {
          value = invoice[event.target.name];
        } else {
          value = parseInt(event.target.value, 10);
        }
        break;
      default:
        value = event.target.value;
    }

    this.setState({ invoice: { ...invoice, [event.target.name]: value } });
  }

  onDateChange(name, value) {
    const date = moment(value).format('YYYY-MM-DD');
    const { invoice } = this.state;
    this.setState({ invoice: { ...invoice, [name]: date } });
  }

  validate() {
    const { invoice } = this.state;

    return (invoice.company !== '')
    && this.checkDateValidity(invoice.invoiceDate)
    && (invoice.name !== '')
    && (invoice.addressFirstLine !== '')
    && (invoice.addressSecondLine !== '')
    && (invoice.country !== '')
    && this.checkDateValidity(invoice.billingPeriodStart)
    && this.checkDateValidity(invoice.billingPeriodEnd)
    && !isNaN(parseInt(invoice.price, 10))
    && !(isNaN(parseInt(invoice.quantity, 10)) || parseInt(invoice.quantity, 10) < 1);
  }

  async save() {
    const { mode, invoice, account } = this.state;
    const { toggleSnackbar, history } = this.props;
    const payload = { ...invoice };
    this.setState({ showValidationErrors: true });

    if (!this.validate()) {
      toggleSnackbar('Please fill out all required fileds.');
      return;
    }

    // eslint-disable-next-line default-case
    switch (mode) {
      case INVOICE_MODE_EDIT:
        try {
          this.setState({ isFetching: true });
          const res = await updateInvoice(payload);
          toggleSnackbar(`Successfully created Invoice Revision ${res.invoice.revision}`, true);
          this.setState({ invoice: this.normalizeInvoice(res.invoice) });
        } catch (err) {
          toggleSnackbar(err);
        }
        break;
      case INVOICE_MODE_CREATE:
        try {
          this.setState({ isFetching: true });
          const res = await createInvoice(payload);
          toggleSnackbar('Successfully created Invoice', true);
          this.setState({ invoice: { ...invoice, number: res.number } });
          history.push('/accounting/');
          history.push(`/accounting/account/${account.id}/edit/${res.number}`);
        } catch (err) {
          toggleSnackbar(err);
        }

        break;
    }
    this.setState({ isFetching: false });
  }

  checkDateValidity(date) {
    if (date === null) {
      return false;
    }
    const m = moment(date);
    return m.isValid();
  }

  render() {
    const { history } = this.props;
    const {
      account, mode, invoice, showValidationErrors, isFetching,
    } = this.state;
    if (account === null) {
      return (
        <Spinner show />
      );
    }

    const getTitle = () => {
      // eslint-disable-next-line default-case
      switch (mode) {
        case INVOICE_MODE_EDIT:
          return `Create Revision ${invoice.revision + 1} for Invoice #${invoice.number}`;
        case INVOICE_MODE_CREATE:
          return `Create Invoice for ${account.name}`;
      }
      return '';
    };

    const getSubtitle = () => {
      // eslint-disable-next-line default-case
      switch (mode) {
        case INVOICE_MODE_EDIT:
          return `Account-Name: ${account.name} | ID: ${account.id}`;
        case INVOICE_MODE_CREATE:
          return `Account-Name: ${account.name} | ID: ${account.id} | Active Users: ${account.numberOfUsers}`;
      }
      return '';
    };

    const saveButtonTitle = () => {
      // eslint-disable-next-line default-case
      switch (mode) {
        case INVOICE_MODE_EDIT:
          return 'Create New Revision';
        case INVOICE_MODE_CREATE:
          return 'Create Invoice';
      }
      return '';
    };

    return (
      <Content>

        <h1>{getTitle()}</h1>
        <p>{getSubtitle()}</p>

        <Form>

          <Form.Input
            label="Company*"
            placeholder="Mustermann GmbH"
            width={33}
            name="company"
            value={invoice.company}
            onInput={this.onChange}
            error={showValidationErrors && invoice.company === ''}
            errorMessage="Name can't be blank"
          />
          <Form.DayPicker
            label="Invoice Date*"
            placeholder="YYYY-MM-DD"
            width={25}
            name="invoiceDate"
            value={moment(invoice.invoiceDate).toDate()}
            onChange={(date) => { this.onDateChange('invoiceDate', date); }}
            error={showValidationErrors && !this.checkDateValidity(invoice.invoiceDate)}
            errorMessage="Invalid Date"
          />

          <Content noPadding>
            <Form.Input
              label="Name*"
              placeholder="Max Mustermann"
              width={33}
              name="name"
              value={invoice.name}
              onInput={this.onChange}
              error={showValidationErrors && invoice.name === ''}
              errorMessage="Name can't be blank"
            />
            <Form.Input
              label="Address First Line*"
              placeholder="Musterstreet 123"
              width={33}
              name="addressFirstLine"
              value={invoice.addressFirstLine}
              onInput={this.onChange}
              error={showValidationErrors && invoice.addressFirstLine === ''}
              errorMessage="Can't be blank"
            />
            <Form.Input
              label="Address Second Line*"
              placeholder="DE-40210 Düsseldrof"
              width={33}
              name="addressSecondLine"
              value={invoice.addressSecondLine}
              onInput={this.onChange}
              error={showValidationErrors && invoice.addressSecondLine === ''}
              errorMessage="Can't be blank"
            />
            <Form.Input
              label="Country*"
              placeholder="Germany"
              width={33}
              name="country"
              value={invoice.country}
              onInput={this.onChange}
              error={showValidationErrors && invoice.country === ''}
              errorMessage="Can't be blank"
            />
            <Form.Input
              label="VAT-ID"
              placeholder="DE123456789"
              width={33}
              name="vatId"
              value={invoice.vatId}
              onInput={this.onChange}
            />
          </Content>
          <div style={{
            width: '100%',
            height: '1px',
            backgroundColor: 'lightgrey',
            marginBottom: '16px',
          }}
          />

          <Form.DayPicker
            label="Billing Period From*"
            placeholder="YYYY-MM-DD"
            width={25}
            name="billingPeriodStart"
            value={moment(invoice.billingPeriodStart).toDate()}
            onChange={(date) => { this.onDateChange('billingPeriodStart', date); }}
            error={showValidationErrors && !this.checkDateValidity(invoice.billingPeriodStart)}
            errorMessage="Invalid date."
          />
          <Form.DayPicker
            label="Billing Period To*"
            placeholder="YYYY-MM-DD"
            width={25}
            name="billingPeriodEnd"
            value={moment(invoice.billingPeriodEnd).toDate()}
            onChange={(date) => { this.onDateChange('billingPeriodEnd', date); }}
            error={showValidationErrors && !this.checkDateValidity(invoice.billingPeriodEnd)}
            errorMessage="Invalid date."
          />

          <Form.Input
            label="Price* (in cent)"
            placeholder="1900"
            // type="number"
            width={25}
            name="price"
            value={invoice.price}
            onInput={this.onChange}
            error={showValidationErrors && isNaN(parseInt(invoice.price, 10))}
            errorMessage="Invalid value."
          />

          <Form.Input
            label="Quantity*"
            placeholder="1"
            width={25}
            name="quantity"
            value={invoice.quantity}
            onInput={this.onChange}
            error={showValidationErrors && (isNaN(parseInt(invoice.quantity, 10)) || parseInt(invoice.quantity, 10) < 1)}
            errorMessage="Quantity must be at least 1."
          />

          <div style={{
            width: '100%',
            height: '1px',
            backgroundColor: 'lightgrey',
            marginBottom: '16px',
          }}
          />

          <h3 style={{
            textAlign: 'right',
            marginTop: 0,
            fontSize: '32px',
          }}
          >
            {`${formatToCurrency((parseInt(invoice.quantity, 10) * parseInt(invoice.price, 10)) / 100)} €`}

          </h3>
        </Form>

        <div style={{ display: 'flex' }}>
          <div>
            {invoice.revision > 0 ? (
              <Button
                disabled={isFetching}
                label={`Download PDF Revision ${invoice.revision}`}
                onClick={() => {
                  const win = window.open(invoice.fileUrl, '_blank');
                  win.focus();
                }}
              />
            ) : null}

          </div>
          <div style={{ flex: 1 }} />
          <div>
            <Button
              label="Cancel"
              disabled={isFetching}
              secondary
              onClick={() => {
                history.push(`/accounting/account/${account.id}/list`);
              }}
            />
          </div>
          <div style={{ marginLeft: '12px' }}>
            <Button
              disabled={isFetching}
              label={saveButtonTitle()}
              onClick={this.save}
            />
          </div>
        </div>
        <br />
        <hr />
        <br />
        <Link
          to={`/accounting/account/${account.id}/list`}
        >
          Back
        </Link>
        <Spinner show={isFetching} />
      </Content>
    );
  }
}

AccountingInvoiceEditor.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.node,
      invoiceId: PropTypes.node,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default connect('', actions)(AccountingInvoiceEditor);
