import React, { Component } from 'react';
import ReactGA from 'react-ga';
import style from '../style.module.scss';
import PasswordValidator, { PASSWORD_VALIDATOR_MODE_SET_NEW_PASSWORD } from '../../../components/PasswordValidator';
import Content from '../../../components/Content';

// eslint-disable-next-line react/prefer-stateless-function
export default class PasswordSet extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const query = new URLSearchParams(location.search);

    let strings = {};

    switch (location.pathname) {
      case '/new-password':
        ReactGA.ga('send', {
          hitType: 'pageview',
          page: window.location.pathname,
          title: 'Password Reset: Set New Password',
        });
        // reset password case
        strings = {
          headline: 'Reset your password',
          text: ['Nearly there, just enter your new password and you’ll be Linkin’ again in seconds…', <br />, 'Choose a new password'],

        };
        break;
      case '/welcome':
        ReactGA.ga('send', {
          hitType: 'pageview',
          page: window.location.pathname,
          title: 'Password: Set Initial Password',
        });
        // initial case
        strings = {
          headline: 'Welcome to Linkhawk',
          text: 'To be able to use Linkhawk you have to choose your password.',
        };
        break;
      default:
    }
    this.state = {
      strings,
      token: query.get('confirm'),
    };
    // remove token from url
    // history.replace({ search: null });
  }

  render() {
    const {
      strings, token,
    } = this.state;
    const { history, location } = this.props;
    return (

      <Content center style={{ minHeight: '100vh' }}>
        <div className={style.password_container}>
          <img src="/password.png" alt="Password" />
          <h1>{strings.headline}</h1>

          <p>{strings.text}</p>

          <div className={style.password_wrapper}>
            <PasswordValidator
              mode={PASSWORD_VALIDATOR_MODE_SET_NEW_PASSWORD}
              token={token}
              history={history}
              location={location}
            />
          </div>
        </div>
      </Content>

    );
  }
}
