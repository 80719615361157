import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import actions from '../../../actions';
import { fetchAccount } from '../../../api/account';
import { LinkhawkServiceEntityNotFound } from '../../../api/common';
import { deleteUser, getUsers, updateUser } from '../../../api/users';
import Button from '../../../components/Button';
import Content from '../../../components/Content';
import Form from '../../../components/Form';
import ParagraphRequiredField from '../../../components/ParagraphRequiredField';
import PayPalPayment from '../../../components/PayPalPayment/PayPalPayment';
import PopUp from '../../../components/PopUp';
import PopUpStyle from '../../../components/PopUp/popup.module.scss';
import Spinner from '../../../components/Spinner';
import { PAYPAL, STRIPE, SUBSCRIPTION_PRICE } from '../../../util/constants';
import { formatToCurrency } from '../../../util/helperFunctions';
import {
  getAccount, getCurrentUserId,

  getLatestPayment,
  isCanceled
} from '../../../util/user-management';
import { validateRequiredFields } from '../../../util/validates';

class UserEdit extends Component {
  constructor(props) {
    super(props);

    const { match } = props;
    const { id } = match.params;
    this.state = {
      id,
      user: null,
      notFound: false,
      usergroups: [],
      rolesAll: [{
        id: 'ROLE_USER',
        name: 'User',
      }, {
        id: 'ROLE_SUPER_ADMIN',
        name: 'Admin',
      }],
      role: '',
      // temporaryNumber: 'TODO',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.confirmCheckout = this.confirmCheckout.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'User Edit',
    });
    const { groups } = this.props;
    const { id } = this.state;
    try {
      const user = await getUsers(id);

      const usergroupIds = user.usergroups.map((group) => group.id);
      const usergroups = groups
        .filter((item) => usergroupIds.includes(item.id))
        .reduce((acc, item) => ([...acc, item.id]), []);
      const role = user.roles[0] || 'ROLE_USER';

      this.setState({
        usergroups,
        role,
        user,
      });
    } catch (err) {
      if (err instanceof LinkhawkServiceEntityNotFound) {
        this.setState({ notFound: true });
      }
    }
  }

  handleChange(event) {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleSelectChange(items, name) {
    const value = items !== null ? Array.isArray(items) ? items.map((item) => item.value) : items.value : [];
    this.setState({ [name]: value });
  }

  async confirmCheckout(event) {
    // only use event.preventDefault() for stripe transactions, otherwise the page would rerender.
    // the event parameter does not exist in the same context when using this function for paypal -> (data: object, actions: object) => void
    const payment = getLatestPayment();
    if (payment.provider === STRIPE) {
      event.preventDefault();
    }
    const { id } = this.state;
    const {
      history, toggleLoading, refreshAdminData, toggleSnackbar, togglePopup,
    } = this.props;
    const input = document.getElementById('confirmCheckout');
    const inputVar = input.value;
    input.classList.remove('error');

    // checks whether the input is identical
    if (inputVar === 'delete') {
      input.classList.remove('error');
      // TODO: check this BEFORE payment flow, otherwise the user can skim 19 EUR of monthly payment

      toggleLoading(true);
      try {
        ReactGA.event({
          category: 'User',
          action: 'Delete',
          label: 'Confirmed',
        });
        await deleteUser(id);
        await fetchAccount();
        // refetch users and groups
        await refreshAdminData();
        toggleLoading(false);
        togglePopup();
        history.push('/user');
      } catch (err) {
        toggleLoading(false);
        toggleSnackbar(err.message);
      }
    } else {
      input.classList.add('error');
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {
      toggleLoading, toggleSnackbar, refreshAdminData, history,
    } = this.props;
    const {
      id, usergroups, user, role,
    } = this.state;
    const {
      firstname, lastname, email,
    } = user;

    const payload = JSON.stringify({
      firstname,
      lastname,
      email,
      role,
      usergroups,
    });

    // TODO: do not access dom directly in react app!!!
    const requiredFields = Array.from(document.querySelectorAll('[data-required]'));
    const error = validateRequiredFields(requiredFields);

    if (!error) {
      toggleLoading(true);
      try {
        ReactGA.event({
          category: 'User',
          action: 'Edit',
          label: 'Save Changes',
        });
        await updateUser(id, payload);
        // refetch users and groups
        await refreshAdminData();
        toggleLoading(false);
        toggleSnackbar('User updated successfully.', 'success');
        history.push('/user');
      } catch (err) {
        toggleLoading(false);
        toggleSnackbar(err.message);
      }
    } else {
      toggleSnackbar('Please complete all required fields!');
    }
  }

  render() {
    const account = getAccount();
    const payment = getLatestPayment();
    const subscriptionCanceled = isCanceled();
    const currentUserId = getCurrentUserId();
    const totalPrice = formatToCurrency((account.quantity - 1) * SUBSCRIPTION_PRICE);
    const {
      groups, togglePopup, history, showPopup, toggleSnackbar, onChange,
    } = this.props;
    const {
      id, user, usergroups, rolesAll, role, notFound,
    } = this.state;

    if (notFound) {
      return (<Content><h3>The User was not found.</h3></Content>);
    }
    if (user === null) {
      return (<Spinner show />);
    }

    return (
      <Content>
        <h1>Edit user</h1>

        <Form>

          <Form.Input
            name="firstname"
            value={user.firstname}
            onInput={this.handleChange}
            type="text"
            data-required
            errorMessage="Firstname must not be empty."
            label="First name*"
            width={33}
            placeholder="First Name"
          />

          <Form.Input
            name="lastname"
            value={user.lastname}
            onInput={this.handleChange}
            type="text"
            data-type="text"
            errorMessage="Lastname must not be empty."
            label="Last name"
            width={33}
            placeholder="Last Name"
          />

          <Form.Input
            name="email"
            value={user.email}
            onInput={this.handleChange}
            type="text"
            data-required
            data-type="email"
            errorMessage="Email address must not be empty."
            label="Email address*"
            width={33}
            placeholder="Email address"
          />

          <Form.SelectField
            name="role"
            label="Role"
            width={50}
            options={rolesAll}
            value={role}
            placeholder="Select"
            onChange={(items) => this.handleSelectChange(items, 'role')}
          />

          <Form.MultiSelectField
            name="usergroups"
            label="Usergroup"
            width={50}
            options={groups}
            value={usergroups}
            placeholder="Choose"
            onChange={(items) => this.handleSelectChange(items, 'usergroups')}
          />

          <Content bar noPadding>
            <ParagraphRequiredField />
            <Content button>

              {!subscriptionCanceled && currentUserId !== id && role === 'ROLE_USER' && (
                <Button
                  secondary
                  label="Delete User"
                  onClick={() => {
                    ReactGA.event({
                      category: 'User',
                      action: 'Delete',
                      label: 'Open Confirmation Modal',
                    });
                    togglePopup();
                  }}
                  type="reset"
                />
              )}
              <Button label="Cancel" secondary onClick={() => history.push('/user')} />
              <Button label="Save changes" onClick={this.handleSubmit} />
            </Content>
          </Content>

        </Form>

        {showPopup
        && (
          <PopUp>
            <div className={PopUpStyle.content_wrapper}>
              <h2>
                Do you really want to delete
                {' '}
                <br />
                this user?
              </h2>
              <p style={{
                width: '250px',
                marginBottom: 0,
              }}
              >
                The total price of your abonnement
                will now be
                <b>{` ${totalPrice} €`}</b>
              </p>

              <Form>
                <Form.Input
                  id="confirmCheckout"
                  containerStyle={{ width: '450px', margin: '0 auto 20px auto' }}
                  label="Enter the word „delete“ below to delete the user permanently."
                  placeholder={'Enter "delete"'}
                  name="delete"
                  onInput={onChange}
                  autoComplete="off"
                />

                <div className={PopUpStyle.button_wrapper}>
                  <Button
                    secondary
                    label="Cancel"
                    onClick={togglePopup}
                    type="reset"
                    className={payment.provider === PAYPAL && PopUpStyle.paypal_button_height}
                  />
                  {
                    payment.provider === PAYPAL
                      ? (
                        <div className="paypal_button" style={{ width: '250px' }}>
                          <PayPalPayment
                            update
                            subscriptionId={payment.payPalSubscriptionId}
                            quantity={account.quantity - 1}
                            onSubmit={this.confirmCheckout}
                            handleErrorMessage={() => toggleSnackbar('PayPal is currently processing your payment. Please try again later.')}
                          />
                        </div>
                      )
                      : (
                        <Button
                          label="Delete User now"
                          onClick={this.confirmCheckout}
                        />
                      )
                  }
                </div>
              </Form>
            </div>
          </PopUp>
        )}
      </Content>
    );
  }
}

UserEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleLoading: PropTypes.func.isRequired,
  refreshAdminData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  toggleSnackbar: PropTypes.func.isRequired,
  togglePopup: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect('groups,showPopup', actions)(UserEdit);
