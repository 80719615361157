/* eslint-disable max-classes-per-file */
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL;

const logout = () => {
  localStorage.clear();
  // this forces page reload which in turn ensures state reset
  window.location.href = '/login';
};

export class LinkhawkServiceError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LinkhawkServiceError';
  }
}

export class LinkhawkServiceEntityNotFound extends Error {
  constructor(message) {
    super(message);
    this.name = 'LinkhawkServiceEntityNotFound';
  }
}

export class LinkhawkServiceEntityConflict extends Error {
  constructor(message) {
    super(message);
    this.name = 'LinkhawkServiceEntityConflict';
  }
}
/**
 * checks for common network erros
 * if response body is available it gets parsed
 * @param {*} res
 * @param {bool} returnParsedResponse; this may need to be false if header
 *                                      information is needed for i.e. pagination
 * @param {*} callback
 */
export const checkResponse = async (res, returnParsedResponse = true) => {
  let data = res;
  const contentType = res.headers.get('content-type');
  if (
    returnParsedResponse && [200, 201, 400].includes(res.status)
    && contentType === 'application/json'
    && typeof res.json === 'function') {
    try {
      data = await res.json();
    } catch (err) {
      if (!(err instanceof SyntaxError)) {
        throw err;
      }
    }
  }

  switch (res.status) {
    case 200:
    case 201:
      return data;
    case 204:
      return res;
    case 400:
      throw new Error(Array.isArray(data) ? data[0].message : data.message);
    case 401:
      logout();
      return false;
    case 403:
      throw new LinkhawkServiceError(`You are not allowed to perform this action. (HTTP:${res.status})`);
    case 404:
      throw new LinkhawkServiceEntityNotFound();
    case 409:
      throw new LinkhawkServiceEntityConflict();
    case 413:
      throw new Error('Files too large.');
    case 500:
      throw new LinkhawkServiceError(`Backend Service Error. Please contact Linkhawk Support. (HTTP:${res.status})`);
    case 502:
    case 503:
    case 504:
      throw new LinkhawkServiceError(`The service appears to be unavailable. Please try later. (HTTP:${res.status})`);
    default:
      throw new LinkhawkServiceError(res.status);
  }
};

function jwtDecode(t) {
  const token = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split('.')[0]));
  token.payload = JSON.parse(window.atob(t.split('.')[1]));
  return (token);
}

const bearerToken = () => {
  try {
    const { token } = JSON.parse(localStorage.getItem('token'));
    return token;
  } catch (err) {
    return null;
  }
};

export const logSessionStatusToConsole = () => {
  const token = bearerToken();
  if (token === null) {
    // eslint-disable-next-line no-console
    console.log('session info: logged out');
    localStorage.clear();
  } else {
    const decoded = jwtDecode(token);

    const expires = moment.unix(decoded.payload.exp).format('YYYY-MM-DD HH:mm:ss');
    // eslint-disable-next-line no-console
    console.log(`session info:  expires${expires}`);
  }
};

export const buildParamString = (params) => {
  if (typeof params === 'undefined') return '';

  let paramString = '?';
  for (const param in params) {
    if (params.hasOwnProperty(param)) {
      paramString += `${param}=${params[param]}&`;
    }
  }
  paramString = paramString.slice(0, -1);

  return paramString;
};

export const login = async (payload) => {
  const res = await fetch(`${API_URL}/api/login_check`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: payload,
  });
  return res;
};

export const get = async (url, params = null, returnParsedResponse = true) => {
  const paramString = await buildParamString(params);
  const res = await fetch(`${API_URL}${url}${paramString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken()}`,
    },
  });
  const data = await checkResponse(res, returnParsedResponse);
  return data;
};

export const post = async (url, method, body, doNotSendAuthHeader = false) => {
  const res = await fetch(`${API_URL}${url}`, {
    method,
    headers: !doNotSendAuthHeader ? {
      Authorization: `Bearer ${bearerToken()}`,
      'Content-Type': 'application/json',
    } : { 'Content-Type': 'application/json' },
    body,
  });
  const data = await checkResponse(res);
  return data;
};

export const multipartPost = async (url, body) => {
  const res = await fetch(`${API_URL}${url}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken()}`,
    },
    body,
  });
  const data = await checkResponse(res);
  return data;
};

export const del = async (url) => {
  const res = await fetch(`${API_URL}${url}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${bearerToken()}`,
    },
  });
  const data = await checkResponse(res);
  return data;
};
