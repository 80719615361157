import React, { Component } from 'react';
import style from './style.module.scss';

export default class Footer extends Component {
  render() {
    return (
      <section id={style.footer}>
        <img className={style.logo} src="/icons/logo-vodafone-square.jpg" alt="Vodafone Icon" />
        <p>©2001-2021 All Rights Reserved. Linkhwak® is a registered trademark of Linkhawk GmbH, Duesseldorf, Germany.</p>
        <ul>
          <li><a href={`${process.env.REACT_APP_WEBSITE_URL}/imprint`} target="_blank" rel="noopener noreferrer">Imprint</a></li>
          <li><a href={`${process.env.REACT_APP_WEBSITE_URL}/privacy-policy`} target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
          <li><a href={`${process.env.REACT_APP_WEBSITE_URL}/terms-of-use`} target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
          <li><a href={`${process.env.REACT_APP_WEBSITE_URL}/spam-policy`} target="_blank" rel="noopener noreferrer">Anti Spam Policy</a></li>
          <li><a href={`${process.env.REACT_APP_WEBSITE_URL}/cookie-policy`} target="_blank" rel="noopener noreferrer">Cookie Policy</a></li>
          <li><a role="button" onClick={() => window.Optanon.ToggleInfoDisplay()}>Cookie Settings</a></li>
        </ul>
      </section>
    );
  }
}
