import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { connect } from 'react-unistore';
import TemplateSelection from './TemplateSelection';
import EditLinkboardForm from './EditLinkboardForm';
import SussessOverview from './SuccessOverview';
import linkboardActions, { EDITOR_STATE_SELECT_TEMPLATE, EDITOR_STATE_EDITOR, EDITOR_STATE_SUMMARY } from '../../../actions/linkboardEditor';
import Content from '../../../components/Content';
import MultiSteps from '../../../components/MultiSteps';

export const LB_MODE_CREATE = 'LB_MODE_CREATE';
export const LB_MODE_EDIT = 'LB_MODE_EDIT';

class LinkboardEditor extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const mode = location.pathname === '/linkboards/create' ? LB_MODE_CREATE : LB_MODE_EDIT;

    this.state = {
      mode,
      initialized: false,
    };
  }

  async componentDidMount() {
    const { initForCreation, initForEditing } = this.props;
    const {
      mode,
    } = this.state;

    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: mode === LB_MODE_CREATE
        ? 'Linkboard Create'
        : 'Linkboard Edit',
    });

    // eslint-disable-next-line default-case
    switch (mode) {
      case LB_MODE_CREATE:
        await initForCreation();
        break;
      case LB_MODE_EDIT:
        // eslint-disable-next-line no-case-declarations
        const { match } = this.props;
        // eslint-disable-next-line no-case-declarations
        const { id } = match.params;
        // eslint-disable-next-line no-case-declarations
        await initForEditing(id);
        break;
    }
    this.setState({ initialized: true });
  }

  render() {
    const { initialized, mode } = this.state;
    if (initialized === false) {
      return (<div />);
    }

    const {
      history,
      totalSteps,
      currentStep,
      disabledSteps,
      currentStepIndex,
    } = this.props;

    const activePage = () => {
      // eslint-disable-next-line default-case
      switch (mode) {
        case LB_MODE_CREATE:
          // eslint-disable-next-line default-case
          switch (currentStep) {
            case EDITOR_STATE_SELECT_TEMPLATE:
              return (
                <TemplateSelection
                  history={history}
                />
              );
            case EDITOR_STATE_EDITOR:
              return (
                <EditLinkboardForm
                  history={history}
                  headline="Create a new Linkboard"
                />
              );
            case EDITOR_STATE_SUMMARY:
              return (
                <SussessOverview
                  history={history}
                  message="Linkboard successfully created."
                />
              );
          }
          break;
        case LB_MODE_EDIT:
          // eslint-disable-next-line default-case
          switch (currentStep) {
            case EDITOR_STATE_EDITOR:
              return (
                <EditLinkboardForm
                  history={history}
                  headline="Edit Linkboard"
                />
              );
            case EDITOR_STATE_SUMMARY:
              return (
                <SussessOverview
                  history={history}
                  message="Linkboard successfully updated."
                />
              );
          }
      }
      return (<div />);
    };

    return (
      <Content>
        {/* <span>{JSON.stringify(linkboard)}</span> */}
        <MultiSteps
          totalSteps={totalSteps}
          currentStep={currentStepIndex}
          disabledSteps={disabledSteps}
        />
        {activePage()}
      </Content>
    );
  }
}

LinkboardEditor.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,

  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.string.isRequired,
  disabledSteps: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  currentStepIndex: PropTypes.number.isRequired,
  initForEditing: PropTypes.func.isRequired,
  initForCreation: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { linkboardEditor } = state;
  return {
    config: linkboardEditor.config,
    totalSteps: linkboardEditor.config.steps.length || 0,
    currentStep: linkboardEditor.config.currentStep || 'UNDEFINED',
    currentStepIndex: linkboardEditor.config.steps.indexOf(linkboardEditor.config.currentStep) + 1,
    // stepping back is not allowed during linkboard creation
    // ~> the only active step is always the current step
    disabledSteps:
    linkboardEditor.config.steps.filter((s) => s !== linkboardEditor.config.currentStep).map(
      (s) => linkboardEditor.config.steps.indexOf(s) + 1,
    ),
  };
}

export default connect(mapStateToProps, (store) => ({
  initForCreation: async (...args) => linkboardActions.initForCreation(store, ...args),
  initForEditing: async (...args) => linkboardActions.initForEditing(store, ...args),
}))(LinkboardEditor);
