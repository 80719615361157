import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import Content from '../../components/Content';
import Spinner from '../../components/Spinner';
import { getAccounts } from '../../api/accounting';

class Accounting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: null,
    };
  }

  async componentDidMount() {
    const accounts = await getAccounts();

    this.setState({ accounts });
  }

  render() {
    const { accounts } = this.state;

    if (accounts === null) {
      return (
        <Spinner show />
      );
    }

    const columns = [
      {
        Header: 'Account-ID',
        name: 'id',
        id: 'id',
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (props) => (
          <div>
            {props.original.id}
          </div>
        ),
      },
      {
        Header: 'Name',
        name: 'accountName',
        id: 'accountId',
        filterable: false,
        sortable: false,
        Cell: (props) => (
          <div>
            <Link to={`/accounting/account/${props.original.id}/list`}>{props.original.name}</Link>
          </div>
        ),
      },
      {
        Header: 'Users',
        name: 'users',
        id: 'users',
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (props) => (
          <div>
            {props.original.numberOfUsers}
          </div>
        ),
      },
      {
        Header: 'Invoices',
        name: 'invoices',
        id: 'invoices',
        width: 100,
        filterable: false,
        sortable: false,
        Cell: (props) => (
          <div>
            {props.original.invoices.length}
          </div>
        ),
      },
    ];

    return (
      <Content>
        <h1>Accounting</h1>
        <p>Customers who chose to pay by invoice are listed below.</p>
        <ReactTable
          data={accounts}
          manual
          columns={columns}
          minRows={0}
          resizable={false}
          showPagination={false}
        />
      </Content>
    );
  }
}

Accounting.propTypes = {

};

export default Accounting;
