import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import ReactGA from 'react-ga';
import 'react-table/react-table.css';
import chartStyle from '../../../components/Charts/style.module.scss';
import style from './style.module.scss';
import { getCampaigns, getCampaignAnalytics } from '../../../api/campaigns';
import Spinner from '../../../components/Spinner';
import DoughnutChart from '../../../components/Charts/DoughnutChart';
import LineChart from '../../../components/Charts/LineChart';
import BarChart from '../../../components/Charts/BarChart';
import ListChart from '../../../components/Charts/ListChart';
import FilterBar from '../../../components/FilterBar';
import DateFilter from '../../../components/FilterBar/DateFilter';
import Content from '../../../components/Content';
import Copy from '../../../components/Copy';
import BackLink from '../../../components/BackLink';
import TagCloud from '../../../components/TagCloud';
import CSVDownloadButton from '../../../components/CSVDownloadButton';

const moment = require('moment');

export default class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    const { id } = match.params;
    this.state = {
      id,
      selectedRange: '30d',
      dateStart: moment().startOf('day').add(12, 'hours').subtract(1, 'week')
        .format('YYYY-MM-DD'),
      dateEnd: moment().startOf('day').subtract(12, 'hours').format('YYYY-MM-DD'),
      analytics: null,
      title: '',
      isFetchingData: true,
    };
  }

  async componentDidMount() {
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Campaign Analytics',
    });
    const { id, dateStart, dateEnd } = this.state;
    const campaign = await getCampaigns(id);

    const analytics = await getCampaignAnalytics(id, { dateStart, dateEnd });

    this.setState({
      analytics,
      campaign,
      title: campaign.title,
      isFetchingData: false,
    });
  }

  async updateDate({ from, to }) {
    const { id } = this.state;

    const dateStart = moment(from).format('YYYY-MM-DD');
    const dateEnd = moment(to).format('YYYY-MM-DD');
    this.setState({ isFetchingData: true });
    const analytics = await getCampaignAnalytics(
      id, { dateStart, dateEnd },
    );
    this.setState({
      analytics, dateStart, dateEnd, isFetchingData: false,
    });
  }

  renderLinkTable() {
    const { analytics } = this.state;
    const { containerStyle } = this.props;

    const columns = [
      {
        Header: 'Shortlinks',
        name: 'links',
        id: 'links',
        accessor: (row) => row[1],
        filterable: false,
        Cell: (props) => (
          <div className={style.listChartEntry}>
            <Copy value={`https://${props.original[1].shortlink}`} />
          </div>
        ),
        sortable: false,
      },
      {
        Header: 'Tags',
        name: 'tags',
        id: 'tags',
        accessor: (row) => row[1],
        filterable: false,
        Cell: (props) => (
          <div className={style.listChartEntry}>
            <div className="tagContainer">
              <TagCloud tags={props.original[1].tags} />
            </div>
          </div>
        ),
        sortable: false,
        className: 'tagsCell',
        headerStyle: {
          textAlign: 'left',
          paddingRight: 10,
        },
        style: {
          textAlign: 'left',
        },
      },
      {
        Header: 'Clicks',
        name: 'clicks',
        id: 'clicks',
        accessor: (row) => row[1],
        filterable: false,
        Cell: (props) => (
          <div className={style.listChartEntryBold}>
            <p>{`${props.original[1].hitsTotals.data.total} Views`}</p>
          </div>
        ),
        sortable: true,

        headerClassName: 'sort',
        // className: 'autoWidth',
        headerStyle: {
          textAlign: 'right',
          paddingRight: 10,
        },
        style: {
          flex: 1,
          flexGrow: '0 !important',
          flexShrink: '0 !important',
          flexBasis: 'auto !important',
          width: 'auto !important',
          textAlign: 'right',
        },
      },
    ];

    return (
      <div className={style.linkTable} style={containerStyle}>
        <h2 className={style.chartHeadline}>Links</h2>
        <div className={style.chart}>
          <ReactTable
            data={Object.entries(analytics.shortlinks)}
            columns={columns}
            defaultPageSize={100}
            showPagination={false}
            minRows={0}
            resizable={false}
            sorted={[{ id: 'clicks', desc: true }]}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      id, dateStart, dateEnd, campaign, selectedRange, title, analytics, isFetchingData,
    } = this.state;

    function getDescriptionText() {
      return `Created: ${moment(campaign.created_at).format('DD.MM.Y')} · User: ${campaign.user} · Group: ${campaign.usergroup}`;
    }

    return (
      <Content>
        <BackLink target="/campaigns" title="Back to Campaigns" />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, paddingTop: '28px' }}>
            <h1 style={{
              display: 'inline-block',
              fontSize: '38px',
            }}
            >
              {title}
            </h1>
            {campaign ? (
              <p className="information-bar">
                {getDescriptionText()}
              </p>
            )
              : null}
          </div>
          <div>
            <FilterBar>
              <DateFilter
                selectedRange={selectedRange}
                dateStart={dateStart}
                dateEnd={dateEnd}
                callback={(dates) => this.updateDate(dates)}
              />
            </FilterBar>
          </div>
        </div>
        {!isFetchingData
          ? (

            <div style={{
              display: 'flex',
              marginTop: 9,
            }}
            >
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'start',
                alignItems: 'start',
              }}
              >
                {(campaign.links.map((link) => <Copy style={{ width: '200px' }} value={link.url} />))}
              </div>
              <div style={{ flex: 1 }} />
              <div style={{ marginTop: '5px', flex: 0 }}>
                <CSVDownloadButton
                  type="campaigns"
                  id={id}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                />
              </div>
            </div>
          ) : null}
        { analytics ? (
          <div>
            <LineChart
              data={analytics.hitsByDay.data}
              totalHits={analytics.hitsTotals.data.total}
              headline="Clicks by day"
              keyColor="rgb(194,212,177)"
              containerStyle={{ marginTop: 20 }}
            />
            <BarChart
              data={analytics.hitsByHourOfDay.data}
              headline="Clicks by time"
              keyColor="rgb(194,212,177, 1)"
              containerStyle={{ marginTop: 30 }}
            />
            <div className={chartStyle.chartGroup} style={{ marginBottom: 30 }}>
              <DoughnutChart
                data={analytics.hitsTotals.data}
                headline="Devices"
                colorDesktop="#A6C38A"
                colorMobile="#C2D4B1"
                colorTablet="#DBEDCA"
                containerStyle={{ marginTop: 30 }}
              />
              <ListChart
                data={analytics.hitsByReferrer.data}
                headline="Top 5 visitor sources by clicks"
                containerStyle={{ marginTop: 30 }}
              />
            </div>
            {this.renderLinkTable()}
          </div>
        ) : null}
        <Spinner show={isFetchingData} />
      </Content>
    );
  }
}

CampaignDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  containerStyle: PropTypes.any.isRequired,
};
