import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

const LinkRouter = (props) => {
  let module = style.link_module;

  if (props.back) {
    module = `${style.link_module} ${style.back}`;
  }

  return (
  // Notice: use props 'targetBlank' to link extern//

    props.targetBlank
      ? <a className={style.link_module} target="_blank" rel="noopener noreferrer" href={props.linkURL}>{props.label ? props.label : 'Learn more'}</a>
      : <Link className={module} onClick={(props.back && !props.onClick) ? () => window.history.back() : props.onClick}>{props.back ? 'Back' : 'Learn more' }</Link>
  );
};

export default LinkRouter;
