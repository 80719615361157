import React from 'react';
import style from './style.module.scss';

const Dropdown = (props) => {
  const label = props.label || 'Label';
  const className = `${style.input_wrapper} width_${props.width} ${props.error ? style.error : ''}`;
  const placeholder = props.placeholder || 'Select';

  return (
    <div className={`selectboxWrapper ${className} ${props['data-required'] ? 'required' : ''}`} data-required={props['data-required']}>

      <label htmlFor={props.nameID}>{label}</label>
      <div className={style.dropdown}>
        <input
          list={props.listID}
          id={props.nameID}
          placeholder={placeholder}
        />
      </div>

      <datalist id={props.listID} hidden>
        {
            props.options.map((option) => (
              <option value={option.name}>
                {option.name}
              </option>
            ))
          }
      </datalist>
      {props.error && <div className={style.error_message}>{props.errorMessage}</div>}
    </div>
  );
};

export default Dropdown;
